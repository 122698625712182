import { Injectable } from '@angular/core';
import { HttpEvent } from '@angular/common/http';
import { CacheHandler } from '../utils/tools/cache-handler';

@Injectable({
  providedIn: 'root'
})
export class CacheInterceptorService extends CacheHandler<HttpEvent<unknown>> {
  excludeUrls: string[] = ['/api/graphql'];
  cacheOnlyUrls: string[] = [];
  override TTL = 60_000;

  override set(url: string, value: HttpEvent<unknown>) {
    if (!this.isUrlAllowedToCached(url)) {
      return;
    }

    super.set(url, value);
  }

  isUrlAllowedToCached(url: string) {
    return this.cacheOnlyUrls.some((pattern: string) => url.includes(pattern)) || !this.excludeUrls.some((pattern: string) => url.includes(pattern))
  }

}
