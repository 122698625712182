import { NGX_UI_LOADER_CONFIG_DEFAULT } from '../app/shared/services/custom-loader.service';
import { FlatpickrDefaults } from 'angularx-flatpickr';

/**
 * Format to be applied by default using EpochToDatePipe and DatePipe pipes
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const APP_DEFAULT_DATE_FORMAT_PIPE: string = 'dd/MM/yyyy';


/**
 * Format to be applied by default using EpochToDatePipe and DatePipe pipes
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const APP_DEFAULT_DATETIME_FORMAT_PIPE: string = 'dd/MM/yyyy, H:mm:ss'

/**
 * Global constant to define what the timezone will be to transform the dates using EpochToDatePipe and DatePipe pipes
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const APP_DEFAULT_DATE_TIMEZONE_PIPE: string | undefined = undefined; // 'UTC' 'GMT+0:00' 'Europe/Madrid ' //


export const LOADER_UI_CONFIG = NGX_UI_LOADER_CONFIG_DEFAULT;
// LOADER_UI_CONFIG.bgsType = 'ball-scale-multiple'
// LOADER_UI_CONFIG.fgsType = 'ball-scale-multiple'
// LOADER_UI_CONFIG.fgsSize = 100
LOADER_UI_CONFIG.bgsColor = 'hsl(var(--primary))'
LOADER_UI_CONFIG.fgsColor = 'hsl(var(--primary))'
LOADER_UI_CONFIG.pbColor = 'hsl(var(--primary))'
LOADER_UI_CONFIG.overlayColor = 'var(--backdrop-color)'
LOADER_UI_CONFIG.bgsPosition = 'center-center'
LOADER_UI_CONFIG.overlayBorderRadius = 'var(--radius)'


export const FLATPICKER_CONFIG = new FlatpickrDefaults();
FLATPICKER_CONFIG.monthSelectorType = 'dropdown';
FLATPICKER_CONFIG.time24hr = true;
FLATPICKER_CONFIG.altInput = true;
FLATPICKER_CONFIG.altFormat = "d/m/Y";
FLATPICKER_CONFIG.dateFormat = "Z";
FLATPICKER_CONFIG.convertModelValue = false;
FLATPICKER_CONFIG.defaultHour = 0;
FLATPICKER_CONFIG.defaultMinute = 0;
FLATPICKER_CONFIG.defaultSeconds = 0;
FLATPICKER_CONFIG.enableTime = false;
FLATPICKER_CONFIG.utc = true;
