import { CENTER_MANAGEMENT_LOCALE_ES } from '@modules/center/i18n/locale';
import { SPECIALIST_MANAGEMENT_LOCALE_ES } from '@modules/specialist/i18n/locale';
import { I18N_CUSTOM_COMPONENTS_ES } from '@app/custome-components/i18n/es';
import { STATISTICS_MANAGEMENT_LOCALE_ES } from '@modules/statistics/i18n/locale';
import { ALL_USER_MANAGEMENT_LOCALE_ES } from '@modules/all-user/i18n/locale';

export const I18N_PARTIAL_ACTIONS_ES = {
  ACTION: {
    SINGULAR: 'Acciones',
    PLURAL: 'Acciones',
    ADD: {GENERIC: 'Añadir'},
    RELOAD: {GENERIC: 'Recargar'},
    SEARCH: {GENERIC: 'Buscador'},
    SAVE: {GENERIC: 'Guardar', AND_CONTINUE: 'Guardar y continuar'},
    EDIT: {GENERIC: 'Editar'},
    DISCARD: {GENERIC: 'Resetear'},
    CANCEL: {GENERIC: 'Cancelar'},
    SEND: {GENERIC: 'Enviar', EMAIL: 'Enviar email'},
    CLOSE: {GENERIC: 'Salir'},
    AGREE: {GENERIC: 'Aceptar', IAGREE: 'Estoy de acuerdo'},
    CONFIRM: {GENERIC: '¡Ok lo tengo!', YES: 'Si, seguro', DELETED: '¡Sí, eliminar!', ACCOUNT: 'Confirmar cuenta'},
    APPLY: {GENERIC: 'Aplicar'},
    ACCESS: {GENERIC: 'Acceder'},
    UPDATE: {GENERIC: 'Actualizar'},
    REGISTER: {GENERIC: 'Registrarse'},
    CONTINUE: {GENERIC: 'Continuar'},
    FINALIZE: {GENERIC: 'Finalizar'},
    GO_BACK: {GENERIC: 'Regresar', LIST: 'Regresar al listado'},
    NEW: {GENERIC: 'Nuevo'},
    EVALUATE: {GENERIC: 'Evaluar'},
    GO: {GENERIC: 'Ir'},
    REMOVE: {GENERIC: 'Eliminar', CLEAN: 'Limpiar'},
    UPLOAD: {GENERIC: 'Subir'},
    FILTER: {GENERIC: 'Filtrar'},
    SELECT: {GENERIC: 'Seleccionar'},
    IMPORT: {GENERIC: 'Importar', DATA: 'Importar datos'},
    SING_IN: {GENERIC: 'Entrar'},
  }
}

export const I18_PARTIAL_VALIDATIONS_RULES_ES = {
  VALIDATION: {
    REQUIRED: 'Campo obligatorio.',
    MIN_LENGTH: 'Este valor es demasiado corto. Debería tener {{ min }} caracteres o más.',
    MAX_LENGTH: 'Este valor es demasiado largo. Debería tener {{ max }} caracteres o menos.',
    MIN: 'Ingrese un valor mayor o igual a {{ min }}.',
    MAX: 'Ingrese un valor menor o igual a {{ max }}.',
    RANGE: 'Ingrese un valor entre {{ min }} y {{ max }}.',
    DATE: 'Este valor no es una fecha válida.',
    DATETIME: 'Este valor no es una fecha y hora válidas.',
    EMAIL: 'Este valor no es una dirección de email válida.',
    PASSWORD_MATCH: 'Las contraseñas no coinciden.',
    PASSWORD_STRENGTH: 'La contraseña debe contener al menos una letra mayúscula, una letra minúscula, un número, un carácter especial y no menos de {{ length }} caracteres.',
  }
}

export const I18_PARTIAL_ALERTS_ES = {
  ALERT: {
    SUCCESS: 'Operación completada correctamente',
    CONFIRM_TITLE: '¿Está seguro?',
    CONFIRM_TEXT: '¡No podrás revertir esta operación!',
    CONFIRM_BUTTON_TEXT: 'Si, seguro',
    DEFAULT_CONFIRM_BUTTON_TEXT: '¡Ok lo tengo!',
    DEFAULT_CANCEL_BUTTON_TEXT: 'Cancelar',
    ERROR: 'Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.'
  }
}


export const I18N_PARTIAL_TIME_PERIODICITY_ES = {
  PERIODICITY: {
    NONE: 'Ninguna',
    TODAY: 'Hoy',
    IN: 'En',
    AGO: 'Hace',
    SINCE: 'Desde hace',
    EVERY_WEEK: 'Semanalmente',
    EVERY_DAY: 'Diariamente',
    EVERY_CERTAIN_DAYS: 'Cada {{value}} días',
    EVERY_CERTAIN_WEEKS: 'Cada {{value}} semanas',
    EVERY_MONTH: 'Mensualmente',
    EVERY_QUARTER: 'Cada trimestre',
    EVERY_SEMESTER: 'Cada semestre',
    EVERY_CERTAIN_MONTHS: 'Cada {{value}} meses',
    EVERY_YEAR: 'Anual',
    EVERY_CERTAIN_YEARS: 'Cada {{value}} años',
    YEARS: 'Años',
    YEAR: 'Año',
    MONTHS: 'Meses',
    MONTH: 'Mese',
    DAYS: 'Días',
    DAY: 'Día',
    WEEKS: 'Semanas',
    WEEK: 'Semana',
    HOURS: 'Horas',
    HOUR: 'Hora',
    MINUTES: 'Minutos',
    MINUTE: 'Minuto',
    SECONDS: 'Segundos',
    SECOND: 'Segundo',
  },
}


export const I18N_PARTIALS_ES = {
  ...I18N_PARTIAL_ACTIONS_ES,
  ...I18_PARTIAL_VALIDATIONS_RULES_ES,
  ...I18N_PARTIAL_TIME_PERIODICITY_ES,
  ...I18_PARTIAL_ALERTS_ES,
  ...CENTER_MANAGEMENT_LOCALE_ES,
  ...SPECIALIST_MANAGEMENT_LOCALE_ES,
  ...I18N_CUSTOM_COMPONENTS_ES,
  ...STATISTICS_MANAGEMENT_LOCALE_ES,
  ...ALL_USER_MANAGEMENT_LOCALE_ES
}
