import { Pipe, PipeTransform } from '@angular/core';

export type TransformFn<TValue = any, TReturn = any> = (
  value: TValue,
) => TReturn;

@Pipe({
  name: 'transform',
  standalone: true,
  pure: true,
})
export class TransformerPipe implements PipeTransform {

  transform(
    value: any,
    transformer?: TransformFn
  ) {
    return transformer ? transformer(value) : value;
  }

}
