import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { IconName } from '@ng-icons/core';
import { lucideEye, lucideEyeOff } from '@ng-icons/lucide';
import { hlm } from '@spartan-ng/ui-core';
import { HlmIconComponent, provideIcons } from '@spartan-ng/ui-icon-helm';
import { HlmInputDirective, inputVariants } from '@spartan-ng/ui-input-helm';
import { ClassValue } from 'clsx';

@Component({
  selector: 'ui-input-password',
  standalone: true,
  providers: [
    provideIcons({ lucideEye, lucideEyeOff }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UiFormInputPasswordComponent,
      multi: true,
    },
  ],
  imports: [HlmInputDirective, HlmIconComponent, ReactiveFormsModule],
  template: `
    <div class="relative">
      <input
        hlmInput
        [value]="value()"
        [class.pe-10]="toggle()"
        [placeholder]="placeholder()"
        [type]="inputType()"
        [disabled]="isDisable()"
        (input)="onInput($event)"
        [class]="inputClass()" />
      @if (toggle()) {
        <hlm-icon
          (click)="togglePasswordEvent()"
          [name]="icon()"
          size="base"
          class="absolute right-3.5 top-0 bottom-0  my-auto cursor-pointer text-gray-500 group-[.ng-invalid.ng-dirty]/input:text-destructive group-[.ng-invalid.ng-touched]/input:text-destructive dark:text-gray-400" />
      }
    </div>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'hostClass',
  },
})
export class UiFormInputPasswordComponent implements ControlValueAccessor {
  // private readonly controlContainer = inject(ControlContainer);
  protected readonly hostClass = 'group group/input';

  protected readonly value = signal('');
  protected readonly inputType = signal<'password' | 'text'>('password');
  protected readonly isDisable = signal(false);

  public toggle = input(true, { transform: booleanAttribute });
  public placeholder = input<string>('');
  public controlName = input<string>('password');
  public inputClass = input<ClassValue>('', { alias: 'class' });

  protected computedClass = computed(() =>
    hlm(
      inputVariants({
        error: 'auto',
      }),
    ),
  );

  protected readonly icon = signal<IconName>('lucideEyeOff');

  onChange: (...args: any[]) => void = () => {
    /* empty */
  };
  onTouched: () => void = () => {
    /* empty */
  };

  isControlInvalid() {
    return false;
  }

  togglePasswordEvent() {
    switch (this.inputType()) {
      case 'password':
        this.icon.set('lucideEye');
        this.inputType.set('text');
        break;
      default:
        this.icon.set('lucideEyeOff');
        this.inputType.set('password');
        break;
    }
  }

  writeValue(value: string): void {
    this.value.set(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisable.set(isDisabled);
  }

  onInput(event: any) {
    this.onChange(event.target.value);
  }
}
