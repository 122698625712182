import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { buttonVariants } from '@spartan-ng/ui-button-helm';
import { hlm } from '@spartan-ng/ui-core';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export const SWEET_ALERT_CONFIRM_BUTTON_STYLE = hlm(
  buttonVariants({
    variant: 'default',
    size: 'lg',
    rounded: 'default',
  }),
);

export const SWEET_ALERT_DENY_BUTTON_STYLE = hlm(
  buttonVariants({
    variant: 'destructive',
    size: 'lg',
    rounded: 'default',
  }),
);

export const SWEET_ALERT_CANCEL_BUTTON_STYLE = hlm(
  buttonVariants({
    variant: 'secondary',
    size: 'lg',
    rounded: 'default',
  }),
);

export const SWEET_ALERT_DEFAULT_CONFIG: SweetAlertOptions = {
  timerProgressBar: false,
  focusConfirm: false,
  focusDeny: false,
  focusCancel: false,
  returnFocus: false,
  buttonsStyling: false,
  customClass: {
    popup: 'bg-popover text-popover-foreground',
    confirmButton: SWEET_ALERT_CONFIRM_BUTTON_STYLE,
    denyButton: SWEET_ALERT_DENY_BUTTON_STYLE,
    cancelButton: SWEET_ALERT_CANCEL_BUTTON_STYLE,
  },
};

@Injectable({
  providedIn: 'root',
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class SweetAlertService {
  private translateService = inject(TranslateService);

  // private _defaultOptions: SweetAlertGlobalOptions = Object.assign(SWEET_ALERT_CONFIG, this._config);

  get defaultOptions(): SweetAlertOptions {
    return SWEET_ALERT_DEFAULT_CONFIG;
  }

  //<editor-fold desc="Public Methods">
  public success(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{
        text: this.translateService.instant('ALERT.SUCCESS'),
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1500,
      },
      ...options,
    });
  }

  public error(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{ icon: 'error' },
      ...options,
    });
  }

  public warning(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{ icon: 'warning' },
      ...options,
    });
  }

  public info(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...{ icon: 'info' },
      ...options,
    });
  }

  public confirm(options: SweetAlertOptions = {}): Promise<SweetAlertResult> {
    return this.simpleAlert({
      ...(this.defaultOptions as any),
      ...{
        title: this.translateService.instant('ALERT.CONFIRM_TITLE'),
        text: this.translateService.instant('ALERT.CONFIRM_TEXT'),
        icon: 'question',
        showCancelButton: true,
        showConfirmButton: true,
      },
      ...options,
    });
  }

  public fire(options: SweetAlertOptions) {
    return Swal.fire(options);
  }

  //</editor-fold>

  private simpleAlert(options: SweetAlertOptions): Promise<SweetAlertResult> {
    return this.fire({
      ...(this.defaultOptions as any),
      ...{
        text: this.translateService.instant('ALERT.ERROR'),
        confirmButtonText: this.translateService.instant(
          'ALERT.DEFAULT_CONFIRM_BUTTON_TEXT',
        ),
        cancelButtonText: this.translateService.instant(
          'ALERT.DEFAULT_CANCEL_BUTTON_TEXT',
        ),
      },
      ...options,
    });
  }
}
