import * as i0 from '@angular/core';
import { Directive, signal, forwardRef, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, inject, NgModule } from '@angular/core';
import { BrnDialogCloseDirective, BrnDialogComponent, BrnDialogContentDirective, BrnDialogDescriptionDirective, BrnDialogOverlayComponent, BrnDialogTitleDirective, BrnDialogTriggerDirective } from '@spartan-ng/ui-dialog-brain';
import { provideExposesStateProviderExisting, provideExposedSideProviderExisting, provideCustomClassSettableExisting } from '@spartan-ng/ui-core';
const _c0 = ["*"];
class BrnSheetCloseDirective extends BrnDialogCloseDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnSheetCloseDirective_BaseFactory;
      return function BrnSheetCloseDirective_Factory(__ngFactoryType__) {
        return (ɵBrnSheetCloseDirective_BaseFactory || (ɵBrnSheetCloseDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnSheetCloseDirective)))(__ngFactoryType__ || BrnSheetCloseDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSheetCloseDirective,
      selectors: [["button", "brnSheetClose", ""]],
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetCloseDirective, [{
    type: Directive,
    args: [{
      selector: 'button[brnSheetClose]',
      standalone: true
    }]
  }], null, null);
})();
class BrnSheetComponent extends BrnDialogComponent {
  constructor() {
    super(...arguments);
    this._side = signal('top');
    this.side = this._side.asReadonly();
  }
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  set setSide(side) {
    this._side.set(side);
    if (side === 'top') {
      this.positionStrategy = this.positionBuilder.global().top();
    }
    if (side === 'bottom') {
      this.positionStrategy = this.positionBuilder.global().bottom();
    }
    if (side === 'left') {
      this.positionStrategy = this.positionBuilder.global().left();
    }
    if (side === 'right') {
      this.positionStrategy = this.positionBuilder.global().right();
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnSheetComponent_BaseFactory;
      return function BrnSheetComponent_Factory(__ngFactoryType__) {
        return (ɵBrnSheetComponent_BaseFactory || (ɵBrnSheetComponent_BaseFactory = i0.ɵɵgetInheritedFactory(BrnSheetComponent)))(__ngFactoryType__ || BrnSheetComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnSheetComponent,
      selectors: [["brn-sheet"]],
      inputs: {
        setSide: [0, "side", "setSide"]
      },
      exportAs: ["brnSheet"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: BrnDialogComponent,
        useExisting: forwardRef(() => BrnSheetComponent)
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function BrnSheetComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetComponent, [{
    type: Component,
    args: [{
      selector: 'brn-sheet',
      standalone: true,
      template: `
		<ng-content />
	`,
      providers: [{
        provide: BrnDialogComponent,
        useExisting: forwardRef(() => BrnSheetComponent)
      }],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      exportAs: 'brnSheet'
    }]
  }], null, {
    setSide: [{
      type: Input,
      args: ['side']
    }]
  });
})();
class BrnSheetContentDirective extends BrnDialogContentDirective {
  constructor() {
    super(...arguments);
    this.side = inject(BrnSheetComponent).side;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnSheetContentDirective_BaseFactory;
      return function BrnSheetContentDirective_Factory(__ngFactoryType__) {
        return (ɵBrnSheetContentDirective_BaseFactory || (ɵBrnSheetContentDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnSheetContentDirective)))(__ngFactoryType__ || BrnSheetContentDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSheetContentDirective,
      selectors: [["", "brnSheetContent", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([provideExposesStateProviderExisting(() => BrnSheetContentDirective), provideExposedSideProviderExisting(() => BrnSheetContentDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetContentDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSheetContent]',
      standalone: true,
      providers: [provideExposesStateProviderExisting(() => BrnSheetContentDirective), provideExposedSideProviderExisting(() => BrnSheetContentDirective)]
    }]
  }], null, null);
})();
class BrnSheetDescriptionDirective extends BrnDialogDescriptionDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnSheetDescriptionDirective_BaseFactory;
      return function BrnSheetDescriptionDirective_Factory(__ngFactoryType__) {
        return (ɵBrnSheetDescriptionDirective_BaseFactory || (ɵBrnSheetDescriptionDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnSheetDescriptionDirective)))(__ngFactoryType__ || BrnSheetDescriptionDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSheetDescriptionDirective,
      selectors: [["", "brnSheetDescription", ""]],
      hostVars: 1,
      hostBindings: function BrnSheetDescriptionDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx._id());
        }
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetDescriptionDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSheetDescription]',
      standalone: true,
      host: {
        '[id]': '_id()'
      }
    }]
  }], null, null);
})();
class BrnSheetOverlayComponent extends BrnDialogOverlayComponent {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnSheetOverlayComponent_BaseFactory;
      return function BrnSheetOverlayComponent_Factory(__ngFactoryType__) {
        return (ɵBrnSheetOverlayComponent_BaseFactory || (ɵBrnSheetOverlayComponent_BaseFactory = i0.ɵɵgetInheritedFactory(BrnSheetOverlayComponent)))(__ngFactoryType__ || BrnSheetOverlayComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnSheetOverlayComponent,
      selectors: [["brn-sheet-overlay"]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([provideCustomClassSettableExisting(() => BrnSheetOverlayComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function BrnSheetOverlayComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'brn-sheet-overlay',
      standalone: true,
      providers: [provideCustomClassSettableExisting(() => BrnSheetOverlayComponent)],
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }], null, null);
})();
class BrnSheetTitleDirective extends BrnDialogTitleDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnSheetTitleDirective_BaseFactory;
      return function BrnSheetTitleDirective_Factory(__ngFactoryType__) {
        return (ɵBrnSheetTitleDirective_BaseFactory || (ɵBrnSheetTitleDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnSheetTitleDirective)))(__ngFactoryType__ || BrnSheetTitleDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSheetTitleDirective,
      selectors: [["", "brnSheetTitle", ""]],
      hostVars: 1,
      hostBindings: function BrnSheetTitleDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx._id());
        }
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetTitleDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSheetTitle]',
      standalone: true,
      host: {
        '[id]': '_id()'
      }
    }]
  }], null, null);
})();
class BrnSheetTriggerDirective extends BrnDialogTriggerDirective {
  constructor() {
    super(...arguments);
    this._sheet = inject(BrnSheetComponent, {
      optional: true
    });
  }
  open() {
    if (this._sheet && this.side) {
      this._sheet.setSide = this.side;
    }
    super.open();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnSheetTriggerDirective_BaseFactory;
      return function BrnSheetTriggerDirective_Factory(__ngFactoryType__) {
        return (ɵBrnSheetTriggerDirective_BaseFactory || (ɵBrnSheetTriggerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnSheetTriggerDirective)))(__ngFactoryType__ || BrnSheetTriggerDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSheetTriggerDirective,
      selectors: [["button", "brnSheetTrigger", ""]],
      inputs: {
        side: "side"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetTriggerDirective, [{
    type: Directive,
    args: [{
      selector: 'button[brnSheetTrigger]',
      standalone: true
    }]
  }], null, {
    side: [{
      type: Input
    }]
  });
})();
const BrnSheetImports = [BrnSheetComponent, BrnSheetOverlayComponent, BrnSheetTriggerDirective, BrnSheetCloseDirective, BrnSheetContentDirective, BrnSheetTitleDirective, BrnSheetDescriptionDirective];
class BrnSheetModule {
  static {
    this.ɵfac = function BrnSheetModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSheetModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnSheetModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSheetModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnSheetImports],
      exports: [...BrnSheetImports]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BrnSheetCloseDirective, BrnSheetComponent, BrnSheetContentDirective, BrnSheetDescriptionDirective, BrnSheetImports, BrnSheetModule, BrnSheetOverlayComponent, BrnSheetTitleDirective, BrnSheetTriggerDirective };
