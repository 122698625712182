export const PageSizes = [3, 5, 10, 15, 50, 100] as const;

export interface IPaginatorState {
  page: number;
  itemsPerPage: number;
  totalCount: number;
  lastPage?: number;
  pageSizes: number[];
  startRowIndex: number;
  endRowIndex: number;

  recalculateStartAndEndIndex: () => void

  recalculatePaginator: (
    paginationInfo: {
      itemsPerPage: number
      lastPage?: number
      totalCount: number
    }) => IPaginatorState;
}


export class NgPaginationConfig {
  disabled = false;
  boundaryLinks = false;
  directionLinks = true;
  ellipses = true;
  maxSize = 0;
  pageSize = PageSizes[2];
  rotate = false;
  size: 'sm' | 'lg';
}


export class PaginatorState implements IPaginatorState {
  page: number = 1;
  itemsPerPage: number = PageSizes[2] || 10;
  totalCount: number = 0;
  pageSizes: number[] = Array.from(PageSizes);
  lastPage?: number = 0;
  startRowIndex: number = 1;
  endRowIndex: number = this.itemsPerPage;

  recalculateStartAndEndIndex() {
    this.startRowIndex =
      this.totalCount > 0 ? (this.page - 1) * this.itemsPerPage + 1 : 0;
    this.endRowIndex = Math.min(
      this.startRowIndex + this.itemsPerPage - 1,
      this.totalCount,
    );
  }

  recalculatePaginator(paginationInfo: {
    itemsPerPage: number;
    lastPage?: number;
    totalCount: number;
  }) {
    this.totalCount = paginationInfo.totalCount;
    this.lastPage = paginationInfo.lastPage;
    this.itemsPerPage = paginationInfo.itemsPerPage;

    this.recalculateStartAndEndIndex();
    return this;
  }
}
