import * as i0 from '@angular/core';
import { signal, inject, PLATFORM_ID, ElementRef, Directive, Input, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgControl } from '@angular/forms';
let nextId = 0;
class BrnLabelDirective {
  constructor() {
    this._id = signal(`brn-label-${nextId++}`);
    this._ngControl = inject(NgControl, {
      optional: true
    });
    this._isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
    this._element = inject(ElementRef).nativeElement;
    this._dataDisabled = signal('auto');
    this.dataDisabled = this._dataDisabled.asReadonly();
  }
  set id(id) {
    this._id.set(id || this._id());
  }
  get id() {
    return this._id();
  }
  ngOnInit() {
    if (!this._isBrowser) return;
    this._changes = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName !== 'data-disabled') return;
        // eslint-disable-next-line
        const state = mutation.target.attributes.getNamedItem(mutation.attributeName)?.value === 'true';
        this._dataDisabled.set(state ?? 'auto');
      });
    });
    this._changes?.observe(this._element, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }
  static {
    this.ɵfac = function BrnLabelDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnLabelDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnLabelDirective,
      selectors: [["", "brnLabel", ""]],
      hostVars: 9,
      hostBindings: function BrnLabelDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx._id());
          i0.ɵɵclassProp("ng-invalid", (ctx._ngControl == null ? null : ctx._ngControl.invalid) || null)("ng-dirty", (ctx._ngControl == null ? null : ctx._ngControl.dirty) || null)("ng-valid", (ctx._ngControl == null ? null : ctx._ngControl.valid) || null)("ng-touched", (ctx._ngControl == null ? null : ctx._ngControl.touched) || null);
        }
      },
      inputs: {
        id: "id"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnLabelDirective, [{
    type: Directive,
    args: [{
      selector: '[brnLabel]',
      standalone: true,
      host: {
        '[id]': '_id()',
        '[class.ng-invalid]': 'this._ngControl?.invalid || null',
        '[class.ng-dirty]': 'this._ngControl?.dirty || null',
        '[class.ng-valid]': 'this._ngControl?.valid || null',
        '[class.ng-touched]': 'this._ngControl?.touched || null'
      }
    }]
  }], null, {
    id: [{
      type: Input
    }]
  });
})();
class BrnLabelModule {
  static {
    this.ɵfac = function BrnLabelModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnLabelModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnLabelModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnLabelModule, [{
    type: NgModule,
    args: [{
      imports: [BrnLabelDirective],
      exports: [BrnLabelDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BrnLabelDirective, BrnLabelModule };
