import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';

export const NGX_UI_LOADER_CONFIG_DEFAULT: NgxUiLoaderConfig = {
  bgsColor: '#0095E8',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'folding-cube',
  blur: 60,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#0095E8',
  fgsPosition: 'center-center',
  fgsSize: 50,
  fgsType: 'ball-spin-clockwise',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(146,213,251,0.12)',
  pbColor: '#0095E8',
  pbDirection: 'ltr',
  pbThickness: 4,
  hasProgressBar: false,
  textColor: '#000000',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
  text: '',
};

@Injectable({
  providedIn: 'root'
})
export class CustomLoaderService {
  ngxUiLoaderService: NgxUiLoaderService = inject(NgxUiLoaderService);

  private _config: WritableSignal<NgxUiLoaderConfig> = signal(NGX_UI_LOADER_CONFIG_DEFAULT);

  start(config?: Partial<NgxUiLoaderConfig>) {

    if (undefined !== config) {
      this._config.set(Object.assign(this._config(), config));
    }

    this.ngxUiLoaderService.start();
  }

  startLoader(id: string, config?: Partial<NgxUiLoaderConfig>) {
    if (undefined !== config) {
      this._config.set(Object.assign(this._config(), config));
    }
    this.ngxUiLoaderService.startLoader(id);
  }


  stop() {
    this.ngxUiLoaderService.stop();
  }

  stopLoader(id: string) {
    this.ngxUiLoaderService.stopLoader(id);
  }

  stopAll() {
    this.ngxUiLoaderService.stopAll();

  }

  getConfig() {
    return this._config.asReadonly();
  }

}
