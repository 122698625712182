import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HlmDialogService } from '@spartan-ng/ui-dialog-helm';
import { toast } from 'ngx-sonner';
import { AuthService } from '../app/modules/auth';
import { AlertDialogContext, UiAlertDialogComponent } from '../app/shared/ui';

export const INVALID_TOKEN_ERRORS = [
  'Invalid JWT Token',
  'Expired JWT Token',
  'JWT Token not found',
];

export const GLOBAL_ERROR_CONFIG = {
  enabled: true,
  verbose: true,
};

export const DEFAULT_INTERNAL_ERROR_MESSAGE =
  'Lo sentimos, a ocurrido un error interno. Si el error persiste recargue la página o consulte a los administradores.';

export const ERROR_AUTHENTICATION_FAILURE_MESSAGE = 'Credenciales inválidas.';

export const ERROR_INVALID_SESSION_MESSAGE =
  'Lo sentimos, su sesión ha expirado intente acceder nuevamente a la plataforma, si el error persiste ' +
  'puede ponerse en contacto con los administradores.';

export const ERROR_INVALID_SESSION_TITLE = 'La sesión ha expirado';

export function handleGlobalErrorFn(
  error: { toString: () => string },
  injector: Injector,
  forceDisplayError: boolean = false,
): void {
  const translator = injector.get(TranslateService);

  const verboseError = GLOBAL_ERROR_CONFIG.verbose ? error.toString() : '';
  const message = GLOBAL_ERROR_CONFIG.verbose
    ? 'Internal Error:'
    : translator.instant(DEFAULT_INTERNAL_ERROR_MESSAGE);

  toast.error(!forceDisplayError ? message : error.toString(), {
    description: !forceDisplayError ? verboseError : undefined,
    duration: 60_000,
  });
}

export function handleHttpInvalidTokenErrorsFn(error: any, injector: Injector) {
  const dialogService = injector.get(HlmDialogService);
  const authService = injector.get(AuthService);
  const translator = injector.get(TranslateService);

  if (!authService.isFullyAuthenticated()) {
    setTimeout(() => {
      handleGlobalErrorFn(
        { toString: () => ERROR_INVALID_SESSION_MESSAGE },
        injector,
        true,
      );
    }, 300);
    return;
  }

  const context: AlertDialogContext = {
    title: ERROR_INVALID_SESSION_TITLE,
    description: ERROR_INVALID_SESSION_MESSAGE,
    cancelAction: {
      show: false,
    },
    confirmAction: {
      label: 'CORE.USER_MENU.LOGOUT',
      variant: 'destructive',
    },
  };

  const ref = dialogService.open(UiAlertDialogComponent, {
    context,
    closeOnBackdropClick: false,
  });
  const reSub = ref.closed$.subscribe((value: boolean) => {
    if (value) {
      reSub.unsubscribe();
      authService.logout({
        redirectToCurrentUrl: true,
        clearServerSession: true,
      });
    }
  });
}
