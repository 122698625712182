import { NgClass, NgOptimizedImage } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  numberAttribute,
} from '@angular/core';

@Component({
  selector: 'ui-empty',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  template: `
    <div class="w-100 flex flex-col justify-center items-center text-center">
      <img
        [width]="imageWidth()"
        [height]="imageHeight()"
        [ngSrc]="image()"
        [ngClass]="imageClass()"
        alt="no_data"
      />
      <span
        class="font-bold text-muted fs-4 mt-5"
        [innerHTML]="message()"
      ></span>
    </div>
  `,
  imports: [NgClass, NgOptimizedImage],
  styles: [``],
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class UiEmptyComponent {
  message = input<string>('Lo sentimos no hay información disponible');

  showImage = input(true, { transform: booleanAttribute });
  image = input<string>('assets/media/svg/no_data.svg');
  imageWidth = input(250, { transform: numberAttribute });
  imageHeight = input(250, { transform: numberAttribute });
  imageClass = input<string>();
}
