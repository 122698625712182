import {
  ROLE_ADMIN,
  ROLE_SPECIALIST,
  ROLE_SPECIALIST_ADMIN_CENTER,
} from './roles';

/**
 * Class to manage the configurations in the control of access to components via URL
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class AccessControl {
  /**
   * Allows you to configure the route and URL to redirect to if access is denied.
   * The path property specifies the route path, while the url property specifies the full URL to redirect to.
   */
  public static REDIRECT_ON_ACCESS_DENIED_ROUTE: { path: string; url: string } =
    {
      path: 'dashboard',
      url: '/dashboard',
    };
  /**
   * It allows to determine if the authenticated user in case of not having any value can access or not, by default it is false.
   */
  public static GRANT_ACCESS_IF_USER_HAS_NO_ROLE = false;
  /**
   * Enable or disable access control
   */
  public static CHECK_ACCESS = true;
  /**
   * It allows us to deny access based on the specific role or permission the user has.
   */
  public static DENY_LOGIN_ACCESS_BY_ROLE: string[] = [];

  /**
   * Method to get the access list according to the invoked route
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public static getAccessControlListByRole(): Record<string, string[]> {
    const accessControlByRole: Record<string, string[]> = {};

    const COMMON_ROUTES: string[] = [
      //here you can define al common routes regarles the value
      'profile',
    ];

    const SPECIALIST_ROUTES = [...COMMON_ROUTES];


    //you can create more access controls for each value of your business in the same way of  ROLE_ADMIN & ROLE_PATIENT
    accessControlByRole[ROLE_ADMIN.value] = [
      ...COMMON_ROUTES,
      'group',
      'user',
      //define all routes access by value admin
    ];

    accessControlByRole[ROLE_SPECIALIST.value] = SPECIALIST_ROUTES;

    accessControlByRole[ROLE_SPECIALIST_ADMIN_CENTER.value] = [
      ...SPECIALIST_ROUTES,
    ];

    return accessControlByRole;
  }
}
