<ng-container *ngIf="activeDirection() === 'asc'">
  <hlm-icon
    size="sm"
    color="#a4a4a4"
    strokeWidth="2"
    name="lucideArrowDownNarrowWide" />
</ng-container>

<ng-container *ngIf="activeDirection() === 'desc'">
  <hlm-icon
    size="sm"
    strokeWidth="2"
    color="#a4a4a4"
    name="lucideArrowDownWideNarrow" />
</ng-container>

<ng-container *ngIf="activeDirection() === ''">
  <hlm-icon
    size="sm"
    color="#a4a4a4"
    strokeWidth="2"
    name="lucideArrowDownUp" />
</ng-container>
