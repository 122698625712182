import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
  signal
} from '@angular/core';
import { provideIcons } from '@ng-icons/core';
import {
  lucideArrowDownNarrowWide,
  lucideArrowDownUp,
  lucideArrowDownWideNarrow,
} from '@ng-icons/lucide';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import {
  ITableSortState,
  SortDirection,
  SortDirectionOptions,
} from '../../types/sort.model';

@Component({
  selector: 'app-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrls: ['./sort-icon.component.scss'],
  standalone: true,
  imports: [NgIf, HlmIconComponent],
  providers: [
    provideIcons({
      lucideArrowDownUp,
      lucideArrowDownWideNarrow,
      lucideArrowDownNarrowWide,
    }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortIconComponent implements OnInit {
  column = input.required<any>();
  state = input<ITableSortState>();
  
  sortChange = output<ITableSortState>();

  private readonly el = inject(ElementRef<HTMLElement>);

  activeDirection = signal<SortDirection>(SortDirectionOptions.undefined);

  constructor() {
    effect(
      () => {
        this.activeDirection.set(
          this.state()?.direction ?? SortDirectionOptions.undefined,
        );
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    const parent = this.el.nativeElement.parentElement as Element;
    if (!parent) {
      return;
    }

    parent.addEventListener('click', () => {
      if (SortDirectionOptions.desc === this.activeDirection()) {
        this.activeDirection.set(SortDirectionOptions.undefined);
      } else if (SortDirectionOptions.asc === this.activeDirection()) {
        this.activeDirection.set(SortDirectionOptions.desc);
      } else {
        this.activeDirection.set(SortDirectionOptions.asc);
      }

      const state: ITableSortState = {
        column: this.column(),
        direction: this.activeDirection(),
      };

      this.sortChange.emit(state);
    });
  }
}
