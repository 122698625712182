import { onStrategy, strategyHandling } from '@rx-angular/cdk/render-strategies';
import { of, BehaviorSubject, concat, combineLatest, ReplaySubject, EMPTY, merge } from 'rxjs';
import { switchMap, ignoreElements, catchError, distinctUntilChanged, map, tap, withLatestFrom } from 'rxjs/operators';

/**
 * @internal
 * creates an embeddedViewRef
 *
 * @param viewContainerRef
 * @param templateRef
 * @param context
 * @param index
 * @return EmbeddedViewRef<C>
 */
function createEmbeddedView(viewContainerRef, templateRef, context, index = 0) {
  const view = viewContainerRef.createEmbeddedView(templateRef, context, index);
  view.detectChanges();
  return view;
}
/**
 * @internal
 *
 * A factory function returning an object to handle `TemplateRef`'s.
 * You can add and get a `TemplateRef`.
 *
 */
function templateHandling(viewContainerRef) {
  const templateCache = new Map();
  const get$ = name => {
    return templateCache.get(name) || of(undefined);
  };
  const get = name => {
    let ref;
    const templatRef$ = get$(name);
    if (templatRef$) {
      const sub = templatRef$.subscribe(r => ref = r);
      sub.unsubscribe();
    }
    return ref;
  };
  return {
    add(name, templateRef) {
      assertTemplate(name, templateRef);
      if (!templateCache.has(name)) {
        templateCache.set(name, new BehaviorSubject(templateRef));
      } else {
        templateCache.get(name).next(templateRef);
      }
    },
    get$,
    get,
    createEmbeddedView: (name, context) => createEmbeddedView(viewContainerRef, get(name), context)
  };
  //
  function assertTemplate(property, templateRef) {
    const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
    if (!isTemplateRefOrNull) {
      throw new Error(`${property} must be a TemplateRef, but received ${typeof templateRef}`);
    }
    return isTemplateRefOrNull;
  }
}
/**
 * @internal
 *
 * A side effect operator similar to `tap` but with a static internal logic.
 * It calls detect changes on the 'VirtualParent' and the injectingViewCdRef.
 *
 * @param injectingViewCdRef
 * @param strategy
 * @param notifyNeeded
 * @param ngZone
 */
function notifyAllParentsIfNeeded(injectingViewCdRef, strategy, notifyNeeded, ngZone) {
  return o$ => o$.pipe(switchMap(v => {
    const notifyParent = notifyNeeded();
    if (!notifyParent) {
      return of(v);
    }
    return concat(of(v), onStrategy(injectingViewCdRef, strategy, (_v, work, options) => {
      /*console.log(
       'notifyAllParentsIfNeeded injectingView',
       (injectingViewCdRef as any).context
       );*/
      work(injectingViewCdRef, options.scope);
    }, {
      scope: injectingViewCdRef.context || injectingViewCdRef,
      ngZone
    }).pipe(ignoreElements()));
  }));
}

/**
 * @internal
 *
 * Factory that returns a `ListTemplateManager` for the passed params.
 *
 * @param templateSettings
 */
function getTemplateHandler(templateSettings) {
  const {
    viewContainerRef,
    initialTemplateRef,
    createViewContext,
    updateViewContext
  } = templateSettings;
  return {
    updateUnchangedContext,
    insertView,
    moveView,
    removeView,
    getListChanges,
    updateView
  };
  // =====
  function updateUnchangedContext(item, index, count) {
    const view = viewContainerRef.get(index);
    updateViewContext(item, view, {
      count,
      index
    });
    view.detectChanges();
  }
  function moveView(oldIndex, item, index, count) {
    const oldView = viewContainerRef.get(oldIndex);
    const view = viewContainerRef.move(oldView, index);
    updateViewContext(item, view, {
      count,
      index
    });
    view.detectChanges();
  }
  function updateView(item, index, count) {
    const view = viewContainerRef.get(index);
    updateViewContext(item, view, {
      count,
      index
    });
    view.detectChanges();
  }
  function removeView(index) {
    return viewContainerRef.remove(index);
  }
  function insertView(item, index, count) {
    createEmbeddedView(viewContainerRef, initialTemplateRef, createViewContext(item, {
      count,
      index
    }), index);
  }
}
/**
 * @internal
 *
 * @param changes
 * @param items
 */
function getListChanges(changes, items) {
  const changedIdxs = new Set();
  const changesArr = [];
  let notifyParent = false;
  changes.forEachOperation((record, adjustedPreviousIndex, currentIndex) => {
    const item = record.item;
    if (record.previousIndex == null) {
      // insert
      changesArr.push(getInsertChange(item, currentIndex === null ? undefined : currentIndex));
      changedIdxs.add(item);
      notifyParent = true;
    } else if (currentIndex == null) {
      // remove
      changesArr.push(getRemoveChange(item, adjustedPreviousIndex === null ? undefined : adjustedPreviousIndex));
      notifyParent = true;
    } else if (adjustedPreviousIndex !== null) {
      // move
      changesArr.push(getMoveChange(item, currentIndex, adjustedPreviousIndex));
      changedIdxs.add(item);
      notifyParent = true;
    }
  });
  changes.forEachIdentityChange(record => {
    const item = record.item;
    if (!changedIdxs.has(item)) {
      changesArr.push(getUpdateChange(item, record.currentIndex));
      changedIdxs.add(item);
    }
  });
  items.forEach((item, index) => {
    if (!changedIdxs.has(item)) {
      changesArr.push(getUnchangedChange(item, index));
    }
  });
  return [changesArr, notifyParent];
  // ==========
  function getMoveChange(item, currentIndex, adjustedPreviousIndex) {
    return [2 /* RxListTemplateChangeType.move */, [item, currentIndex, adjustedPreviousIndex]];
  }
  function getUpdateChange(item, currentIndex) {
    return [3 /* RxListTemplateChangeType.update */, [item, currentIndex]];
  }
  function getUnchangedChange(item, index) {
    return [4 /* RxListTemplateChangeType.context */, [item, index]];
  }
  function getInsertChange(item, currentIndex) {
    return [0 /* RxListTemplateChangeType.insert */, [item, currentIndex === null ? undefined : currentIndex]];
  }
  function getRemoveChange(item, adjustedPreviousIndex) {
    return [1 /* RxListTemplateChangeType.remove */, [item, adjustedPreviousIndex === null ? undefined : adjustedPreviousIndex]];
  }
}

/** @internal **/
function isRxRenderError(e) {
  return e != null && Array.isArray(e) && e.length === 2 && e[0] instanceof Error;
}
/** @internal **/
function createErrorHandler(_handler) {
  const _handleError = _handler ? e => _handler.handleError(e) : console.error;
  return {
    handleError: error => {
      if (isRxRenderError(error)) {
        _handleError(error[0]);
        console.error('additionalErrorContext', error[1]);
      } else {
        _handleError(error);
      }
    }
  };
}
/** @internal **/
function toRenderError(e, context) {
  return [e, context];
}
function createListTemplateManager(config) {
  const {
    templateSettings,
    renderSettings,
    trackBy,
    iterableDiffers
  } = config;
  const {
    defaultStrategyName,
    strategies,
    cdRef: injectingViewCdRef,
    patchZone,
    parent
  } = renderSettings;
  const errorHandler = createErrorHandler(renderSettings.errorHandler);
  const ngZone = patchZone ? patchZone : undefined;
  const strategyHandling$ = strategyHandling(defaultStrategyName, strategies);
  let _differ;
  function getDiffer(values) {
    if (_differ) {
      return _differ;
    }
    return values ? _differ = iterableDiffers.find(values).create(trackBy) : null;
  }
  //               type,  context
  /* TODO (regarding createView): this is currently not in use. for the list-manager this would mean to provide
   functions for not only create. developers than should have to provide create, move, remove,... the whole thing.
   i don't know if this is the right decision for a first RC */
  const listViewHandler = getTemplateHandler({
    ...templateSettings,
    initialTemplateRef: templateSettings.templateRef
  });
  const viewContainerRef = templateSettings.viewContainerRef;
  let notifyParent = false;
  let changesArr;
  let partiallyFinished = false;
  return {
    nextStrategy(nextConfig) {
      strategyHandling$.next(nextConfig);
    },
    render(values$) {
      return values$.pipe(render());
    }
  };
  function handleError() {
    return o$ => o$.pipe(catchError(err => {
      partiallyFinished = false;
      errorHandler.handleError(err);
      return of(null);
    }));
  }
  function render() {
    return o$ => combineLatest([o$, strategyHandling$.strategy$.pipe(distinctUntilChanged())]).pipe(map(([iterable, strategy]) => {
      const differ = getDiffer(iterable);
      let changes;
      if (differ) {
        if (partiallyFinished) {
          const currentIterable = [];
          for (let i = 0, ilen = viewContainerRef.length; i < ilen; i++) {
            const viewRef = viewContainerRef.get(i);
            currentIterable[i] = viewRef.context.$implicit;
          }
          differ.diff(currentIterable);
        }
        changes = differ.diff(iterable);
      }
      return {
        changes,
        iterable,
        strategy
      };
    }),
    // Cancel old renders
    switchMap(({
      changes,
      iterable,
      strategy
    }) => {
      if (!changes) {
        return of([]);
      }
      const values = iterable || [];
      // TODO: we might want to treat other iterables in a more performant way than Array.from()
      const items = Array.isArray(values) ? values : Array.from(iterable);
      const listChanges = listViewHandler.getListChanges(changes, items);
      changesArr = listChanges[0];
      const insertedOrRemoved = listChanges[1];
      const applyChanges$ = getObservablesFromChangesArray(changesArr, strategy, items.length);
      partiallyFinished = true;
      notifyParent = insertedOrRemoved && parent;
      return combineLatest(applyChanges$.length > 0 ? applyChanges$ : [of(null)]).pipe(tap(() => partiallyFinished = false), notifyAllParentsIfNeeded(injectingViewCdRef, strategy, () => notifyParent, ngZone), handleError(), map(() => iterable));
    }), handleError());
  }
  /**
   * @internal
   *
   * returns an array of streams which process all of the view updates needed to reflect the latest diff to the
   * viewContainer.
   * I
   *
   * @param changes
   * @param strategy
   * @param count
   */
  function getObservablesFromChangesArray(changes, strategy, count) {
    return changes.length > 0 ? changes.map(change => {
      const payload = change[1];
      return onStrategy(change[0], strategy, type => {
        switch (type) {
          case 0 /* RxListTemplateChangeType.insert */:
            listViewHandler.insertView(payload[0], payload[1], count);
            break;
          case 2 /* RxListTemplateChangeType.move */:
            listViewHandler.moveView(payload[2], payload[0], payload[1], count);
            break;
          case 1 /* RxListTemplateChangeType.remove */:
            listViewHandler.removeView(payload[1]);
            break;
          case 3 /* RxListTemplateChangeType.update */:
            listViewHandler.updateView(payload[0], payload[1], count);
            break;
          case 4 /* RxListTemplateChangeType.context */:
            listViewHandler.updateUnchangedContext(payload[0], payload[1], count);
            break;
        }
      }, {
        ngZone
      });
    }) : [of(null)];
  }
}
const computeFirst = ({
  count,
  index
}) => index === 0;
const computeLast = ({
  count,
  index
}) => index === count - 1;
const computeEven = ({
  count,
  index
}) => index % 2 === 0;
class RxDefaultListViewContext {
  _item = new ReplaySubject(1);
  item$ = this._item.asObservable();
  _$implicit;
  _$complete;
  _$error;
  _$suspense;
  _context$ = new BehaviorSubject({
    index: -1,
    count: -1
  });
  set $implicit($implicit) {
    this._$implicit = $implicit;
    this._item.next($implicit);
  }
  get $implicit() {
    return this._$implicit;
  }
  get $complete() {
    return this._$complete;
  }
  get $error() {
    return this._$error;
  }
  get $suspense() {
    return this._$suspense;
  }
  get index() {
    return this._context$.getValue().index;
  }
  get count() {
    return this._context$.getValue().count;
  }
  get first() {
    return computeFirst(this._context$.getValue());
  }
  get last() {
    return computeLast(this._context$.getValue());
  }
  get even() {
    return computeEven(this._context$.getValue());
  }
  get odd() {
    return !this.even;
  }
  get index$() {
    return this._context$.pipe(map(c => c.index), distinctUntilChanged());
  }
  get count$() {
    return this._context$.pipe(map(s => s.count), distinctUntilChanged());
  }
  get first$() {
    return this._context$.pipe(map(computeFirst), distinctUntilChanged());
  }
  get last$() {
    return this._context$.pipe(map(computeLast), distinctUntilChanged());
  }
  get even$() {
    return this._context$.pipe(map(computeEven), distinctUntilChanged());
  }
  get odd$() {
    return this.even$.pipe(map(even => !even));
  }
  constructor(item, customProps) {
    this.$implicit = item;
    if (customProps) {
      this.updateContext(customProps);
    }
  }
  updateContext(newProps) {
    this._context$.next({
      ...this._context$.getValue(),
      ...newProps
    });
  }
  select = props => {
    return this.item$.pipe(map(r => props.reduce((acc, key) => acc?.[key], r)));
  };
}
var RxBaseTemplateNames;
(function (RxBaseTemplateNames) {
  RxBaseTemplateNames["error"] = "errorTpl";
  RxBaseTemplateNames["complete"] = "completeTpl";
  RxBaseTemplateNames["suspense"] = "suspenseTpl";
})(RxBaseTemplateNames || (RxBaseTemplateNames = {}));

/**
 * @internal
 *
 * A factory function that returns a map of projections to turn a notification of a Observable (next, error, complete)
 *
 * @param customNextContext - projection function to provide custom properties as well as override existing
 */
function notificationKindToViewContext(customNextContext) {
  // @TODO rethink overrides
  return {
    suspense: notification => {
      const $implicit = notification.value;
      return {
        $implicit,
        suspense: true,
        error: false,
        complete: false,
        ...customNextContext($implicit)
      };
    },
    next: notification => {
      const $implicit = notification.value;
      return {
        $implicit,
        suspense: false,
        error: false,
        complete: false,
        ...customNextContext($implicit)
      };
    },
    error: notification => {
      const $implicit = notification.value;
      return {
        $implicit,
        complete: false,
        error: notification.error || true,
        suspense: false,
        ...customNextContext($implicit)
      };
    },
    complete: notification => {
      const $implicit = notification.value;
      return {
        $implicit,
        error: false,
        complete: true,
        suspense: false,
        ...customNextContext($implicit)
      };
    }
  };
}
function createTemplateManager(config) {
  const {
    renderSettings,
    notificationToTemplateName,
    templateSettings
  } = config;
  const {
    defaultStrategyName,
    strategies,
    cdRef: injectingViewCdRef,
    patchZone,
    parent
  } = renderSettings;
  const errorHandler = createErrorHandler(renderSettings.errorHandler);
  const ngZone = patchZone ? patchZone : undefined;
  let activeTemplate;
  const strategyHandling$ = strategyHandling(defaultStrategyName, strategies);
  const templates = templateHandling(templateSettings.viewContainerRef);
  const viewContainerRef = templateSettings.viewContainerRef;
  const triggerHandling = config.templateTrigger$ || EMPTY;
  const getContext = notificationKindToViewContext(templateSettings.customContext || (() => ({})));
  return {
    addTemplateRef: (name, templateRef) => {
      templates.add(name, templateRef);
    },
    nextStrategy: strategyHandling$.next,
    render(values$) {
      let trg;
      let notification = {
        value: undefined,
        complete: false,
        error: false,
        kind: "suspense" /* RxNotificationKind.Suspense */,
        hasValue: false
      };
      return merge(values$.pipe(tap(n => notification = n)), triggerHandling.pipe(tap(trigger => trg = trigger))).pipe(switchMap(() => {
        const contextKind = trg || notification.kind;
        trg = undefined;
        const value = notification.value;
        const templateName = notificationToTemplateName[contextKind](value, templates);
        return templates.get$(templateName).pipe(map(template => ({
          template,
          templateName,
          notification,
          contextKind
        })));
      }), withLatestFrom(strategyHandling$.strategy$),
      // Cancel old renders
      switchMap(([{
        template,
        templateName,
        notification,
        contextKind
      }, strategy]) => {
        const isNewTemplate = activeTemplate !== template || !template;
        const notifyParent = isNewTemplate && parent;
        return onStrategy(notification.value, strategy, (v, work, options) => {
          const context = getContext[contextKind](notification);
          if (isNewTemplate) {
            // template has changed (undefined => next; suspense => next; ...)
            // handle remove & insert
            // remove current view if there is any
            if (viewContainerRef.length > 0) {
              // patch removal if needed
              viewContainerRef.clear();
            }
            // create new view if any
            if (template) {
              // createEmbeddedView is already patched, no need for workFactory
              templates.createEmbeddedView(templateName, context);
            }
          } else if (template) {
            // template didn't change, update it
            // handle update
            const view = viewContainerRef.get(0);
            Object.keys(context).forEach(k => {
              view.context[k] = context[k];
            });
            // update view context, patch if needed
            work(view, options.scope, notification);
          }
          activeTemplate = template;
        }, {
          ngZone
        }
        // we don't need to specify any scope here. The template manager is the only one
        // who will call `viewRef#detectChanges` on any of the templates it manages.
        // whenever a new value comes in, any pre-scheduled work of this taskManager will
        // be nooped before a new work will be scheduled. This happens because of the implementation
        // of `StrategyCredential#behavior`
        ).pipe(notifyAllParentsIfNeeded(injectingViewCdRef, strategy, () => notifyParent, ngZone), catchError(e => {
          errorHandler.handleError(e);
          return of(e);
        }));
      }));
    }
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { RxBaseTemplateNames, RxDefaultListViewContext, createListTemplateManager, createTemplateManager, templateHandling };
