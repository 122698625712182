import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { provideIcons } from '@ng-icons/core';
import { lucideFilter, lucideFilterX } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import {
  BrnSheetContentDirective,
  BrnSheetTriggerDirective,
} from '@spartan-ng/ui-sheet-brain';
import {
  HlmSheetComponent,
  HlmSheetContentComponent,
  HlmSheetFooterComponent,
  HlmSheetHeaderComponent,
  HlmSheetTitleDirective,
} from '@spartan-ng/ui-sheet-helm';

@Component({
  selector: 'ui-table-filter-sheet',
  standalone: true,
  providers: [provideIcons({ lucideFilter, lucideFilterX })],
  imports: [
    BrnSheetContentDirective,
    BrnSheetTriggerDirective,
    HlmButtonDirective,
    HlmIconComponent,
    TranslateModule,
    HlmSheetComponent,
    HlmSheetContentComponent,
    HlmSheetHeaderComponent,
    HlmSheetTitleDirective,
    HlmSheetFooterComponent,
  ],
  template: `
    <hlm-sheet [side]="side()">
      <button [class]="btnClass()" hlmBtn variant="outline" brnSheetTrigger>
        <hlm-icon
          size="sm"
          [name]="isFilterActive() ? 'lucideFilterX' : 'lucideFilter'" />
        <span class="ps-1">{{ triggerLabel() | translate }}</span>
      </button>

      <hlm-sheet-content *brnSheetContent="let ctx">
        <hlm-sheet-header>
          <h3 hlmSheetTitle>{{ sheetLabel() }}</h3>
        </hlm-sheet-header>

        <ng-content />

        <hlm-sheet-footer>
          <button
            hlmBtn
            [disabled]="!isFilterActive()"
            variant="destructive"
            (click)="resetFilter(ctx)">
            {{ 'ACTION.REMOVE.CLEAN' | translate }}
          </button>
        </hlm-sheet-footer>
      </hlm-sheet-content>
    </hlm-sheet>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiTableFilterSheetComponent {
  side = input<'top' | 'bottom' | 'left' | 'right'>('right');
  triggerLabel = input<string>('Filtros');
  sheetLabel = input<string>('Filtros');
  isFilterActive = input(false, { transform: booleanAttribute });
  btnClass = input<string>('', { alias: 'class' });

  onClearFilter = output<boolean>();

  resetFilter(ctx: any) {
    this.onClearFilter.emit(true);
    setTimeout(() => {
      ctx.close();
    }, 250);
  }
}
