import {
  inject,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { Apollo, QueryRef, WatchQueryOptions } from 'apollo-angular';
import {
  BehaviorSubject,
  Observable,
  share,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import {
  apolloGetConnectionName,
  ApolloQuery,
} from '../../services/apollo-helper.service';
import { PartialExtended, Prettify } from '../../types/common';
import { IPaginatorState } from '../../ui/ui-paginator';
import { ITableSortState, SortDirection } from '../../ui/ui-table';
import {
  GenericPaginatorState,
  GenericTableState,
  IGenericTableService,
  ITableSearchableFields,
} from '../index';

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type TWatchQueryOptions = Prettify<
  Partial<Pick<WatchQueryOptions, 'query' | 'nextFetchPolicy'>> &
    Omit<WatchQueryOptions, 'query' | 'nextFetchPolicy'>
>;

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type WatchQueryOptionsExtendedType = Prettify<Partial<
  Pick<WatchQueryOptions, 'nextFetchPolicy'>
> &
  Omit<WatchQueryOptions, 'nextFetchPolicy' | 'variables'> & {
    processQueryResult?: boolean;
    processQueryPaginationInfo?: boolean;
  }>;

class DEFAULT_STATE implements GenericTableState {
  filter = {};
  searchableFields = [];
  paginator: IPaginatorState = new GenericPaginatorState();
  sorting: ITableSortState[] = [];
  searchTerm = '';
  // grouping = new GenericGroupingState();
}

@Injectable({
  providedIn: 'root',
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export abstract class GenericTableService<T = any>
  implements IGenericTableService
{
  //Services DI
  protected readonly apollo: Apollo = inject(Apollo);

  protected _querySubscription: Subscription | undefined = undefined;

  // Private fields
  private readonly destroyer$: Subject<any> = new Subject<any>();
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
  private readonly _errorMessage$ = new BehaviorSubject<string>('');

  private _apolloPostQuery: QueryRef<any>;
  private _defaultQueryFilters: Record<any, any> | undefined = undefined;

  private readonly _items: WritableSignal<T[]> = signal<T[]>([]);
  private readonly _tableState = signal<GenericTableState>(new DEFAULT_STATE());
  private readonly _isTableFilterActive = signal<boolean>(false);
  private readonly defaultQueryOptions: TWatchQueryOptions = {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    pollInterval: 300000,
  };

  readonly dataTableType: T[];

  abstract configureQueryOptions(): WatchQueryOptionsExtendedType;

  //<editor-fold desc="Getter & Setter">

  get defaultQueryFilters() {
    return this._defaultQueryFilters;
  }

  set defaultQueryFilters(value) {
    this._defaultQueryFilters = value;
  }

  get isTableFilterActive() {
    return this._isTableFilterActive;
  }

  get items() {
    return this._items.asReadonly();
  }

  public setItems(data: T[]) {
    this._items.set(data);
  }

  public setIsLoading(state: boolean) {
    this._isLoading$.next(state);
  }

  get tableState(): Signal<GenericTableState> {
    return this._tableState.asReadonly();
  }

  get isLoading$() {
    return this._isLoading$;
  }
  get isLoadingObservable() {
    return this._isLoading$.asObservable().pipe(share());
  }

  get errorMessage$() {
    return this._errorMessage$;
  }

  get querySubscription() {
    return this._querySubscription;
  }

  // State getters
  get paginator() {
    return this.tableState().paginator;
  }

  get filter() {
    return this.tableState().filter;
  }

  get sorting() {
    return this.tableState().sorting;
  }

  get searchTerm() {
    return this.tableState().searchTerm;
  }

  // get grouping() {
  //   return this.tableState().grouping;
  // }

  get tableSearchableFields(): ITableSearchableFields {
    return this.tableState().searchableFields;
  }

  set tableSearchableFields(value: ITableSearchableFields) {
    this._tableState.update((currentState) => {
      currentState.searchableFields = value;
      return currentState;
    });
  }

  //</editor-fold>

  /**
   * This method is only invoked when the service is handled by the AbstractGenericListHandler
   */
  public onInit() {}

  /**
   * This method is only invoked when the service is handled by the AbstractGenericListHandler
   */
  public onDestroy() {}

  /**
   * Perform update query data
   */
  public async refreshApolloQuery() {
    this.setIsLoading(true);
    await this._apolloPostQuery
      ?.refetch()
      .finally(() => this.setIsLoading(false));
  }

  public fetchApollo(query?: ApolloQuery) {
    const customOptions: PartialExtended<WatchQueryOptionsExtendedType> =
      this.configureQueryOptions();

    const apolloQuery = query ?? customOptions.query;
    const processResultQuery = customOptions?.processQueryResult ?? true;
    const processPaginationInfo =
      customOptions?.processQueryPaginationInfo ?? true;

    const deletableKeys = ['query', 'paginationInfo', 'processQueryResult'] as const
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    deletableKeys.forEach((key) => delete customOptions[key]);

    if (undefined === apolloQuery) {
      throw new Error(
        'You cant perform fetchApollo() without a query defined, you can provide one through the' +
          ' fetchApollo(query) itself or from the constructor of the service in the apolloQuery key',
      );
    }

    const searchOptions: Record<string, any> = {
      page: this.paginator.page,
      order: [],
      itemsPerPage: this.paginator.itemsPerPage,
    };

    //apply ordering
    if (this.sorting.length > 0) {
      Object.assign(searchOptions, this.processSortFields(this.sorting));
    }

    // apply filters
    if (undefined !== this.defaultQueryFilters) {
      Object.assign(searchOptions, this.defaultQueryFilters);
    }

    const filters = this.filter;
    if (Object.keys(filters).length > 0) {
      Object.assign(searchOptions, filters);
    }

    //method to extend and override the options sent to the query
    this.processSearchableFields(searchOptions);

    //query has filters
    this._isTableFilterActive.set(
      Object.keys(filters).length > 0 || this.searchTerm.length > 0,
    );

    const queryOptions = Object.assign(this.defaultQueryOptions, customOptions);

    this.setIsLoading(true);

    //execute query
    this._apolloPostQuery = this.apollo.watchQuery<T>({
      ...queryOptions,
      query: apolloQuery,
      variables: searchOptions,
    });

    // destroy previous subscription
    if (undefined !== this.querySubscription) {
      this.querySubscription.unsubscribe();
    }

    this._querySubscription = this.processApolloQueryValueChanges(
      this._apolloPostQuery.valueChanges,
    )
      .pipe(takeUntil(this.destroyer$))
      .subscribe({
        next: (response) => {
          if (!processResultQuery) {
            this.processCustomApolloQueryResult(response);
            return;
          }

          const data = response.data;
          const loading = response.loading;
          const errors = response.errors;

          this.setIsLoading(loading);

          if (undefined !== errors) {
            this._errorMessage$.next(errors[0].message);
          }

          if (!loading && undefined !== data) {
            const queryName = apolloGetConnectionName(data);

            if (queryName) {
              if (processPaginationInfo) {
                const paginationInfo = data[queryName].paginationInfo;
                if (undefined === paginationInfo) {
                  throw new Error(
                    'GenericTableService::fetchApollo -> The paginationInfo key is not defined within the query results.',
                  );
                }
                this.updateTablePaginationFromQueryResponse(paginationInfo);
              }

              this.setItems(data[queryName].collection);
            }
          }
        },
        error: (error) => {
          this.setItems([]);
          this.setIsLoading(false);
          // this._errorMessage$.next('GenericTableService::fetchApollo subscription failed.');
          throw error;
        },
        complete: () => {
          this.resetTableState();
        },
      });
  }

  public updateTablePaginationFromQueryResponse(paginationInfo: {
    itemsPerPage: number;
    lastPage?: number;
    totalCount: number;
  }) {
    this.patchStateApolloWithoutFetch({
      paginator: this.paginator.recalculatePaginator(paginationInfo),
    });
  }

  public resetTableState(): void {
    this.patchStateApolloWithoutFetch(new DEFAULT_STATE());
  }

  resetDefaultServiceState() {
    this.resetTableState();
    this.setIsLoading(true);
    this._errorMessage$.next('');
  }

  async patchStateApollo(patch: Partial<GenericTableState>) {
    this.patchStateApolloWithoutFetch(patch);
    this.fetchApollo();
  }

  patchStateApolloWithoutFetch(patch: Partial<GenericTableState>) {
    const newState = { ...this.tableState(), ...patch };

    this._tableState.set(newState);
  }

  processApolloQueryValueChanges(
    valueChanges: Observable<ApolloQueryResult<T>>,
  ): Observable<any> {
    return valueChanges;
  }

  processCustomApolloQueryResult(response: any): void {
    return response;
  }

  public processSortFields(sortState: ITableSortState[]): Record<string, unknown[]> {
    const fields: Record<string, SortDirection> = {};
    for (const item of sortState) {
      fields[item.column] = item.direction;
    }

    return {
      order: [fields],
    };
  }

  destroySubscription() {
    this.destroyer$.next(null);
  }

  private processSearchableFields(searchOptions: Record<any, any>) {
    const searchTerm = this.searchTerm;
    if (searchTerm.trim().length > 0) {
      this.tableSearchableFields.forEach((field) => {
        if ('string' === typeof field) {
          searchOptions[field] = searchTerm;
        }

        if ('object' === typeof field) {
          Object.entries(field).forEach((item) => {
            const [key, value] = item;

            const filtersArray: any[] = [];
            value.forEach((filterKey) => {
              const filterObject: Record<any, any> = {};
              filterObject[filterKey] = searchTerm;
              filtersArray.push(filterObject);
            });

            searchOptions[key] = filtersArray;
          });
        }
      });
    }
  }
}
