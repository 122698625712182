import { inject, Injectable } from '@angular/core';
import {
  GetCentersCommonDocument,
  type GetCentersCommonQuery,
  type GetCentersCommonQueryVariables,
} from '@graphql-types';
import { BehaviorSubject } from 'rxjs';
import { InferQueryType } from '../types';
import { ApolloHelperService } from './apollo-helper.service';

export type CenterCommonQuery = InferQueryType<
  GetCentersCommonQuery['centers']
>;

@Injectable({
  providedIn: 'root',
})
export class CommonQueriesService {
  private apolloHelperService = inject(ApolloHelperService);

  fetchAllCenters(isLoading$?: BehaviorSubject<boolean>) {
    return this.apolloHelperService.fetch<
      CenterCommonQuery[],
      GetCentersCommonQueryVariables
    >({
      query: GetCentersCommonDocument,
      isCollection: true,
      variables: { active: true, itemsPerPage: 99999 },
      isLoading$,
      convertTo: 'observable',
    });
  }
}
