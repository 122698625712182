import { computed, Directive, Input, input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const inputVariants = cva(
  'w-full flex text-foreground rounded-md border font-normal border-input bg-transparent dark:bg-gray-500/30 text-sm ring-offset-background file:border-0 file:text-foreground file:bg-transparent file:text-sm file:font-medium placeholder:text-muted dark:placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-500/10',
  {
    variants: {
      size: {
        initial: 'py-2 px-4',
        default: 'h-10 py-2 px-4',
        form:'w-[25vw] h-[51px]',
        sm: 'h-9 px-3',
        lg: 'h-[51px] px-8',
        recover: 'w-[74px] h-[74px]',
      },
      variant: {
        default: 'focus-visible:border-none active:border-none',
        form: 'focus-visible:border-none active:border-none bg-white rounded px-3',
        solid:
          'border-0 bg-gray-100 focus-visible:bg-gray-200 focus-visible:ring-gray-300 focus-visible:ring-1 dark:focus-visible:bg-gray-500/30 placeholder:text-gray-400',
      },
      error: {
        auto: '[&.ng-invalid.ng-dirty]:text-destructive [&.ng-invalid.ng-dirty]:border-destructive [&.ng-invalid.ng-touched]:border-destructive [&.ng-invalid.ng-dirty]:focus-visible:border-none [&.ng-invalid.ng-touched]:focus-visible:border-none [&.ng-invalid.ng-touched]:focus-visible:ring-destructive [&.ng-invalid.ng-dirty]:focus-visible:ring-destructive group-[&.ng-invalid.ng-dirty]:text-destructive group-[&.ng-invalid.ng-dirty]:border-destructive group-[&.ng-invalid.ng-touched]:border-destructive group-[&.ng-invalid.ng-dirty]:focus-visible:border-none group-[&.ng-invalid.ng-touched]:focus-visible:border-none group-[&.ng-invalid.ng-touched]:focus-visible:ring-destructive group-[&.ng-invalid.ng-dirty]:focus-visible:ring-destructive',
        true: 'text-destructive border-destructive focus-visible:ring-destructive',
      },
    },
    defaultVariants: {
      size: 'default',
      error: 'auto',
      variant: 'default',
    },
  },
);
export type InputVariants = VariantProps<typeof inputVariants>;

@Directive({
  selector: '[hlmInput]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class HlmInputDirective {
  private readonly _size = signal<InputVariants['size']>('default');
  @Input()
  set size(value: InputVariants['size']) {
    this._size.set(value);
  }

  private readonly _error = signal<InputVariants['error']>('auto');
  @Input()
  set error(value: InputVariants['error']) {
    this._error.set(value);
  }

  public readonly variant = input<InputVariants['variant']>('default');
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    hlm(
      inputVariants({
        size: this._size(),
        error: this._error(),
        variant: this.variant(),
      }),
      this.userClass(),
    ),
  );
}
