export const AUTH_LOCALE_ES = {
  AUTH: {
    FIELD: {
      EMAIL: 'Correo electrónico',
      PASSWORD: 'Contraseña',
      CONFIRM_PASSWORD: 'Confirmar contraseña',
      NEW_PASSWORD: 'Nueva contraseña',
    },
    LOGIN: {
      TITLE: 'Inicia sesión',
      FORGOT_PASSWORD: '¿Has olvidado la contraseña?',
      FORGOT_PASSWORD_GO: 'Restablecer la contraseña',
    },
    FORGOT_PASSWORD: {
      TITLE: '¿Olvidó su contraseña?',
      SUBTITLE: 'Ingrese su correo electrónico para restablecer su contraseña.',
    },
    RESET_PASSWORD: {
      TITLE: 'Recuperar contraseña',
      CREATE_NEW_PASSWORD: 'Crea tu nueva contraseña',
      SUBTITLE: 'Introduce el código para acceder a <b>{{ name }}</b>, si no lo encuentras quizás lo tengas en tu bandeja de spam.',
    },
  }
};
