export type SortDirection = 'asc' | 'desc' | '';

export enum SortDirectionOptions {
  asc = 'asc',
  desc = 'desc',
  undefined = '',
}

export interface ITableSortState {
  column: string;
  direction: SortDirection;
}
