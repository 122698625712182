import { NgClass, NgIf } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideIcons } from '@ng-icons/core';
import { lucideSearch } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { HlmInputDirective } from '@spartan-ng/ui-input-helm';

@Component({
  selector: 'ui-input-search',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    NgClass,
    HlmInputDirective,
    HlmIconComponent,
  ],
  providers: [provideIcons({ lucideSearch })],
  template: `
    <div [formGroup]="formGroup()" class="flex items-center relative">
      <hlm-icon
        *ngIf="showIcon"
        size="md"
        color="#c2c4bd"
        name="lucideSearch"
        class="absolute ms-2" />
      <input
        hlmInput
        [class.ps-9]="showIcon()"
        class="w-full md:w-[315px] rounded-[100px] h-[57px] border border-secondary"
        variant="solid"
        [ngClass]="class()"
        value=""
        type="text"
        [name]="controlName()"
        [formControlName]="controlName()"
        [placeholder]="placeholder() | translate" />
    </div>
  `,
  styles: [],
})
export class UiFormInputSearchComponent {
  placeholder = input<string>('ACTION.SEARCH.GENERIC');
  class = input<string>();
  formGroup = input.required<FormGroup>();
  controlName = input.required<string>();
  showIcon = input(true, { transform: booleanAttribute });
}
