import { inject } from '@angular/core';
import { CONNECTION_STATUS_CONFIG } from '../../../../_config';
import { fromEvent, merge, of, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { toast } from 'ngx-sonner';

export class ConnectionStatus {

  //DI
  private translateService: TranslateService = inject(TranslateService);
  private destroyer$: Subject<any> = new Subject<any>();

  networkStatus: boolean = false;

  startListener() {
    if (!CONNECTION_STATUS_CONFIG.enabled) {
      return;
    }

    const showStatusOnFirstLoad = CONNECTION_STATUS_CONFIG.showStatusOnFirstLoad ?? false;
    const showSuccessStatusOnFirstLoad = CONNECTION_STATUS_CONFIG.showSuccessStatusOnFirstLoad ?? false;

    let isFirstLoad = true;
    this.networkStatus = navigator.onLine;

    const onlineMessage = this.translateService.instant('TEXT.ONLINE_MESSAGE');
    const offlineMessage = this.translateService.instant('TEXT.OFFLINE_MESSAGE');

    merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(
        takeUntil(this.destroyer$),
        map(() => navigator.onLine),
      )
      .subscribe({
        next: status => {

          this.networkStatus = status;
          if (!this.networkStatus && (!isFirstLoad || (isFirstLoad && showStatusOnFirstLoad))) {
            toast.error(offlineMessage)
          }

          if (
            (isFirstLoad && showSuccessStatusOnFirstLoad && showStatusOnFirstLoad && this.networkStatus)
            || !isFirstLoad && this.networkStatus) {
            toast.success(onlineMessage);
          }

          isFirstLoad = false;

        },
      });
  }

  stopListener() {
    this.destroyer$.next(null);
    this.destroyer$.complete();
  }
}
