import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  BrnSelectComponent,
  BrnSelectContentComponent,
  BrnSelectGroupDirective,
  BrnSelectLabelDirective,
  BrnSelectOptionDirective,
  BrnSelectScrollDownDirective,
  BrnSelectScrollUpDirective,
  BrnSelectTriggerDirective,
  BrnSelectValueComponent,
} from '@spartan-ng/ui-select-brain';
import {
  HlmSelectContentDirective,
  HlmSelectDirective,
  HlmSelectGroupDirective,
  HlmSelectLabelDirective,
  HlmSelectOptionComponent,
  HlmSelectScrollDownComponent,
  HlmSelectScrollUpComponent,
  HlmSelectTriggerComponent,
  HlmSelectValueDirective,
} from '@spartan-ng/ui-select-helm';
import { TransformerPipe, TransformFn } from '../../pipes';

function itemsAttribute(values: any[] | undefined): any[] {
  if (undefined === values || 0 === values.length) return [];

  const element = values[0];
  if ('string' === typeof element) {
    return values.map((item: string) => ({ label: item, value: item }));
  }

  return values;
}

@Component({
  selector: 'ui-select',
  standalone: true,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  imports: [
    BrnSelectContentComponent,
    BrnSelectGroupDirective,
    BrnSelectLabelDirective,
    BrnSelectOptionDirective,
    BrnSelectScrollDownDirective,
    BrnSelectScrollUpDirective,
    BrnSelectTriggerDirective,
    BrnSelectValueComponent,
    BrnSelectComponent,

    HlmSelectContentDirective,
    HlmSelectTriggerComponent,
    HlmSelectOptionComponent,
    HlmSelectValueDirective,
    HlmSelectDirective,
    HlmSelectScrollUpComponent,
    HlmSelectScrollDownComponent,
    HlmSelectLabelDirective,
    HlmSelectGroupDirective,
    ReactiveFormsModule,
    TranslateModule,

    TransformerPipe,
  ],
  template: `
    <brn-select
      hlm
      class="flex h-[53px]"

      [placeholder]="placeholder() | translate"
      [multiple]="multiple()" >
      <hlm-select-trigger class="flex items-center w-full  bg-transparent h-[51px] border-[#041430] rounded-full text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#041430] focus:border-transparent transition duration-200">
        <hlm-select-value />
      </hlm-select-trigger>
      <brn-select-content hlm class="w-full">
        @for (item of items(); track trackByFn(item)) {
          <hlm-option
            [value]="
              undefined !== bindValue() ? item[bindValue() ?? 'value'] : item
            ">
            @if (undefined !== bindLabelTransformerFn()) {
              {{ item | transform: bindLabelTransformerFn() | translate }}
            } @else {
              {{ item[bindLabel()] | translate }}
            }
          </hlm-option>
        }
      </brn-select-content>
    </brn-select>
  `,
  styles: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFormSelectComponent {
  items = input<string[] | any[], any[]>([], { transform: itemsAttribute });
  trackBy = input<string | undefined>(undefined);
  controlName = input<string>('');

  bindValue = input<string>();
  bindLabel = input<string>('label');
  bindLabelTransformerFn = input<TransformFn>();
  placeholder = input<string>('Select');

  multiple = input(false, { transform: booleanAttribute });
  // disabled = input(false, { transform: booleanAttribute });

  protected trackByFn(item: any) {
    if (undefined !== this.trackBy() && null !== this.trackBy()) {
      return item[this.trackBy() ?? 'key'];
    }

    if (Object.prototype.hasOwnProperty.call(item, this.bindValue() || 'key')) {
      return item[this.bindValue() ?? 'key'];
    }

    return item;
  }
}
