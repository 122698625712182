import {
  assertInInjectionContext,
  computed,
  inject,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';


/**
 * This function takes an observable and converts it into two signals: 'value' and 'error'.
 * The 'value' signal emits the value emitted by the observable, while the 'error' signal emits any error occurred during the observable's execution.
 * If there is no error, the 'error' signal emits 'undefined'.
 * If there is no value emitted by the observable, the 'value' signal emits 'undefined'.
 *
 *
 * @param observable The observable to convert into signals.
 * @returns An object containing the 'value' and 'error' signals.
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function toSignalWithError<T>(observable: Observable<T>): {
  error: Signal<any>,
  value: Signal<T | undefined>,
} {

  const source = toSignal(
    observable.pipe(
      take(1),
      map(value => ({value, error: undefined})),
      catchError(error => of({value: undefined, error})),
    )
  );

  const value = computed(() => source()?.value)
  const error = computed(() => source()?.error)

  return {value, error}
}


export function injectActivatedRouteParams(): Signal<Params>;
export function injectActivatedRouteParams<T>(): Signal<T>;
export function injectActivatedRouteParams(key: string): Signal<string | null>;
export function injectActivatedRouteParams<T>(transform: (params: Params) => T): Signal<T>;
/**
 * Injects the params from the current route as signal.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 *
 */
export function injectActivatedRouteParams<T>(
  keyOrTransform?: string | ((params: Params) => T)
): Signal<T | Params | string | null> {
  assertInInjectionContext(injectActivatedRouteParams);
  return processActivatedRouteParamsToSignal('params', keyOrTransform)
}


export function injectActivatedRouteQueryParams(): Signal<Params>;
export function injectActivatedRouteQueryParams<T>(): Signal<T>;
export function injectActivatedRouteQueryParams(key: string): Signal<string | null>;
export function injectActivatedRouteQueryParams<T>(transform: (params: Params) => T): Signal<T>;
/**
 * Injects the query params from the current route as signal.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function injectActivatedRouteQueryParams<T>(
  keyOrTransform?: string | ((params: Params) => T)
): Signal<T | Params | string | null> {
  assertInInjectionContext(injectActivatedRouteQueryParams);
  return processActivatedRouteParamsToSignal('queryParams', keyOrTransform)
}


type RouteDataTransformFn<T> = (data: Data) => T;

/**
 * The `injectRouteData` function allows you to access and manipulate data from the current route.
 *
 * @returns {Signal} A `Signal` that emits the entire route data object.
 */
export function injectActivatedRouteData(): Signal<Data>;
export function injectActivatedRouteData<T>(): Signal<T>;

/**
 * The `injectActivatedRouteData` function allows you to access and manipulate data from the current route.
 * @param {string} key - The name of the route data object key to retrieve.
 * @param defaultValue
 * @returns {Signal} A `Signal` that emits the value of the specified route data object key
 */
export function injectActivatedRouteData<T>(key: keyof Data, defaultValue?: T): Signal<T>;

/**
 * The `injectQueryParams` function allows you to access and manipulate query parameters from the current route.
 *
 * @param {RouteDataTransformFn} transform - The name of the query parameter to retrieve.
 * @returns {Signal} A `Signal` that emits the transformed value of the specified route data object key.
 */
export function injectActivatedRouteData<T>(
  transform: RouteDataTransformFn<T>
): Signal<T>;

export function injectActivatedRouteData<T>(
  keyOrTransform?: keyof Data | ((data: Data) => T),
  defaultValue?: T
) {
  assertInInjectionContext(injectActivatedRouteData);
  const route = inject(ActivatedRoute);
  const initialRouteData = route.snapshot.data || {};

  const getDataParam =
    typeof keyOrTransform === 'function'
      ? keyOrTransform
      : (data: Data) =>
        keyOrTransform ? data?.[keyOrTransform] ?? null : data;

  return toSignal(route.data.pipe(map(getDataParam)), {
    initialValue: defaultValue ?? getDataParam(initialRouteData),
  });
}


/**
 * Generic function to process the params or queryParams of an ActivatedRoute in a standard way
 * @param internalKey
 * @param keyOrTransform
 */
function processActivatedRouteParamsToSignal<T>(
  internalKey: 'params' | 'queryParams',
  keyOrTransform?: string | ((params: Params) => T),
) {
  assertInInjectionContext(processActivatedRouteParamsToSignal);

  const route = inject(ActivatedRoute);
  const params = route.snapshot[internalKey] || {};

  if (typeof keyOrTransform === 'function') {
    return toSignal(route[internalKey].pipe(map(keyOrTransform)), {
      initialValue: keyOrTransform(params),
    });
  }

  const getParam = (params: Params) =>
    keyOrTransform ? params?.[keyOrTransform] ?? null : params;

  return toSignal(route[internalKey].pipe(map(getParam)), {
    initialValue: getParam(params),
  });
}
