import { Pipe, PipeTransform } from '@angular/core';
import { CellTransformerFn } from '../types/table-types';

@Pipe({
  name: 'cellTransformer',
  standalone: true,
})
export class CellTransformerPipe implements PipeTransform {
  transform<T extends object, K extends keyof T>(
    value: T[K],
    row: T,
    index: number,
    transformer: CellTransformerFn<T, any>,
  ) {
    return transformer({ value, rowData: row, index });
  }
}
