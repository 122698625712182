import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ContentChild,
  CUSTOM_ELEMENTS_SCHEMA,
  DoCheck,
  effect,
  inject,
  input,
  output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RxFor } from '@rx-angular/template/for';
import { RxIf } from '@rx-angular/template/if';
import { UiSpinnerComponent } from 'src/app/shared/ui';
import { CustomLoaderService } from '../../services';
import { SortIconComponent } from './component/sort-icon/sort-icon.component';
import { GenericTableRowActionTemplateDirective } from './directive/generic-table-action-template.directive';
import { GenericTableEmptyTemplateDirective } from './directive/generic-table-empty-template.directive';
import { GenericTableFooterTemplateDirective } from './directive/generic-table-footer-template.directive';
import { GenericTableHeaderTemplateDirective } from './directive/generic-table-header-template.directive';
import { GenericTableRowTemplateDirective } from './directive/generic-table-row-template.directive';
import { CellTransformerPipe } from './pipes/cell-transformer.pipe';
import { ITableSortState, SortDirectionOptions } from './types/sort.model';
import { ColumnConfig, GenericTableColumn } from './types/table-types';

@Component({
  selector: 'ui-table',
  templateUrl: './ui-table.component.html',
  styleUrl: './ui-table.component.scss',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    UiSpinnerComponent,
    CommonModule,
    TranslateModule,
    SortIconComponent,
    NgOptimizedImage,
    CellTransformerPipe,
    RxFor,
    RxIf,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class UiTableComponent<TItem extends object> implements DoCheck {
  //DI
  private customLoaderService = inject(CustomLoaderService);
  private cdr = inject(ChangeDetectorRef);

  loading = input<boolean, unknown>(false, { transform: booleanAttribute });
  data = input.required<TItem[]>();
  caption = input<string>();
  captionPlacement = input<'top' | 'bottom'>('top');

  columns = input<GenericTableColumn<TItem>[]>([]);
  trackBy = input<keyof TItem | undefined>(undefined);

  headerClass = input<string[] | string>('bg-gray-100 rounded');
  headerStyle = input<Record<string, any>>();

  striped = input(true, { transform: booleanAttribute });
  bordered = input(false, { transform: booleanAttribute });
  dashed = input(false, { transform: booleanAttribute });
  rounded = input(true, { transform: booleanAttribute });
  shadow = input(false, { transform: booleanAttribute });
  showRowBorder = input(false, { transform: booleanAttribute });
  rowHoverable = input(true, { transform: booleanAttribute });
  showRowBorderDashed = input(false, { transform: booleanAttribute });
  showSpinner = input(true, { transform: booleanAttribute });

  sortState = input<ITableSortState[]>();
  sortChange = output<ITableSortState[]>();

  @ContentChild(GenericTableHeaderTemplateDirective, { read: TemplateRef })
  headersTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableRowTemplateDirective, { read: TemplateRef })
  rowTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableRowActionTemplateDirective, { read: TemplateRef })
  rowActionTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableFooterTemplateDirective, { read: TemplateRef })
  footerTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableEmptyTemplateDirective, { read: TemplateRef })
  emptyTableTemplateRef: TemplateRef<any> | null = null;

  //no data options
  noDataMessage = input<string>('Lo sentimos no hay información disponible.');

  noDataShowImg = input(true, { transform: booleanAttribute });
  showTableIfEmpty = input(true, { transform: booleanAttribute });
  detectChanges = input(false, { transform: booleanAttribute });

  protected componentLoaderID: string =
    'generic-table-loaderId' + self.crypto.randomUUID();

  protected tableHasItems = computed(() => this.data().length > 0);

  constructor() {
    effect(() => {
      this.toggleBlockTableUI(this.loading());
    });
  }

  ngDoCheck(): void {
    if (this.detectChanges()) {
      this.cdr.markForCheck();
    }
  }

  toggleBlockTableUI(state: boolean | undefined | null) {
    if (!this.showSpinner) return;

    if (state) {
      this.customLoaderService.startLoader(this.componentLoaderID);
      return;
    }

    this.customLoaderService.stopLoader(this.componentLoaderID);
  }

  sortingEmitter(columnSortState: ITableSortState) {
    const newState = [...(this.sortState() ?? [])];

    const existIndex = newState.findIndex(
      (item) => item.column === columnSortState.column,
    );
    if (existIndex < 0) {
      newState.push(columnSortState);
    } else {
      newState[existIndex] = columnSortState;

      if (SortDirectionOptions.undefined === columnSortState.direction) {
        newState.splice(existIndex, 1);
      }
    }

    this.sortChange.emit(newState);
  }

  trackByFn = (index: number, item: any) => {
    if (undefined !== this.trackBy()) {
      return item[this.trackBy() ?? ''];
    }

    if ('id' in item) {
      return item.id;
    }

    return item;
  };

  trackHeaderBy = (_: any, header: GenericTableColumn) => {
    if (undefined !== header.config?.column) {
      return header.config.column;
    }

    return header.label;
  };

  getSortStateForColum(config?: ColumnConfig<any> | undefined) {
    if (!config) {
      return undefined;
    }

    const state = this.sortState();

    return state
      ? state.find((item: ITableSortState) => item.column === config.sortColumn)
      : undefined;
  }
}
