import { ICrudLocale } from '@app/i18n/interface';

export const ALL_USER_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  ALL_USER: {
    LABEL: {
      SINGULAR: 'Usuario',
      PLURAL: 'Usuarios',
    },
    FIELD: {
      NAME: 'Nombre del usuario',
      USERNAME: 'Usuario',
      USER_ROLL: 'Rol del usuario',
      USER_TYPE: 'Tipo de usuario*',
      USER_DATA: 'Datos del usuario',
      STATUS: 'Estado',
      ACTIONS: 'Acciones',
      EMAIL: 'Contacto',
      GENDER: 'Género',
      ENABLED: 'Activo',
      LASTNAME: 'Apellido',
      PASSWORD: 'Contraseña',
      MOBILE: 'Teléfono',
      ROL: 'Rol',
      DNI: 'Dni',
      GROUPS: 'Tipo',
      CENTER: 'Centro',
      BIRTHDATE: 'Fecha de nacimiento',
      SELF_GENERATED_PASSWORD: 'Contraseña autogenerada',
      COLLEGIATE_NUMBER: 'Número de colegiado',
      LAST_LOGIN: 'Último acceso',
      BTN:'Guardar usuario'
    },
    LIST: {
      TITLE: 'Listado de usuarios',
      FILTERS: 'Filtros',
      BTN: '+ Añadir usuario',
    },
  },
};
