import { inject, Injectable, InjectionToken } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { of, Subject, takeUntil } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

export interface ManagerEncryptor {
  encrypt: (decryptedValue: string) => string;
  decrypt: (encryptedValue: string) => string;
}

export interface ManagerStorage {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
}

class Base64Encryptor implements ManagerEncryptor {
  encrypt = (string: string): string => {
    return self.btoa(string);
  };
  decrypt = (string: string) => {
    return self.atob(string);
  };
}

export const FORMS_MANAGER_STORAGE = new InjectionToken<ManagerStorage>(
  'Defines the storage of the state when it is persisted',
  {
    providedIn: 'root',
    factory: () => localStorage,
  },
);

export const FORMS_MANAGER_ENCRYPTOR = new InjectionToken<ManagerEncryptor>(
  'Defines the encryptor for a state when it is persisted',
  {
    providedIn: 'root',
    factory: () => new Base64Encryptor(),
  },
);

const ALL_REFS = '$ALL';
type State = { data: unknown; expiresOn: number | undefined };
type StateCache = {
  form?: FormGroup | AbstractControl | FormControl;
  state?: State | undefined;
  encrypted: boolean;
};
type StateConfig = {
  persistState?: boolean;
  ttl?: number;
  debounceTime?: number;
  encryptStore?: boolean;
};
type StateOptions = Omit<StateConfig, 'debounceTime'> & {
  patchValue?: boolean;
};

@Injectable({
  providedIn: 'root',
})
/**
 * Allows you to manage the states of the reactive forms and be able to use each state in different logics
 * regardless of whether the form is active or not.
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class FormStateManager {
  #cache = new Map<string, StateCache>();
  #destroyer$ = new Subject<string>();

  #store = inject(FORMS_MANAGER_STORAGE);
  #crypto = inject(FORMS_MANAGER_ENCRYPTOR);

  //<editor-fold desc="Public Api">

  /**
   * Create a subscription to the FormGroup or FormControl provided and stores its value in the desired key.
   *
   * @param {string} key
   * @param {(FormGroup | AbstractControl | FormControl)} form
   * @param {StateConfig} [config]
   * @return {this}
   * @memberof FormStateManager
   */
  track(
    key: string,
    form: FormGroup | AbstractControl | FormControl,
    config?: StateConfig,
  ): this {
    const initialState: StateCache = {
      form,
      state: undefined,
      encrypted: true === config?.encryptStore,
    };

    if (this.#cache.has(key)) {
      this.#destroyRef(key);
    }

    const previousState = this.getState(key);
    if (previousState) {
      initialState.state = previousState;
    }

    this.#cache.set(key, initialState);

    form.valueChanges
      .pipe(
        takeUntil(
          this.#destroyer$.pipe(
            filter((ref) => ref === key || ref === ALL_REFS),
          ),
        ),
        distinctUntilChanged(),
        debounceTime(config?.debounceTime ?? 1_000),
      )
      .subscribe({
        next: (values) => this.updateState(key, values, config),
        error: (_) => of({}),
      });

    return this;
  }

  /**
   * Cancel the subscription made to the form changes given a specific key.
   * A bool can be supplied as second parameter to determine if you also want to clean the stored cache
   *
   * @param {string} key
   * @param {boolean} [clear=false]
   * @return {this}
   * @memberof FormStateManager
   */
  unTrack(key: string, clear: boolean = false): this {
    this.#destroyRef(key);

    if (clear) {
      this.clear(key);
    }

    return this;
  }

  /**
   * Cancels all active subscriptions stored in memory.
   * A bool can be supplied as second parameter to determine if you also want to clean the stored cache
   *
   * @param {boolean} [clear=true]
   * @return {this}
   * @memberof FormStateManager
   */
  unTrackAll(clear: boolean = true): this {
    this.#destroyRef();

    if (clear) {
      this.clear();
    }

    return this;
  }

  /**
   * Recover an instance of the stored cache in the memory
   *
   * @param {string} key
   * @return {(StateCache | undefined)}
   * @memberof FormStateManager
   */
  get(key: string): StateCache | undefined {
    return this.#cache.get(key);
  }

  /**
   * Clean the cache given a key, if you do not supply a key, a cleaning of the entire handler will be done.
   *
   * @param {string} [key]
   * @return {this}
   * @memberof FormStateManager
   */
  clear(key?: string): this {
    if (undefined !== key) {
      this.#cache.delete(key);
      this.#storageRemove(key);
    } else {
      for (const cacheKey of this.#cache.keys()) {
        this.clear(cacheKey);
      }
    }

    return this;
  }

  /**
   * Directly update the state of a stored cache.
   *
   * @param {string} key
   * @param {*} value
   * @param {StateOptions} [options]
   * @return {this}
   * @memberof FormStateManager
   */
  updateState(key: string, value: unknown, options?: StateOptions): this {
    const {
      ttl = undefined,
      patchValue = false,
      encryptStore = false,
    } = options ?? {};

    let { persistState = false } = options ?? {};

    const state = {
      data: value,
      expiresOn: ttl ? new Date().getTime() + ttl : undefined,
    };

    const stateCache = this.#cache.get(key);
    if (stateCache) {
      stateCache.state = state;
      persistState = stateCache.encrypted;
      this.#cache.set(key, stateCache);
    }

    if (persistState) {
      this.#storageSet(key, state, encryptStore);
    }

    if (patchValue) {
      const formInstance = stateCache?.form;
      formInstance?.patchValue(value);
    }

    return this;
  }

  /**
   * Recovers only the state of a stored cache according to the key provided
   *
   * @template TModel
   * @param {string} key
   * @return {(TModel | undefined)}
   * @memberof FormStateManager
   */
  getState<TModel extends Record<any, any> = any>(
    key: string,
  ): TModel | undefined {
    const cacheState = this.#cache.get(key);

    const state: State | undefined =
      cacheState?.state ??
      this.#storageGet(key, cacheState?.encrypted) ??
      undefined;

    if (undefined !== state?.expiresOn) {
      if (new Date().getTime() >= state.expiresOn) {
        this.invalidateState(key);
        return undefined;
      }
    }

    return state?.data as TModel;
  }

  /**
   * Invalidate the state of a stored cache
   *
   * @param {string} key
   * @return {this}
   * @memberof FormStateManager
   */
  invalidateState(key: string): this {
    const staleCache = this.#cache.get(key);
    if (staleCache) {
      staleCache.state = undefined;
      this.#cache.set(key, staleCache);
    }

    this.#storageRemove(key);
    return this;
  }

  //</editor-fold>

  //<editor-fold desc="Private Api">
  #encrypt(value: unknown) {
    return this.#crypto.encrypt(JSON.stringify(value));
  }

  #decrypt(value: string) {
    return JSON.parse(this.#crypto.decrypt(value));
  }

  #destroyRef(key?: string) {
    this.#destroyer$.next(key ?? ALL_REFS);
  }

  //</editor-fold>

  //<editor-fold desc="Localstorage Api">
  #storageSet(key: string, state: State, encryptStore: boolean = false) {
    if (encryptStore) {
      this.#store.setItem(this.#encrypt(key), this.#encrypt(state));
      return;
    }

    this.#store.setItem(key, JSON.stringify(state));
  }

  #storageGet(key: string, isEncrypted: boolean = false): State | null {
    const stateStorage = this.#store.getItem(
      isEncrypted ? this.#encrypt(key) : key,
    );

    if (null === stateStorage) return null;

    return isEncrypted ? this.#decrypt(stateStorage) : JSON.parse(stateStorage);
  }

  #storageRemove(key: string) {
    this.#store.removeItem(key);
    this.#store.removeItem(this.#encrypt(key));
  }

  //</editor-fold>
}
