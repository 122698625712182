import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateFormat',
  standalone: true,
})
@Injectable({providedIn: 'root'})
export class DateFormatPipe implements PipeTransform {

  private datePipe = inject(DatePipe);

  transform(
    value: Date | string | number | null | undefined,
    options: {
      format?: string,
      timezone?: string,
      locale?: string
    } = {}
  ): string | null {

    const {
      format,
      timezone,
      locale
    } = options

    return this.datePipe.transform(value, format, timezone, locale);
  }
}
