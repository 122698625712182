import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_DEFAULT_DATE_TIMEZONE_PIPE } from '../../../_config';
import {
  dateHelperEpochToDate,
  dateHelperToDate,
} from '../utils/tools/date-tools';

@Pipe({
  name: 'elapsedTime',
  standalone: true,
  pure: true
})
@Injectable({
  providedIn: 'root'
})
/**
 * The ElapsedTimeFromDatePipe is a custom Angular pipe that calculates the elapsed time between a given date and the
 * current date. It can take in three parameters: currentValue, which is the date to calculate the elapsed time from;
 * valueFrom, which specifies the format of the date (date, epoch, or timestamp); and mode, which determines whether to
 * return the elapsed time, periodicity, difference in days, or age in years.
 *
 * The getTimeElapsed method calculates the actual elapsed time and returns it in a human-readable format. It first calculates
 * the time difference between the current date and the given date in milliseconds. It then converts this value into
 * years, months, weeks, hours, and minutes. Depending on the mode parameter, it returns the elapsed time, periodicity,
 * or difference in days.
 *
 * The periodicityFn method returns a string that describes how often an event occurs. It looks at the time difference
 * in days and calculates if the event occurs every year, every certain number of years, every semester, every quarter, every month,
 * every certain number of months, every week, or every certain number of weeks.
 *
 * The elapsedTimeFn method returns a string that describes the elapsed time between the current date and the given date.
 * It looks at the time difference in days and calculates if the elapsed time is in years, months, weeks, days, hours, or minutes.
 * Overall, this pipe is useful for displaying time-related information in a human-readable format.
 */
export class ElapsedTimeFromDatePipe implements PipeTransform {

  private readonly translateService = inject(TranslateService);

  transform(
    currentValue: Date | string | number | null | undefined,
    valueFrom: 'date' | 'epoch' | 'timestamp' | 'string' = 'date',
    mode: | 'elapsed_time' | 'periodicity' | 'diff_days' | 'birthdate' = 'elapsed_time',
    timezone: string | undefined = APP_DEFAULT_DATE_TIMEZONE_PIPE
  ): string | null {

    if (undefined === currentValue || null === currentValue) {
      return null;
    }

    switch (valueFrom) {
      case "string":
      case "timestamp":
        currentValue = dateHelperToDate(valueFrom, timezone);
        break;
      case "epoch":

        if ('object' === typeof currentValue) {
          throw new Error('The value sent is an object does not match the valueFrom property of type epoch');
        }

        if ('string' === typeof currentValue) {
          currentValue = parseInt(currentValue);
        }

        currentValue = dateHelperEpochToDate(currentValue, timezone);
        break;
    }

    return currentValue instanceof Date
      ? this.getTimeElapsed(currentValue, mode, timezone)
      : null;
  }

  private getTimeElapsed(
    from: Date,
    mode: | 'elapsed_time' | 'periodicity' | 'diff_days' | 'birthdate' = 'elapsed_time',
    timezone?: string
  ) {
    const now = dateHelperToDate(Date.now(), timezone);

    // Calculate the time difference in milliseconds
    const timeDiff = Math.abs(now.getTime() - from.getTime());
    const days = Math.floor(timeDiff / (1000 * 3600 * 24));

    if ('diff_days' === mode) {
      return days;
    }

    if ('birthdate' === mode) {
      return Math.floor(days / 365);
    }


    const intervals = {
      years: days / 365,
      months: days / 30,
      weeks: days / 7,
      hours: Math.floor(timeDiff / 3600000),
      minutes: Math.floor(timeDiff / 60000),
    };

    const periodicityFn = () => {

      if (days === 0) {
        return this.translateService.instant('PERIODICITY.NONE');
      }

      if (intervals.years > 0 && (intervals.years % 1 === 0)) {

        switch (intervals.years) {
          case 1:
            return this.translateService.instant('PERIODICITY.EVERY_YEAR');
          default:
            return this.translateService.instant('PERIODICITY.EVERY_CERTAIN_YEARS', {value: intervals.years});
        }
      }

      if (intervals.months > 0 && (intervals.months % 1 === 0)) {

        switch (intervals.months) {
          case 6:
            return this.translateService.instant('PERIODICITY.EVERY_SEMESTER');
          case 3:
            return this.translateService.instant('PERIODICITY.EVERY_QUARTER');
          case 1:
            return this.translateService.instant('PERIODICITY.EVERY_MONTH');
          default:
            return this.translateService.instant('PERIODICITY.EVERY_CERTAIN_MONTHS', {value: intervals.months});
        }
      }
      if (intervals.weeks > 0 && (intervals.weeks % 1 === 0)) {
        switch (intervals.weeks) {
          case 1:
            return this.translateService.instant('PERIODICITY.EVERY_WEEK');
          default:
            return this.translateService.instant('PERIODICITY.EVERY_CERTAIN_WEEKS', {value: intervals.weeks});
        }
      }

      if (1 === days) {
        return this.translateService.instant('PERIODICITY.EVERY_DAY');
      }

      return this.translateService.instant('PERIODICITY.EVERY_CERTAIN_DAYS', {value: days});

    };
    const elapsedTimeFn = () => {
      const direction = this.translateService.instant(from.getTime() > now.getTime() ? 'PERIODICITY.IN' : 'PERIODICITY.AGO');
      
      if (days === 0) {

        if (intervals.hours > 0 && (intervals.hours % 1 === 0)) {
          switch (intervals.hours) {
            case 1:
              return `${direction} ${intervals.hours} ${this.translateService.instant('PERIODICITY.HOUR')}`
            default:
              return `${direction} ${intervals.hours} ${this.translateService.instant('PERIODICITY.HOURS')}`
          }
        }

        if (intervals.minutes > 0 && (intervals.minutes % 1 === 0)) {
          switch (intervals.minutes) {
            case 1:
              return `${direction} ${intervals.minutes} ${this.translateService.instant('PERIODICITY.MINUTE')}`
            default:
              return `${direction} ${intervals.minutes} ${this.translateService.instant('PERIODICITY.MINUTES')}`
          }
        }

        return this.translateService.instant('PERIODICITY.TODAY');
      }

      if (intervals.years >= 1) {
        switch (intervals.years) {
          case 1:
            return `${direction} ${intervals.years} ${this.translateService.instant('PERIODICITY.YEAR')}`
          default:
            return `${direction} ${Math.floor(intervals.years)} ${this.translateService.instant('PERIODICITY.YEARS')}`
        }
      }

      if (intervals.months >= 1) {
        switch (intervals.months) {
          case 1:
            return `${direction} ${intervals.months} ${this.translateService.instant('PERIODICITY.MONTH')}`
          default:
            return `${direction} ${Math.floor(intervals.months)} ${this.translateService.instant('PERIODICITY.MONTHS')}`
        }
      }

      if (intervals.weeks >= 1) {
        switch (intervals.weeks) {
          case 1:
            return `${direction} ${intervals.weeks} ${this.translateService.instant('PERIODICITY.WEEK')}`
          default:
            return `${direction} ${Math.floor(intervals.weeks)} ${this.translateService.instant('PERIODICITY.WEEKS')}`
        }
      }

      if (1 === days) {
        return `${direction} ${days} ${this.translateService.instant('PERIODICITY.DAY')}`
      }

      return `${direction} ${days} ${this.translateService.instant('PERIODICITY.DAYS')}`
    }

    if ('elapsed_time' === mode) {
      return elapsedTimeFn();
    }

    return periodicityFn();
  }
}
