import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { TokenModel } from '../../models/token.model';
import { ApiAuthUserDTO, AuthUserModel } from '../../models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/api/users`;
const API_LOGIN_URL = `${environment.apiUrl}/authentication_token`;
const API_LOGOUT_URL = `${environment.apiUrl}/api/token/invalidate`;
const API_REFRESH_TOKEN_URL = `${environment.apiUrl}/api/refresh_token`;
const API_GET_USER_URL = `${environment.apiUrl}/api/authenticated_user`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  private http: HttpClient = inject(HttpClient);

  login(email: string, password: string): Observable<TokenModel> {
    return this.http.post<TokenModel>(API_LOGIN_URL, {
      email,
      password,
    });
  }

  logout(): Observable<any> {
    return this.http.post<TokenModel>(API_LOGOUT_URL, {});
  }

  refreshToken(token?: string): Observable<TokenModel | undefined> {
    return this.http.post<TokenModel>(
      API_REFRESH_TOKEN_URL,
      token ? { refresh_token: token } : null,
    );
  }

  registerUser(user: AuthUserModel): Observable<AuthUserModel> {
    return this.http.post<AuthUserModel>(API_USERS_URL, user);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/reset-password-code`, {
      email,
    });
  }

  getAuthenticatedUser(): Observable<ApiAuthUserDTO> {
    return this.http.get<ApiAuthUserDTO>(API_GET_USER_URL);
  }

  changePasswordWithCode(parameters: unknown): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/check-password-code`,
      parameters,
    );
  }

  changePassword(parameters: {
    email: string;
    newPassword: string;
    oldPassword: string;
  }) {
    return this.http.post<any>(
      `${environment.apiUrl}/api/change_password`,
      parameters,
    );
  }
}
