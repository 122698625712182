import { DatePipe } from '@angular/common';
import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { dateHelperEpochToDate } from '../utils/tools/date-tools';

@Pipe({
  name: 'epochToDate',
  standalone: true,
})
@Injectable({providedIn: 'root'})
export class EpochToDatePipe implements PipeTransform {

  private datePipe = inject(DatePipe);

  transform(value: string | number | null | undefined, options: {
    format?: string
    timezone?: string,
    locale?: string,
  } = {}): string | null {

    if (undefined === value || null === value) {
      return null
    }

    const {
      format,
      timezone,
      locale
    } = options

    value = 'string' === typeof value ? Number.parseInt(value) : value;

    return this.datePipe.transform(dateHelperEpochToDate(value), format, timezone, locale);
  }

}
