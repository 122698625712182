import {
  DestroyRef,
  Directive,
  inject,
  Injector,
  OnInit,
  runInInjectionContext,
  Signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { SweetAlertService } from '../../../services';
import { GenericTableService, GenericTableState } from '../../index';
import { ITableSortState } from '../../../ui/ui-table';
import { IPaginatorState } from '../../../ui/ui-paginator';

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export interface ListOptions {
  tableService: GenericTableService;
  reactiveFilters?: Observable<GenericTableFilter>;
  searchFilter?: {
    enabled?: boolean;
    inputDelay?: number;
    controlName?: string;
  };
}

/**
 * Permite establecer los filtros a usar en el query, por defecto null o objeto vacio resetea los valores de los filtros en el query
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type GenericTableFilter = Record<any, any> | null;

/**
 * Abstract class that standardizes the work with generic listings
 *
 * Public access members
 * - `tableSearchFormGroup: FormGroup`
 * - `tableSearchFormControlName: string` (Default value "searchTerm")
 * - `tableIsFilterActive: Signal<boolean>`
 * - `tableIsLoading: Signal<boolean>`
 * - `tableDataSource: Signal<any[]>`
 * - `tableState: Signal<GenericTableState>`
 *
 * Services DI
 * - `protected sweetAlertService: SweetAlertService`
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
@Directive()
export abstract class AbstractGenericListHandler<T = any> implements OnInit {
  tableSearchFormGroup: FormGroup;
  tableSearchFormControlName: string = 'searchTerm';
  tableIsFilterActive: Signal<boolean>;
  tableDataSource: Signal<T[]>;
  tableIsLoading: Signal<boolean>;
  tableState: Signal<GenericTableState>;

  //Services DI
  protected readonly sweetAlertService: SweetAlertService =
    inject(SweetAlertService);
  private readonly handlerDestroyRef: DestroyRef = inject(DestroyRef);

  // private genericTableSubscriptions: Subscription[];
  private _tableServiceInstance: GenericTableService<T>;
  private injector = inject(Injector);

  ngOnInit() {
    this.handlerDestroyRef.onDestroy(() => {
      this.destroyListHandler();
    });
    this.initListHandler();
  }

  /**
   * Method in which we can configure our list dynamically
   * for more information review the ListOptions interface
   * @return ListOptions
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public abstract configureList(): ListOptions;

  public initListHandler(): void {
    this._tableServiceInstance = this.configureList().tableService;
    const searchConfig = this.configureList().searchFilter;

    //call table service onInit
    this._tableServiceInstance.onInit();

    if (searchConfig?.enabled) {
      this.tableSearchFormControlName =
        searchConfig.controlName ?? this.tableSearchFormControlName;
      this.initSearchFilter(searchConfig?.inputDelay);
    }

    this.tableDataSource = this._tableServiceInstance.items;
    this.tableState = this._tableServiceInstance.tableState;
    this.tableIsFilterActive = this._tableServiceInstance.isTableFilterActive;

    runInInjectionContext(this.injector, () => {
      this.tableIsLoading = toSignal(
        this._tableServiceInstance.isLoadingObservable,
        { requireSync: true },
      );
    });

    //fetch initial data form server
    this._tableServiceInstance.fetchApollo();

    // table subscriptions
    this._tableServiceInstance.errorMessage$
      .pipe(
        filter((message) => message.trim().length > 0),
        takeUntilDestroyed(this.handlerDestroyRef),
      )
      .subscribe(async (message) => await this.alertFromTableError(message));

    this.configureList()
      .reactiveFilters?.pipe(takeUntilDestroyed(this.handlerDestroyRef))
      .subscribe(async (filters) => await this.applyFilterPatch(filters));
  }

  /**
   * Invoked when the reference to the component is destroyed
   */
  public onDestroyHandler() {}

  private destroyListHandler(): void {
    this.onDestroyHandler();
    this._tableServiceInstance.onDestroy();
    this._tableServiceInstance.setItems([]);
    this._tableServiceInstance.resetTableState();
    this._tableServiceInstance.setIsLoading(false);
    this._tableServiceInstance.defaultQueryFilters = {};
    this._tableServiceInstance.tableSearchableFields = [];
    this._tableServiceInstance.destroySubscription();
    this._tableServiceInstance.querySubscription?.unsubscribe();
  }

  /**
   * Initialize the global search term filter
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   * @param delay
   */
  public initSearchFilter(delay: number = 500) {
    this.tableSearchFormGroup = new FormGroup({
      [this.tableSearchFormControlName]: new FormControl(''),
    });

    const inputValues$ = this.tableSearchFormGroup.get(
      this.tableSearchFormControlName,
    )?.valueChanges;

    if (undefined === inputValues$) return;

    inputValues$
      .pipe(
        takeUntilDestroyed(this.handlerDestroyRef),
        distinctUntilChanged(),
        debounceTime(delay),
      )
      .subscribe((searchTerm) =>
        this._tableServiceInstance.patchStateApollo({ searchTerm }),
      );
  }

  /**
   * Allows you to refresh the query specified for the table
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public async applyRefreshPatch(): Promise<void> {
    await this._tableServiceInstance.refreshApolloQuery();
  }

  /**
   * Method that receives the event of the paginator component
   * to apply the changes in the list
   *
   * @param paginator IPaginatorState
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public applyPaginatorPatch(paginator: IPaginatorState): void {
    this._tableServiceInstance.patchStateApollo({ paginator }).catch((err: unknown) => console.error(err));
  }

  /**
   * Method that receives the event of the paginator component
   * to apply the changes in the list
   *
   * @param sorting ITableSortState[]
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public applySortPatch(sorting: ITableSortState[]): void {
    this._tableServiceInstance.patchStateApollo({ sorting })
    .catch((reason: unknown) => {
      console.log(reason);
    });
  }

  /**
   * Apply filters to the table
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  private async applyFilterPatch(filter: object | null): Promise<void> {
    await this._tableServiceInstance.patchStateApollo({ filter: filter ?? {} });
  }

  /**
   * This method is used to overwrite the alert system in case of errors in the table
   * @param message
   *
   * @example 1
   *  public async alertFromTableError(message: string) {
   *    await this.sweetAlert.error({
   *       text: message
   *     });
   *   }
   *
   * @example 2
   *  public async alertFromTableError(message: string) {
   *    await this.toaster.error(message);
   *   }
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public async alertFromTableError(message: string) {
    void this.sweetAlertService.error({
      text: message,
    });
  }
}
