import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ApolloFormError } from '../../services/apollo-helper.service';

export function formHelperIsControlValid(
  control: AbstractControl<any, any> | FormControl | null,
): boolean {
  if (null === control) {
    throw new Error('isControlValid::control cannot be null.');
  }

  return control.valid && (control.dirty || control.touched);
}

export function formHelperIsControlInvalid(
  control: AbstractControl<any, any> | FormControl | null
): boolean {
  if (null === control) {
    throw new Error('isControlInvalid::control cannot be null.');
  }

  return control.invalid && (control.dirty || control.touched);
}

export function formHelperControlHasError(
  control: AbstractControl<any, any> | FormControl | null,
  validation: string
): boolean {
  if (null === control) {
    throw new Error('controlHasError::control cannot be null.');
  }

  return control.hasError(validation) && (control.dirty || control.touched);
}

export function formHelperIsControlTouched(
  control: AbstractControl<any, any> | FormControl | null
): boolean {
  if (null === control) {
    throw new Error('isControlTouched::control cannot be null.');
  }

  return control.dirty || control.touched;
}

/**
 * Update the values of a FromGroup based on the response from the server
 * takes the keys of an object and checks that a control with the same name exists
 * if exist update its value with the response value for that key
 *
 * @param form
 * @param response
 */
export function formHelperPatchFormValuesFromResponse(
  form: FormGroup,
  response: Record<string, any>
): void {
  Object.keys(response).forEach((key) => {
    if (form.controls[key]) {
      form.controls[key].patchValue(response[key]);
    }
  });
}

/**
 * Allows assigning an error to a FormControl, even if the rule does not exist in the established validations
 *
 * @param formGroup
 * @param field
 * @param rule
 * @param errorMessage
 */
export function formHelperSetFormControlError(
  formGroup: FormGroup,
  field: string,
  rule: string,
  errorMessage: string
) {
  const formControl = formGroup.get(field);
  if (formControl) {
    const validation: Record<any, any> = {};
    validation[rule] = errorMessage;
    formControl.setErrors(validation);
  }
}

/**
 * Allows you to add errors to controls within a FormGroup,
 * coming from the server
 *
 *
 * @param {FormGroup} formGroup
 * @param {ApolloFormError} reason
 * @param {string} propagateErrorAs
 */
export function formHelperAddFormGroupErrorsFromMutationResponse(
  formGroup: FormGroup,
  reason: ApolloFormError,
  propagateErrorAs: string
) {
  reason.errors.forEach((error) => {
    error.extensions?.violations?.forEach(({ path, message }) => {
      formHelperSetFormControlError(formGroup, path, propagateErrorAs, message);
    });
  });
}

/**
 * gets only the dirty(changed) values from a form
 *
 */
export function formHelperGetDirtyValues(form: FormGroup): object {
  const dirtyValues: Record<string, any> = {};

  Object.keys(form.controls).forEach((key) => {
    const currentControl: any = form.controls[key];

    if (currentControl.dirty) {
      if (currentControl.controls) {
        dirtyValues[key] = formHelperGetDirtyValues(currentControl);
      } else {
        dirtyValues[key] = currentControl.value;
      }
    }
  });

  return dirtyValues;
}
