import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  effect,
  inject,
  input,
  TemplateRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import {
  HlmCardContentDirective,
  HlmCardDescriptionDirective,
  HlmCardDirective,
  HlmCardFooterDirective,
  HlmCardHeaderDirective,
  HlmCardTitleDirective,
} from '@spartan-ng/ui-card-helm';
import { HlmSpinnerComponent } from '@spartan-ng/ui-spinner-helm';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CustomLoaderService } from '../../services/custom-loader.service';
import { UiSpinnerComponent } from '../ui-spinner/ui-spinner.component';
import { CardFooterDirective } from './directives/card-footer.directive';
import { CardTitleDirective } from './directives/card-title.directive';
import { CardToolbarDirective } from './directives/card-toolbar.directive';

@Component({
  selector: 'ui-card',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    HlmCardDirective,
    HlmCardHeaderDirective,
    HlmCardTitleDirective,
    HlmCardDescriptionDirective,
    HlmCardContentDirective,
    HlmCardFooterDirective,
    HlmButtonDirective,
    TranslateModule,
    HlmSpinnerComponent,
    UiSpinnerComponent,
  ],
  template: `
    <section
      hlmCard
      class="relative w-full"
      [class.bg-transparent]="transparent()"
      [class.border-none]="!bordered()">
      <ui-spinner [loaderId]="componentLoaderID" />

      @if (showCardHeader()) {
        <div hlmCardHeader>
          <div
            class="flex flex-col md:flex-row justify-between items-baseline space-y-3 lg:space-y-0 lg:space-x-4 flex-wrap">
            <div
              class="flex flex-col gap-1 pb-2"
              [ngClass]="{
                'w-full md:w-4/5 lg:w-1/2': toolbarTemplateRef,
                'w-full': !toolbarTemplateRef
              }">
              <h3 hlmCardTitle class="w-full">
                @if (titleTemplateRef) {
                  <ng-container *ngTemplateOutlet="titleTemplateRef" />
                } @else {
                  {{ label() || '' | translate }}
                }
              </h3>
              <p hlmCardDescription class="w-full">
                {{ description() || '' | translate }}
              </p>
            </div>

            @if (toolbarTemplateRef) {
              <div
                class="w-full flex-shrink-0 md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-2">
                <ng-container *ngTemplateOutlet="toolbarTemplateRef" />
              </div>
            }
          </div>
        </div>
      }

      <div hlmCardContent [ngClass]="{ 'px-6 pb-6 pt-0': showCardHeader() }">
        <ng-content />
      </div>

      @if (footerTemplateRef) {
        <div hlmCardFooter>
          <ng-container *ngTemplateOutlet="footerTemplateRef" />
        </div>
      }
    </section>
  `,
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class UiCardComponent {
  private customLoaderService: CustomLoaderService =
    inject(CustomLoaderService);

  label = input<string | undefined>(undefined);
  description = input<string | undefined>(undefined);
  transparent = input(false, { transform: booleanAttribute });
  bordered = input(false, { transform: booleanAttribute });
  blocked = input(false, { transform: booleanAttribute });

  componentLoaderID: string = 'ui-card-loaderId-' + self.crypto.randomUUID();

  @ContentChild(CardFooterDirective, { read: TemplateRef })
  footerTemplateRef?: TemplateRef<any>;

  @ContentChild(CardTitleDirective, { read: TemplateRef })
  titleTemplateRef?: TemplateRef<any>;

  @ContentChild(CardToolbarDirective, { read: TemplateRef })
  toolbarTemplateRef?: TemplateRef<any>;

  showCardHeader = computed(
    () => undefined !== this.label() || undefined !== this.description(),
  );

  constructor() {
    effect(() => {
      this.toggleLoading(this.blocked());
    });
  }

  private toggleLoading(sate: boolean | undefined | null) {
    if (true === sate) {
      this.customLoaderService.startLoader(this.componentLoaderID);
      return;
    }

    this.customLoaderService.stopLoader(this.componentLoaderID);
  }
}
