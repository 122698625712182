import { NgIf } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  signal,
  untracked,
} from '@angular/core';
import { provideIcons } from '@ng-icons/core';
import {
  lucideCircleAlert,
  lucideTriangleAlert,
  lucideBox,
  lucideCircleCheck,
  lucideShieldAlert,
} from '@ng-icons/lucide';
import {
  AlertVariants,
  HlmAlertDescriptionDirective,
  HlmAlertDirective,
  HlmAlertIconDirective,
  HlmAlertTitleDirective,
} from '@spartan-ng/ui-alert-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';

export type FlashAlertVariant = AlertVariants['variant'];

@Component({
  selector: 'ui-alert',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    HlmAlertDirective,
    HlmIconComponent,
    HlmAlertIconDirective,
    HlmAlertTitleDirective,
    HlmAlertDescriptionDirective,
  ],
  providers: [
    provideIcons({
      lucideShieldAlert,
      lucideTriangleAlert,
      lucideCircleAlert,
      lucideCircleCheck,
      lucideBox,
    }),
  ],
  template: `
    <ng-container *ngIf="showAlert()">
      <div
        hlmAlert
        [variant]="type()"
        class="flex flex-col justify-start border-l-4 sm:flex-row">
        <hlm-icon hlmAlertIcon [name]="iconName()" />

        <div class="flex-col">
          <h4 hlmAlertTitle>{{ title() }}</h4>
          <p hlmAlertDesc [innerHTML]="message()"></p>
        </div>

        <button
          type="button"
          class="absolute end-0 top-0 m-2 sm:relative sm:m-0 sm:ms-auto"
          *ngIf="dismissible()"
          (click)="hide()">
          <span class="svg-icon svg-icon-2x svg-icon-{{ type() }} ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                class=""
                opacity="0.5"
                x="7.05025"
                y="15.5356"
                width="12"
                height="2"
                rx="1"
                transform="rotate(-45 7.05025 15.5356)"
                fill="currentColor" />
              <rect
                class=""
                x="8.46447"
                y="7.05029"
                width="12"
                height="2"
                rx="1"
                transform="rotate(45 8.46447 7.05029)"
                fill="currentColor" />
            </svg>
          </span>
        </button>
      </div>
    </ng-container>
  `,
  styles: [``],
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class UiAlertComponent {
  type = input<AlertVariants['variant']>('default');
  title = input<string>();
  message = input<string>();
  dismissible = input(true, { transform: booleanAttribute });
  hlmIconName = input<string | undefined>();

  showAlert = signal<boolean>(true);
  iconName = computed<string>(() => {
    if (undefined !== this.hlmIconName())
      return this.hlmIconName() ?? 'lucideBox';

    switch (this.type()) {
      case 'success':
        return 'lucideCircleCheck';
      case 'info':
        return 'lucideCircleAlert';
      case 'warning':
        return 'lucideTriangleAlert';
      case 'destructive':
        return 'lucideShieldAlert';
      default:
        return 'lucideBox';
    }
  });

  constructor() {
    effect(() =>
      untracked(() =>
        this.showAlert.set(
          undefined !== this.title() || undefined !== this.message(),
        ),
      ),
    );
  }

  hide() {
    this.showAlert.set(false);
  }
}
