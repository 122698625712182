import { computed, Directive, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const inputErrorVariants = cva(
  'text-destructive text-[13px] font-medium mt-1 flex flex-col hide-all-except-first-child',
  {
    variants: {},
    defaultVariants: {},
  },
);
export type InputErrorVariants = VariantProps<typeof inputErrorVariants>;

@Directive({
  selector: '[hlmInputError], [hlmInputErrors]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class HlmInputErrorDirective {
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    hlm(inputErrorVariants(), this.userClass()),
  );
}
