import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import {
  HlmAlertDialogDescriptionDirective,
  HlmAlertDialogFooterComponent,
  HlmAlertDialogHeaderComponent,
  HlmAlertDialogTitleDirective,
} from '@spartan-ng/ui-alertdialog-helm';
import { ButtonVariants, HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import {
  BrnDialogRef,
  injectBrnDialogContext,
} from '@spartan-ng/ui-dialog-brain';
import { TranslateModule } from '@ngx-translate/core';

type AlertDialogActionContext = {
  show?: boolean;
  label?: string;
  variant?: ButtonVariants['variant'];
};

export type AlertDialogContext = {
  title?: string;
  description?: string;
  confirmAction?: AlertDialogActionContext;
  cancelAction?: AlertDialogActionContext;
  [key: string]: any
};

@Component({
  selector: 'ui-alert-dialog',
  standalone: true,
  imports: [
    HlmAlertDialogHeaderComponent,
    HlmAlertDialogTitleDirective,
    HlmAlertDialogDescriptionDirective,
    HlmAlertDialogFooterComponent,
    HlmButtonDirective,
    TranslateModule,
  ],
  template: `
    <hlm-alert-dialog-header>
      <h3 hlmAlertDialogTitle>{{ vmContext().title | translate }}</h3>
      <p hlmAlertDialogDescription>
        <span [innerHTML]="vmContext().description | translate"></span>
      </p>
    </hlm-alert-dialog-header>
    <hlm-alert-dialog-footer class="mt-3.5">
      @if (vmContext().cancel.show) {
        <button
          hlmBtn
          [variant]="vmContext().cancel.variant"
          (click)="close(false)">
          {{ vmContext().cancel.label | translate }}
        </button>
      }
      @if (vmContext().confirm.show) {
        <button
          hlmBtn
          [variant]="vmContext().confirm.variant"
          (click)="close(true)">
          {{ vmContext().confirm.label | translate }}
        </button>
      }
    </hlm-alert-dialog-footer>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAlertDialogComponent {
  private readonly dialogRef = inject<BrnDialogRef<boolean>>(BrnDialogRef);

  private readonly injectContext = signal<AlertDialogContext>(
    injectBrnDialogContext<AlertDialogContext>(),
  );
  protected readonly vmContext = computed(() => {
    return {
      title: this.injectContext().title ?? 'ALERT.CONFIRM_TITLE',
      description: this.injectContext().description ?? 'ALERT.CONFIRM_TEXT',
      confirm: {
        show: this.injectContext().confirmAction?.show ?? true,
        label:
          this.injectContext().confirmAction?.label ?? 'ACTION.CONFIRM.GENERIC',
        variant: this.injectContext().confirmAction?.variant ?? 'default',
      },
      cancel: {
        show: this.injectContext().cancelAction?.show ?? true,
        label:
          this.injectContext().cancelAction?.label ?? 'ACTION.CANCEL.GENERIC',
        variant: this.injectContext().cancelAction?.variant ?? 'ghost',
      },
    } as const;
  });

  close(state: boolean) {
    this.dialogRef.close(state);
  }
}
