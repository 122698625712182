import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { APP_DEFAULT_DATETIME_FORMAT_PIPE } from '../../../_config';

@Pipe({
  name: 'dateTimeFormat',
  standalone: true,
})
@Injectable({providedIn: 'root'})
export class DateTimeFormatPipe implements PipeTransform {

  private datePipe = inject(DatePipe);

  transform(
    value: Date | string | number | null | undefined,
    options: {
      format?: string,
      timezone?: string,
      locale?: string
    } = {}
  ): string | null {

    const {
      format = APP_DEFAULT_DATETIME_FORMAT_PIPE,
      timezone,
      locale
    } = options

    return this.datePipe.transform(value, format, timezone, locale);
  }
}
