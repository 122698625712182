import {
  ErrorHandler,
  inject,
  Injectable,
  Injector
} from '@angular/core';
import { GLOBAL_ERROR_CONFIG, handleGlobalErrorFn } from '../../../_config';

/**
 * Global error handling service, this service allows you to configure from "src\_config\global-error.ts"
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  private injector: Injector = inject(Injector);

  constructor() {
    window.addEventListener('error', (event) => {
      this.handleError(event.error);
      event.preventDefault();
      event.stopPropagation();
    });

    window.addEventListener('unhandledrejection', (event) => {
      this.handleError(event.reason);
      event.preventDefault();
      event.stopPropagation();
    });
  }

  handleError(error: any): void {
    if (!GLOBAL_ERROR_CONFIG.enabled) {
      throw error;
    }

    console.info('Start Global Handler Error');
    console.error('ErrorHandler ->', error);
    console.info('End Global Handler Error');

    handleGlobalErrorFn(error, this.injector);
  }
}
