import { PAGINATOR_LOCALE_EN } from '@app/shared/ui/ui-paginator/i18n/locale';
import { ILangVocab } from '../interface';

export const locale: ILangVocab = {
  lang: 'en',
  data: {
    CORE: {
      ROLE: {
        ADMIN: 'Admin',
        PATIENT: 'Patient',
      },
      GENDER: {
        MALE: 'Masculine',
        FEMALE: 'Feminine',
        INTERSEXUAL: 'Intersexual',
        OTHER: 'Other',
      },
      THEME_COLOR: {
        DEFAULT: 'Default',
        BLUE: 'Blue',
        ORANGE: 'Orange',
        RED: 'Red',
        VIOLET: 'Violet',
        SLATE: 'Slate',
        GREEN: 'Green',
        YELLOW: 'Yellow',
      },
      THEME_MODE: {
        SYSTEM: 'System',
        LIGHT: 'Light',
        DARK: 'Dark',
      },
      USER_MENU: {
        PROFILE: 'Profile',
        LANG: 'Language',
        LOGOUT: 'Sing out',
        THEME: {
          LABEL: 'Theme',
          COLOR: 'Color',
          MODE: 'Dark/Light',
        },
      },
    },
    TEXT: {
      MANAGEMENT: 'Management',
      ADMINISTRATION: 'Administration',
      SELECT: 'Select',
      PLEASE_WAIT: 'Please wait',
      ONLINE_MESSAGE: 'Your internet connection was restored.',
      OFFLINE_MESSAGE: 'You are not connected to the internet.',
      NO_ITEMS_FOUND: 'No items found',
    },
    ...PAGINATOR_LOCALE_EN,
  },
};
