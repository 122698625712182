import { booleanAttribute, computed, Directive, input } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';

export const paginationItemVariants = cva(
  'cursor-pointer hover:bg-secondary font-medium h-10 inline-flex items-center justify-center text-sm transition-colors w-10 [&.active]:bg-primary [&.active]:text-primary-foreground disabled:opacity-50 disabled:pointer-events-none focus-visible:outline-none [&[aria-disabled="true"]]:cursor-default [&[aria-disabled="true"]]:pointer-events-none [&[aria-disabled="true"]]:text-gray-400',
  {
    variants: {
      variant: {
        default: 'rounded-md',
        circle: 'rounded-full',
        square: 'rounded-0',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
export type PaginationItemVariants = VariantProps<
  typeof paginationItemVariants
>;

@Directive({
  selector: '[hlmPaginationItem]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
    '[class.active]': 'active()',
    '[attr.aria-disabled]': "disabled() ? 'true' : null",
    '[attr.tabindex]': "disabled() ? '-1' : null",
  },
})
export class HlmPaginationItemDirective {
  public readonly class = input('');
  public active = input(false, { transform: booleanAttribute });
  public disabled = input(false, { transform: booleanAttribute });
  public variant = input<PaginationItemVariants['variant']>('default');

  protected _computedClass = computed(() =>
    hlm(paginationItemVariants({ variant: this.variant() }), this.class())
  );
}
