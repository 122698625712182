import { Directive, Input } from '@angular/core';
import { GenericTableColumn } from '../types/table-types';

interface TableHeaderTemplateContext<TItem extends object> {
  $implicit: GenericTableColumn[];
  data: TItem[];
}

/**
 * Directive to perform content projection and data casting, to enable autocompletion and type inference in TS.
 *
 * To use it, just assign the directive in ng-template element, if you want to complete the fields, use the attr
 * [castDataTo]="myCustomType or myCustomInterface".
 *
 * There are only two variables that are injected, the first is implicit to reference the header configuration sent to the
 * same component and the second are all the elements of the table
 *
 * @example html
 *  <ng-template genTableHeader [castDataTo]="myCustomType[] or myCustomInterface[]" let-headersConfig let-rows="data">...template</ng-template>
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
@Directive({
  selector: '[genTableHeader]',
  standalone: true
})
export class GenericTableHeaderTemplateDirective<TItem extends object = any> {
  @Input('genTableHeader') data: TItem[] | string = [];

  static ngTemplateContextGuard<TItem extends object = any>(
    dir: GenericTableHeaderTemplateDirective<TItem>,
    ctx: unknown
  ): ctx is TableHeaderTemplateContext<TItem> {
    return true;
  }
}
