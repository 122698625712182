import { computed, Directive, Input, input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const alertVariants = cva(
  'relative w-full rounded-lg border border-border p-4 [&>[hlmAlertIcon]]:absolute [&>[hlmAlertIcon]]:text-foreground [&>[hlmAlertIcon]]:left-4 [&>[hlmAlertIcon]]:top-4 [&>[hlmAlertIcon]+div]:translate-y-[-3px] [&>[hlmAlertIcon]~*]:pl-7',
  {
    variants: {
      variant: {
        default: 'bg-popover text-popover-foreground',
        info: 'border-blue-500/50 bg-blue-500/5 dark:border-blue-500 [&>[hlmAlertIcon]]:text-blue-500 text-blue-500 [&> svg rect]:fill-blue-500',
        success:
          'border-green-500/50 bg-green-500/5 dark:border-green-500 [&>[hlmAlertIcon]]:text-green-500 text-green-500 [&> svg rect]:fill-green-500',
        warning:
          'border-amber-500/50 bg-amber-500/5 dark:border-amber-500 [&>[hlmAlertIcon]]:text-amber-500 text-amber-500 [&> svg rect]:fill-amber-500',
        destructive:
          'text-destructive bg-destructive/5 border-destructive/50 dark:border-destructive [&>[hlmAlertIcon]]:text-destructive [&> svg rect]:fill-destructive',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
export type AlertVariants = VariantProps<typeof alertVariants>;

@Directive({
  selector: '[hlmAlert]',
  standalone: true,
  host: {
    role: 'alert',
    '[class]': '_computedClass()',
  },
})
export class HlmAlertDirective {
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    hlm(alertVariants({ variant: this._variant() }), this.userClass())
  );

  private readonly _variant = signal<AlertVariants['variant']>('default');
  @Input()
  set variant(variant: AlertVariants['variant']) {
    this._variant.set(variant);
  }
}
