import * as i0 from '@angular/core';
import { Directive, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, forwardRef, NgModule } from '@angular/core';
import { provideExposesStateProviderExisting, provideCustomClassSettableExisting } from '@spartan-ng/ui-core';
import { BrnDialogContentDirective, BrnDialogDescriptionDirective, BrnDialogOverlayComponent, BrnDialogTitleDirective, BrnDialogTriggerDirective, BrnDialogComponent } from '@spartan-ng/ui-dialog-brain';
const _c0 = ["*"];
class BrnAlertDialogContentDirective extends BrnDialogContentDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnAlertDialogContentDirective_BaseFactory;
      return function BrnAlertDialogContentDirective_Factory(__ngFactoryType__) {
        return (ɵBrnAlertDialogContentDirective_BaseFactory || (ɵBrnAlertDialogContentDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnAlertDialogContentDirective)))(__ngFactoryType__ || BrnAlertDialogContentDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnAlertDialogContentDirective,
      selectors: [["", "brnAlertDialogContent", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([provideExposesStateProviderExisting(() => BrnAlertDialogContentDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnAlertDialogContentDirective, [{
    type: Directive,
    args: [{
      selector: '[brnAlertDialogContent]',
      standalone: true,
      providers: [provideExposesStateProviderExisting(() => BrnAlertDialogContentDirective)]
    }]
  }], null, null);
})();
class BrnAlertDialogDescriptionDirective extends BrnDialogDescriptionDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnAlertDialogDescriptionDirective_BaseFactory;
      return function BrnAlertDialogDescriptionDirective_Factory(__ngFactoryType__) {
        return (ɵBrnAlertDialogDescriptionDirective_BaseFactory || (ɵBrnAlertDialogDescriptionDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnAlertDialogDescriptionDirective)))(__ngFactoryType__ || BrnAlertDialogDescriptionDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnAlertDialogDescriptionDirective,
      selectors: [["", "brnAlertDialogDescription", ""]],
      hostVars: 1,
      hostBindings: function BrnAlertDialogDescriptionDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx._id());
        }
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnAlertDialogDescriptionDirective, [{
    type: Directive,
    args: [{
      selector: '[brnAlertDialogDescription]',
      standalone: true,
      host: {
        '[id]': '_id()'
      }
    }]
  }], null, null);
})();
class BrnAlertDialogOverlayComponent extends BrnDialogOverlayComponent {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnAlertDialogOverlayComponent_BaseFactory;
      return function BrnAlertDialogOverlayComponent_Factory(__ngFactoryType__) {
        return (ɵBrnAlertDialogOverlayComponent_BaseFactory || (ɵBrnAlertDialogOverlayComponent_BaseFactory = i0.ɵɵgetInheritedFactory(BrnAlertDialogOverlayComponent)))(__ngFactoryType__ || BrnAlertDialogOverlayComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnAlertDialogOverlayComponent,
      selectors: [["brn-alert-dialog-overlay"]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([provideCustomClassSettableExisting(() => BrnAlertDialogOverlayComponent)]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function BrnAlertDialogOverlayComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnAlertDialogOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'brn-alert-dialog-overlay',
      standalone: true,
      providers: [provideCustomClassSettableExisting(() => BrnAlertDialogOverlayComponent)],
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None
    }]
  }], null, null);
})();
class BrnAlertDialogTitleDirective extends BrnDialogTitleDirective {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnAlertDialogTitleDirective_BaseFactory;
      return function BrnAlertDialogTitleDirective_Factory(__ngFactoryType__) {
        return (ɵBrnAlertDialogTitleDirective_BaseFactory || (ɵBrnAlertDialogTitleDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnAlertDialogTitleDirective)))(__ngFactoryType__ || BrnAlertDialogTitleDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnAlertDialogTitleDirective,
      selectors: [["", "brnAlertDialogTitle", ""]],
      hostVars: 1,
      hostBindings: function BrnAlertDialogTitleDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx._id());
        }
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnAlertDialogTitleDirective, [{
    type: Directive,
    args: [{
      selector: '[brnAlertDialogTitle]',
      standalone: true,
      host: {
        '[id]': '_id()'
      }
    }]
  }], null, null);
})();
class BrnAlertDialogTriggerDirective extends BrnDialogTriggerDirective {
  set brnAlertDialogTriggerFor(brnDialog) {
    super.brnDialogTriggerFor = brnDialog;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBrnAlertDialogTriggerDirective_BaseFactory;
      return function BrnAlertDialogTriggerDirective_Factory(__ngFactoryType__) {
        return (ɵBrnAlertDialogTriggerDirective_BaseFactory || (ɵBrnAlertDialogTriggerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(BrnAlertDialogTriggerDirective)))(__ngFactoryType__ || BrnAlertDialogTriggerDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnAlertDialogTriggerDirective,
      selectors: [["button", "brnAlertDialogTrigger", ""], ["button", "brnAlertDialogTriggerFor", ""]],
      hostAttrs: ["aria-haspopup", "dialog"],
      hostVars: 4,
      hostBindings: function BrnAlertDialogTriggerDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id());
          i0.ɵɵattribute("aria-expanded", ctx.state() === "open" ? "true" : "false")("data-state", ctx.state())("aria-controls", ctx.dialogId);
        }
      },
      inputs: {
        brnAlertDialogTriggerFor: "brnAlertDialogTriggerFor"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnAlertDialogTriggerDirective, [{
    type: Directive,
    args: [{
      selector: 'button[brnAlertDialogTrigger],button[brnAlertDialogTriggerFor]',
      standalone: true,
      host: {
        '[id]': 'id()',
        'aria-haspopup': 'dialog',
        '[attr.aria-expanded]': "state() === 'open' ? 'true': 'false'",
        '[attr.data-state]': 'state()',
        '[attr.aria-controls]': 'dialogId'
      }
    }]
  }], null, {
    brnAlertDialogTriggerFor: [{
      type: Input
    }]
  });
})();
class BrnAlertDialogComponent extends BrnDialogComponent {
  constructor() {
    super();
    this._options.role = 'alertdialog';
    this._options.closeOnBackdropClick = false;
    this._options.closeOnOutsidePointerEvents = false;
  }
  static {
    this.ɵfac = function BrnAlertDialogComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnAlertDialogComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnAlertDialogComponent,
      selectors: [["brn-alert-dialog"]],
      exportAs: ["brnAlertDialog"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: BrnDialogComponent,
        useExisting: forwardRef(() => BrnAlertDialogComponent)
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function BrnAlertDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnAlertDialogComponent, [{
    type: Component,
    args: [{
      selector: 'brn-alert-dialog',
      standalone: true,
      template: `
		<ng-content />
	`,
      providers: [{
        provide: BrnDialogComponent,
        useExisting: forwardRef(() => BrnAlertDialogComponent)
      }],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      exportAs: 'brnAlertDialog'
    }]
  }], () => [], null);
})();
const BrnAlertDialogImports = [BrnAlertDialogComponent, BrnAlertDialogOverlayComponent, BrnAlertDialogTriggerDirective, BrnAlertDialogContentDirective, BrnAlertDialogTitleDirective, BrnAlertDialogDescriptionDirective];
class BrnAlertDialogModule {
  static {
    this.ɵfac = function BrnAlertDialogModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnAlertDialogModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnAlertDialogModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnAlertDialogModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnAlertDialogImports],
      exports: [...BrnAlertDialogImports]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BrnAlertDialogComponent, BrnAlertDialogContentDirective, BrnAlertDialogDescriptionDirective, BrnAlertDialogImports, BrnAlertDialogModule, BrnAlertDialogOverlayComponent, BrnAlertDialogTitleDirective, BrnAlertDialogTriggerDirective };
