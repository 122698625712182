
export const I18N_PARTIAL_CUSTOM_COMPONENTS_ES = {
  PERSONALIZED_AI_BOX: {
    ID: 'ID Paciente:',
    TYPE: 'Tipo:',
    BTN_AI: 'GENERAR AI'
  },
}


export const I18N_CUSTOM_COMPONENTS_ES = {
  ...I18N_PARTIAL_CUSTOM_COMPONENTS_ES,
}
