import { Directive, Input } from '@angular/core';

interface TableRowTemplateContext<TItem extends object> {
  $implicit: TItem;
  index: number;
  first: boolean;
  last: boolean;
  odd: boolean;
  even: boolean;
  count: number;
}


/**
 * Directive to perform content projection and data casting, to enable autocompletion and type inference in TS.
 *
 * To use it, just assign the directive in ng-template element, if you want to complete the fields, use the attr castDataTo to
 * get type inference.
 *
 * The data can be obtained by doing (let-data, let-row, let-item) or any name, the value is implicit therefore there is no need to define
 * where to get it from (let-data="data").
 *
 * You can also access all the variables defined by an NgFor directive, but these are necessary to specify which context key you want.
 *
 * @example html
 *  <ng-template genTableRow [castDataTo]="myCustomType or myCustomInterface" let-item let-index="index" let-even="even">
 *
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
@Directive({
  selector: `[genTableRow]`,
  standalone: true
})
export class GenericTableRowTemplateDirective<TItem extends object = any> {
  @Input('genTableRow') $implicit!: TItem[] | string;

  static ngTemplateContextGuard<TItem extends object = any>(
    dir: GenericTableRowTemplateDirective<TItem>,
    ctx: unknown
  ): ctx is TableRowTemplateContext<TItem> {
    return true;
  }
}
