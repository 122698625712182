interface CacheEntry<T> {
  value: T;
  expiresOn: number | undefined;
}

/**
 * CacheHandler is class that implements a simple cache state manager.
 * The cache stores key-value pairs, where the key is a string and the value can be of any type.
 * The class provides methods to manipulates values in the cache.
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class CacheHandler<T = any> {
  readonly #cache = new Map<string, CacheEntry<T>>();
  TTL: number | undefined = undefined;

  /**
   * Retrieves the value associated with the given key from the cache.
   * @param key The key to look up in the cache.
   * @returns The value associated with the key, or undefined if the key is not found.
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  get(key: string): T | undefined {
    const cached = this.#cache.get(key);

    if (undefined === cached) {
      return undefined;
    }

    if (undefined !== cached.expiresOn) {
      const hasExpired = new Date().getTime() >= cached.expiresOn;

      if (hasExpired) {
        this.delete(key);
        return undefined;
      }

    }

    return cached.value;
  }

  /**
   * Sets the value for the given key in the cache.
   * @param key The key to set in the cache.
   * @param value The value to associate with the key.
   * @param ttl The time to life of this cache entry.
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  set(key: string, value: T, ttl?: number): void {
    const TTL = ttl ?? this.TTL;
    const expiresOn = undefined !== TTL ? new Date().getTime() + TTL : undefined;

    const entry: CacheEntry<T> = {value, expiresOn};
    this.#cache.set(key, entry);
  }

  delete(key: string): boolean {
    return this.#cache.delete(key)
  }

  has(key: string): boolean {
    return this.#cache.has(key);
  }

  entries(): IterableIterator<[string, CacheEntry<T>]> {
    return this.#cache.entries();
  }

  keys(): IterableIterator<string> {
    return this.#cache.keys();
  }

  values(): IterableIterator<CacheEntry<T>> {
    return this.#cache.values();
  }

  clear(): void {
    this.#cache.clear();
  }

  size(): number {
    return this.#cache.size;
  }


}
