import { computed, Directive, input, Input, signal } from '@angular/core';
import { hlm } from '@spartan-ng/ui-core';
import { cva, VariantProps } from 'class-variance-authority';
import { ClassValue } from 'clsx';

export const buttonVariants = cva(
  'rounded-md inline-flex items-center transition-all duration-300 justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90 H-18-700-DM_Sans',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input text-foreground hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/50',
        'ghost-light':
          'bg-none text-gray-500 hover:text-primary/80 hover:bg-primary/5 dark:text-foreground dark:hover:text-primary-foreground dark:hover:bg-primary/30',
        light:
          'bg-primary/5 text-primary hover:text-primary-foreground hover:bg-primary dark:bg-primary/30 dark:text-primary-foreground',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'underline-offset-4 hover:underline text-primary',
        nonBg: 'hover:scale-105 active:scale-90 mt-2',
      },
      size: {
        sm: 'h-8 px-2',
        default: 'h-10 py-2 px-4',
        xl: 'w-[194px] h-[51px]',
        lg: 'h-11 py-1 px-4',
        'icon-sm': 'h-8 w-8',
        icon: 'h-10 w-10',
        'icon-md': 'h-9 w-9',
        'icon-lg': 'h-11 w-11',
      },
      rounded: {
        default: 'rounded-[100px]',
        none: 'rounded-none',
        full: 'rounded-full',
        lg: 'rounded-lg',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);
export type ButtonVariants = VariantProps<typeof buttonVariants>;

@Directive({
  selector: '[hlmBtn]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
})
export class HlmButtonDirective {
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  private readonly _settableClass = signal<ClassValue>('');

  protected _computedClass = computed(() =>
    hlm(
      buttonVariants({
        variant: this._variant(),
        size: this._size(),
        rounded: this._rounded(),
      }),
      this._settableClass(),
      this.userClass()
    )
  );

  setClass(value: ClassValue) {
    this._settableClass.set(value);
  }

  private readonly _rounded = signal<ButtonVariants['rounded']>('default');

  @Input()
  public set rounded(value: ButtonVariants['rounded']) {
    this._rounded.set(value);
  }

  private readonly _variant = signal<ButtonVariants['variant']>('default');
  @Input()
  set variant(variant: ButtonVariants['variant']) {
    this._variant.set(variant);
  }

  private readonly _size = signal<ButtonVariants['size']>('default');
  @Input()
  set size(size: ButtonVariants['size']) {
    this._size.set(size);
  }
}
