import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GetAuthenticatedUserDocument } from '@graphql-types';
import { isAfter } from 'date-fns';
import {
  BehaviorSubject,
  lastValueFrom,
  noop,
  Observable,
  of,
  tap,
} from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccessControl } from '../../../../_config';
import {
  ApolloHelperService,
  FormStateManager,
} from '../../../shared/services';
import { dateHelperEpochToDate } from '../../../shared/utils/tools';
import { AuthUserModel } from '../models/auth.model';
import { ITokenDecoded, TokenModel } from '../models/token.model';
import { AuthHTTPService } from './auth-http/auth-http.service';

/**
 * Reflect the data of the authenticated user can be Undefined as a default value or an AuthUserModel
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type AuthUserType = AuthUserModel | undefined;

export const LOGIN_ROUTE = '/auth/login';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly formStateManager = inject(FormStateManager);
  private readonly authHttpService = inject(AuthHTTPService);
  private readonly router = inject(Router);
  private readonly apolloHelperService = inject(ApolloHelperService);

  private _jwtDecodedToken: ITokenDecoded | undefined = undefined;

  // public fields
  static readonly LS_TOKEN_KEY = `${environment.appVersion}-token-${environment.USERDATA_KEY}`;
  static readonly LS_AUTH_USER_ID_KEY = `${environment.appVersion}-${environment.USERDATA_KEY}-user_id`;
  static readonly LS_REFRESH_TOKEN_KEY = `${environment.appVersion}-refresh_token`;
  static readonly LS_REFRESH_TOKEN_EXP_KEY = `${environment.appVersion}-session_ttl`;

  authToken: string | undefined = undefined;

  currentUser: WritableSignal<AuthUserType> = signal(undefined);

  isLoadingSubject = new BehaviorSubject<boolean>(false);

  get currentUserValue(): AuthUserType {
    return this.currentUser();
  }

  set currentUserValue(user: AuthUserType) {
    this.currentUser.set(user);
  }

  // public methods
  login(email: string, password: string): Observable<AuthUserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      tap((auth: TokenModel) => {
        AuthService.setAuthFromLocalStorage(auth);
        this.decodeJWTTokenFromStorage(auth.token);
      }),
      switchMap(() => this.getAuthenticatedUser()),
      catchError((err) => {
        console.error('login-error', err);
        return of(undefined);
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      }),
    );
  }

  logout(options?: {
    reloadPage?: boolean;
    redirectToCurrentUrl?: boolean;
    reloadPageDelay?: number;
    context?: Record<string, any>;
    clearServerSession?: boolean;
  }) {
    AuthService.removeAuthFromStorage();
    this.currentUserValue = undefined;

    const reloadPage: boolean = options?.reloadPage ?? false;
    const redirectToCurrentUrl: boolean = options?.redirectToCurrentUrl ?? true;
    const reloadPageDelay: number = options?.reloadPageDelay ?? 0;
    const context: Record<string, any> = options?.context ?? {};
    const clearServerSession = options?.clearServerSession ?? false;

    if (clearServerSession) {
      lastValueFrom(this.authHttpService.logout()).catch(noop);
    }

    const returnUrl = window.location.pathname;
    if (
      redirectToCurrentUrl &&
      !returnUrl.includes('/auth') &&
      !returnUrl.includes('/error')
    ) {
      context.redirectUrl = returnUrl;
    }

    const extras =
      Object.keys(context).length > 0 ? { queryParams: context } : {};

    this.formStateManager.unTrackAll(true);
    this.router
      .navigate([LOGIN_ROUTE], extras)
      .then(() => {
        if (reloadPage) {
          if (reloadPageDelay > 0) {
            setTimeout(() => {
              document.location.reload();
            }, reloadPageDelay);

            return;
          }
          document.location.reload();
        }
      })
      .catch(noop);
  }

  getAuthenticatedUser(): Observable<AuthUserType> {
    this.isLoadingSubject.next(true);

    return this.authHttpService.getAuthenticatedUser().pipe(
      take(1),
      map((user) => {
        return {
          ...user,
          id: user['@id'],
          type: user['@type'],
        };
      }),
      tap((user: AuthUserType) => (this.currentUserValue = user)),
      catchError((err) => {
        console.error('getUserByToken-error', err);
        return of(undefined);
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      }),
    );
  }

  // need create new user then login
  registration(user: AuthUserModel): Observable<AuthUserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.registerUser(user).pipe(
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('registration-error', err);
        return of(undefined);
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      }),
    );
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.forgotPassword(email).pipe(
      finalize(() => {
        this.isLoadingSubject.next(false);
      }),
    );
  }

  refreshToken() {
    return this.authHttpService.refreshToken().pipe(
      take(1),
      tap((newToken) => {
        if (undefined === newToken) {
          return;
        }
        AuthService.setAuthFromLocalStorage(newToken);

        this.decodeJWTTokenFromStorage(newToken.token);
      }),
    );
  }

  isAuthorized(roles: string | string[]) {
    roles = Array.isArray(roles) ? roles : [roles];
    return this.currentUserValue?.roles.some((role) =>
      roles.includes(role.toString()),
    );
  }

  isFullyAuthenticated(): boolean {
    return undefined !== this.currentUserValue;
  }

  userHasAccessToRoute(route: string | null = null): Promise<boolean> {
    return new Promise((resolve) => {
      if (!AccessControl.CHECK_ACCESS) {
        resolve(true);
        return;
      }

      //get the roles of the authenticated user
      const userRoles = this.currentUserValue?.roles;

      //user has no roles
      if (undefined === userRoles || 0 === userRoles.length) {
        if (AccessControl.GRANT_ACCESS_IF_USER_HAS_NO_ROLE) {
          resolve(true);
        } else {
          resolve(false);
        }

        return;
      }

      //get all allowed route by value
      const accessControl: Record<string, string[]> =
        AccessControl.getAccessControlListByRole();

      const permits: string[] = [];

      //we get all permissions for each user value
      userRoles.forEach((userRole) => {
        if (Object.prototype.hasOwnProperty.call(accessControl, userRole)) {
          const allowedRoutes = accessControl[userRole];
          if (allowedRoutes) {
            permits.push(...allowedRoutes);
          }
        }
      });

      if (route && !permits.includes(route)) {
        resolve(false);

        // In the case of bad configuration that by mistake we define that the route to redirect is a
        // route under access check and our user does not have access to said route will be logged out of the platform
        // and redirected to login.
        // And if the initial page of our platform after login is the same as redirection route then our user will never be able to access the platform
        if (AccessControl.REDIRECT_ON_ACCESS_DENIED_ROUTE.path === route) {
          this.logout();
          return;
        }

        this.router
          .navigateByUrl(AccessControl.REDIRECT_ON_ACCESS_DENIED_ROUTE.url)
          .catch(noop);
        return;
      }

      resolve(true);
    });
  }

  /**
   * Decode the JWT token used to authenticate, so we can get the metadata sent in the token
   */
  get jwtDecodedToken(): ITokenDecoded | undefined {
    return this._jwtDecodedToken;
  }

  set jwtDecodedToken(value: ITokenDecoded | undefined) {
    this._jwtDecodedToken = value;
  }

  decodeJWTTokenFromStorage(token?: string): undefined | ITokenDecoded {
    if (!token) {
      return undefined;
    }

    try {
      const jwtHelper = new JwtHelperService();

      this.authToken = token;

      // const cryptoHelper = new CryptoService();
      // const decryptedToken = cryptoHelper.decrypt(_token);
      const decryptedToken = token;
      const decodedToken = jwtHelper.decodeToken<ITokenDecoded | null>(
        decryptedToken,
      );

      if (!decodedToken) {
        return undefined;
      }

      this.jwtDecodedToken = decodedToken;

      return decodedToken;
    } catch {
      return undefined;
    }
  }

  fetchUserByIRI(iri: string, freezeResult?: boolean) {
    return this.apolloHelperService.fetchById({
      query: GetAuthenticatedUserDocument,
      id: iri,
      freezeResult,
      convertTo: 'promise',
    });
  }

  static setAuthFromLocalStorage(auth: TokenModel): boolean {
    if (auth.token && auth.refresh_token_expiration > 0) {
      // const jwtHelper = new JwtHelperService();
      // const decodedToken = jwtHelper.decodeToken(auth.token);

      localStorage.setItem(
        AuthService.LS_REFRESH_TOKEN_EXP_KEY,
        String(auth.refresh_token_expiration),
      );
      // localStorage.setItem(AuthService.LS_TOKEN_KEY, auth.token);
      // localStorage.setItem(
      //   AuthService.LS_AUTH_USER_ID_KEY,
      //   decodedToken.user.id,
      // );
      // localStorage.setItem(
      //   AuthService.LS_REFRESH_TOKEN_KEY,
      //   auth.refresh_token,
      // );
      return true;
    }
    return false;
  }

  static removeAuthFromStorage() {
    localStorage.removeItem(AuthService.LS_TOKEN_KEY);
    localStorage.removeItem(AuthService.LS_AUTH_USER_ID_KEY);
    localStorage.removeItem(AuthService.LS_REFRESH_TOKEN_KEY);
    localStorage.removeItem(AuthService.LS_REFRESH_TOKEN_EXP_KEY);
  }

  static isExpiredRefreshToken(): boolean {
    const refreshTokenExpiration = localStorage.getItem(
      AuthService.LS_REFRESH_TOKEN_EXP_KEY,
    );
    if (!refreshTokenExpiration) {
      return true;
    }

    const refreshTokenExpirationDate = dateHelperEpochToDate(
      Number(refreshTokenExpiration),
    );

    return isAfter(new Date(), refreshTokenExpirationDate);
  }

  static hasRefreshToken(): boolean {
    const refreshTokenExpiration = localStorage.getItem(
      AuthService.LS_REFRESH_TOKEN_EXP_KEY,
    );

    return refreshTokenExpiration ? true : false;
  }

  /**
   * @deprecated will be removed in next version
   * @returns
   */
  static getAuthFromStorage(): string | undefined {
    try {
      const lsValue = localStorage.getItem(AuthService.LS_TOKEN_KEY);
      if (!lsValue) {
        return undefined;
      }

      return lsValue;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  /**
   * @deprecated will be removed in next version
   * @returns
   */
  static getAuthRefreshTokenFromStorage(): string | undefined {
    try {
      const lsValue = localStorage.getItem(AuthService.LS_REFRESH_TOKEN_KEY);
      if (!lsValue) {
        return undefined;
      }

      return lsValue;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}
