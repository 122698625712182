import { GET_ALL_ROLES_BY_KEY, ROLE_ADMIN } from './roles';

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export interface DynamicNavigationItemType {
  label?: string
  submenu?: DynamicNavigationItemType[]
  svg?: string | undefined

  route?: string
  routeExactMatch?: boolean

  roles?: string[]
  isNotGrantedAction?: 'remove' | 'hide'

  header?: string
  headerSvg?: string
}

const ALL_ROLES = GET_ALL_ROLES_BY_KEY('value');

export const NAVIGATION_CONFIG: DynamicNavigationItemType[] = [
  {
    label: 'PATIENT.LABEL.PLURAL',
    route: '/management/user',
    routeExactMatch: false,
    svg: './assets/media/kidney/user.svg',
  },
  {
    label: 'SPECIALIST.LABEL.PLURAL',
    route: '/specialist',
    routeExactMatch: false,
    svg: './assets/media/kidney/user.svg',
  },
  {
    label: 'STATISTICS.LABEL.PLURAL',
    route: '/statistics',
    routeExactMatch: false,
    svg: './assets/media/kidney/estadisticas.svg',
  },

  {
    header: 'TEXT.ADMINISTRATION',
    roles:[ROLE_ADMIN.value]
  },
  {
    label: 'CENTER.LABEL.PLURAL',
    route: '/center',
    routeExactMatch: false,
    svg: './assets/media/kidney/center.svg',
    roles:[ROLE_ADMIN.value]
  },
  {
    label: 'ALL_USER.LABEL.PLURAL',
    route: '/all-user',
    routeExactMatch: false,
    svg: './assets/media/kidney/user.svg',
    roles:[ROLE_ADMIN.value]
  },


]
