import {
  Directive,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { GraphQLError } from 'graphql';
import { AbstractBaseEntity } from '../../../model';
import {
  apolloGetProcessedErrorFromResponse,
  ApolloMutation,
  ApolloMutationResult,
  ApolloQuery,
  MUTATION_ACTION,
  MutationAction,
} from '../../../services/apollo-helper.service';
import { SweetAlertService } from '../../../services/sweet-alert.service';
import { PartialExtended, Prettify, StringUnion } from '../../../types/common';
import {
  formHelperAddFormGroupErrorsFromMutationResponse,
  formHelperControlHasError,
  formHelperIsControlInvalid,
  formHelperIsControlValid,
  helperIriToId,
} from '../../../utils/tools';
import {
  DecoratorReflectMetadata,
  GenericCRUDService,
  GenericCRUDTableService,
} from '../../index';

type ExcludeFunctions<T> = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  [K in keyof T as T[K] extends Function ? never : K]: AbstractControl;
};

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type FormFields<T> = Prettify<
  PartialExtended<ExcludeFunctions<Omit<T, '__typename'>>, AbstractControl>
>;

/**
 * Allows you to establish the logic for each API resource class in a generic and dynamic way,
 * It is also possible to set new keys as metadata for each model and be accessible from activeAllowedDataModel
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
interface AllowedDataModel {
  /**
   * API resource class to use in the form, debe implementar @ApiResourceName decorator.
   */
  class: any;
  /**
   * Defines if it is the default model to use, in case there is more than one.
   */
  default?: boolean;
  /**
   * Specifies the query to use for this model, this query is used to fetch the server and return the
   * object to fill the form in edit mode
   */
  query?: ApolloQuery;
  /**
   * Specifies which fields of the class will be returned after performing the mutation.
   * This configuration key is not used in conjunction with customMutations
   */
  mutationSubQueryFields?: string[];
  /**
   * Allows you to define custom mutations for each defined action of the form
   */
  customMutations?: {
    [key in StringUnion<
      Exclude<
        keyof typeof MUTATION_ACTION,
        'isAllowedValue' | 'getAllValues' | 'delete'
      >
    >]?: ApolloMutation;
  };

  [key: string]: any;
}

export type GenericCRUDServiceType =
  | GenericCRUDTableService<any>
  | GenericCRUDService;

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export interface FormOptions {
  /**
   * Service that extends CRUDTableService to be able to handle the form.
   */
  crudService?: GenericCRUDServiceType | undefined;
  /**
   * Allows you to define which models will be used to work on the form, there should only be one by default,
   * if not specify the query key the fetch is not done when editing the element.
   *
   */
  allowedDataModels: AllowedDataModel[];
  /**
   * Clear the form when the creation mutation returns correctly.
   * By default, the form does not clean the values when creating the instance
   *
   *  @default false | undefined
   */
  cleanFormOnCreate?: boolean;
  /**
   *  Key that defines what data to send in the mutation,
   *  when the value is true only the fields defined in the reactive form are sent,
   *  when it is false a merge is created between the fields of the entity and those of the form
   *
   *  @default true
   *
   */
  sendOnlyFormFields?: boolean;
  /**
   * Defines whether the handler should process the response of the mutation carried out, it is very useful when our mutation
   * perform a sub-query with data from the mutated resource, for the generic case the active model must have set the
   * key "mutationSubQueryFields"
   */
  processSuccessMutationResult?: boolean;
  /**
   *  Allows you to define if the transformation of the form fields is done by reference in memory or as
   *  one separate copy at a time
   *
   *  @default true
   *
   */
  createFromDataClone?: boolean;
  /**
   * Key to configure the validation of the generic form
   */
  formValidations?: {
    /**
     * Executes the validation of each formControl even if it was not modified at the time of submitting the form to the server
     * in case of errors the form is marked as invalid,
     *
     * @default true
     */
    validateFieldsOnSubmit?: boolean;
    /**
     * It allows to propagate the error of the fields when the mutation returns from the server with errors from the server,
     * is used in conjunction with the propagateErrorAs key.
     *
     * @default true
     */
    applyFieldErrorFromServer?: boolean;
    /**
     * Defines the name of the validation rule that we will be listening to in case of errors on the server with the entity
     * the default value in case of not specifying would be "api_error"
     *
     * @default "api_error"
     */
    propagateErrorAs?: string;
  };
}

export const FORM_DEFAULT_API_ERROR_KEY = 'api_error';

/**
 * Abstract class that standardizes the work with reactive forms in a dynamic way
 *
 *
 * Public access members
 * - `genericEntityId: string` (Variable used to switch to edit mode and load the DataAllowedModel data)
 * - `ngxUILoader: string` (Variable that defines the value of the loaderID to be used in the views)
 *
 * Services DI
 * - `protected sweetAlertService: SweetAlertService`
 * - `protected customLoaderService: CustomLoaderService`
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
@Directive()
export abstract class AbstractGenericFormHandler<
  TModel extends AbstractBaseEntity,
  TQueryModel extends object = any,
> implements OnInit
{
  public formUUID: string = 'generic-form-' + self.crypto.randomUUID();
  public genericEntityId: string | undefined = undefined;

  //Services DI
  protected readonly sweetAlertService: SweetAlertService =
    inject(SweetAlertService);

  private readonly _form: WritableSignal<FormGroup> = signal(
    new FormGroup<any>({}),
  );
  private _entity: WritableSignal<TModel>;
  private _entityQueryData = signal<TQueryModel | undefined>(undefined);
  private _formConfig: FormOptions;
  private _activeAllowedDataModel: AllowedDataModel | undefined = undefined;
  private _crudServiceInstance: GenericCRUDServiceType =
    inject(GenericCRUDService);
  private readonly _isProcessingForm = signal<boolean>(false);
  private readonly _isFormSubmitted = signal<boolean>(false);

  async ngOnInit(): Promise<void> {
    this.beforeInitGenericFormHandler();
    await this.initGenericFormHandler();
    this.afterInitGenericFormHandler();
  }

  /**
   * Method in which we can configure our crud dynamically
   * for more information review the FormOptions interface
   * @return FormOptions
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  abstract configureForm(): FormOptions;

  /**
   * Method where we create the reactive form
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  abstract buildForm(): FormGroup<FormFields<TModel>>;

  /**
   * Initialize our handler
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public async initGenericFormHandler(): Promise<void> {
    const defaultAllowedDataModel = this.getDefaultAllowedDataModel();
    this._formConfig = this.configureForm();

    if (!defaultAllowedDataModel) {
      throw new Error(
        'You have not defined any instance by default, in the "allowedDataModels" key in the configureCrud()',
      );
    }

    this.activeAllowedDataModel = defaultAllowedDataModel;

    const providedCrudService = this._formConfig.crudService;
    if (undefined !== providedCrudService) {
      this._crudServiceInstance = providedCrudService;
    }

    if (undefined === this.activeAllowedDataModel.class.name) {
      throw new Error(
        'The value of instanceClass is not a valid class instance.',
      );
    }

    //We start the form with the default data so that the view has access from the beginning
    this._entity = signal(this.createNewEntityInstance());
    this.buildReactiveForm();

    this._isProcessingForm.set(true);

    await Promise.all([
      this.prefetchDataAfterBuildReactiveForm(),
      this.findOneInstanceByID(),
    ]);

    this._isProcessingForm.set(false);

    if (undefined !== this.genericEntityId) {
      this.buildReactiveForm();
    }
  }

  beforeInitGenericFormHandler() {}

  afterInitGenericFormHandler() {}

  //<editor-fold desc="getters setters">
  get form(): ReturnType<typeof this.buildForm> {
    return this._form();
  }

  set form(value: FormGroup) {
    this._form.set(value);
  }

  get entity(): TModel {
    return this._entity();
  }

  set entity(value: TModel) {
    this._entity.set(value);
  }

  get entityQueryData(): TQueryModel | undefined {
    return this._entityQueryData();
  }

  set entityQueryData(value: TQueryModel) {
    this._entityQueryData.set(value);
  }

  /**
   * Gets the AllowedDataModel used in the handler, allowing to obtain the configuration and all extra metadata for that model
   * Through the AllowedDataModel, the AbstractGenericFormHandler can create genetic mutations always to the correct class,
   * also what graphql query use to get an instance of our model when the action is edit
   *
   * @return AllowedDataModel
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  get activeAllowedDataModel(): AllowedDataModel | undefined {
    return this._activeAllowedDataModel ?? this.getDefaultAllowedDataModel();
  }

  /**
   * Sets the active model, so that our handler knows how to work correctly
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  set activeAllowedDataModel(value: AllowedDataModel | undefined) {
    this._activeAllowedDataModel = value;
  }

  /**
   * Gets the class from the active AllowedDataModel and returns it in string format
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  entityInstanceToString(): string {
    return DecoratorReflectMetadata.getClassName(
      this.activeAllowedDataModel?.class,
    );
  }

  /**
   * Gets Boolean observable that establish when the form is processing
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  get isProcessingForm() {
    return this._isProcessingForm.asReadonly();
  }

  get isFormSubmitted() {
    return this._isFormSubmitted.asReadonly();
  }

  /**
   * Allows you to get the generic service of type GenericCRUDTableService set in the configureCrud() method
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  get crudServiceInstance(): GenericCRUDServiceType {
    return this._crudServiceInstance;
  }

  //</editor-fold>

  patchFormState(state?: PartialExtended<TModel>) {
    if (undefined !== state) {
      this.form.patchValue(state as any);
    }
  }

  getFormName() {
    const entityId = this.genericEntityId
      ? '_' + helperIriToId(this.genericEntityId)
      : '';
    return `form-${this.entityInstanceToString()}-${this.getFormAction() + entityId}`.toLowerCase();
  }

  /**
   * Method responsible for processing the sending of the form to the server
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  async submitForm() {
    if (
      undefined === this._formConfig.formValidations?.validateFieldsOnSubmit ||
      this._formConfig.formValidations?.validateFieldsOnSubmit
    ) {
      //Validates each control within a FormGroup and triggers its validations even when the control was not touched
      this.form.markAllAsTouched();
    }

    if (this.form.invalid) {
      this.onFormInvalid();
      return;
    }

    const action: string = this.getFormAction();

    if (this.checkFormHasGlobalCustomError(this.form, action)) return;

    let formData =
      this._formConfig.createFromDataClone ||
      undefined === this._formConfig.createFromDataClone
        ? structuredClone(this.form.getRawValue() as PartialExtended<TModel>)
        : (this.form.getRawValue() as PartialExtended<TModel>);

    //We add the id of the entity to the formData so that it is sent as a variable to our mutation
    if (MUTATION_ACTION.update === action && undefined !== this.entity.id) {
      formData.id = this.entity.id;
    }

    //allows you to send the updated model data with the form data  If false, only the form data is sent
    if (
      undefined !== this._formConfig.sendOnlyFormFields &&
      !this._formConfig.sendOnlyFormFields
    ) {
      formData = { ...this.entity, ...formData };
    }

    formData = this.dataFormModelTransformerBeforeSend(action, formData);

    const onBeforeSubmitSuccess = await this.beforeSubmitFormMutation(
      action,
      formData,
    ).catch((reason: unknown) => reason);

    if (!onBeforeSubmitSuccess) {
      return;
    }

    this._isProcessingForm.set(true);
    this._isFormSubmitted.set(true);

    const mutationResponse = await this.applyMutation(action, formData);

    this._isProcessingForm.set(false);

    const submitError = await this.processSubmitError(mutationResponse);
    if (submitError) {
      return;
    }

    this.onMutationSuccess(mutationResponse);

    await this.afterSubmitFormMutation(mutationResponse);

    if (
      MUTATION_ACTION.create === action &&
      this._formConfig.cleanFormOnCreate
    ) {
      this.entity = this.createNewEntityInstance();
      this.buildReactiveForm();
    }
  }

  async applyMutation(
    action: MutationAction,
    formData: PartialExtended<TModel>,
  ) {
    const customMutations = this.activeAllowedDataModel?.customMutations;
    const allowedDataModelCustomMutation =
      undefined !== customMutations
        ? (customMutations[action] ?? undefined)
        : undefined;

    return undefined === allowedDataModelCustomMutation
      ? await this.applyGenericMutation(action, formData)
      : await this.applyCustomMutation(
          allowedDataModelCustomMutation,
          formData,
        );
  }

  resetForm(state?: PartialExtended<TModel>) {
    this.form.reset(state as any);
  }

  async processSubmitError(result: ApolloMutationResult) {
    const formValidationsOptions = this._formConfig.formValidations;

    if (!result.success) {
      const error = apolloGetProcessedErrorFromResponse(result);

      if (error) {
        console.error('onFormSave', error);
        this.onMutationError(error);
        return true;
      }

      if (
        undefined === formValidationsOptions?.applyFieldErrorFromServer ||
        formValidationsOptions?.applyFieldErrorFromServer
      ) {
        const propagateErrorAs: string =
          undefined !== formValidationsOptions?.propagateErrorAs
            ? formValidationsOptions?.propagateErrorAs
            : FORM_DEFAULT_API_ERROR_KEY;

        formHelperAddFormGroupErrorsFromMutationResponse(
          this.form,
          result,
          propagateErrorAs,
        );
      }

      return true;
    }

    return false;
  }

  /**
   * Apply the conventional mutation, use the custom mutations set for the active model
   * @param mutation
   * @param data
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  applyCustomMutation(mutation: ApolloMutation, data: object) {
    return this.crudServiceInstance.mutate({
      mutation,
      variables: data,
      processResult: this._formConfig.processSuccessMutationResult,
      enabledSecurity: true,
    });
  }

  /**
   * Apply a generic mutation with the data from the form configuration
   * @param action
   * @param data
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  applyGenericMutation(action: MutationAction, data: object) {
    const mutationSubQueryFields =
      this.activeAllowedDataModel?.mutationSubQueryFields;
    return this.crudServiceInstance.mutate({
      action,
      instance: this.activeAllowedDataModel?.class,
      variables: data,
      processResult: this._formConfig.processSuccessMutationResult,
      enabledSecurity: true,
      mutationSubQueryFields,
    });
  }

  /**
   * Extension point before sending the form mutation, allows establishing a logic before sending the main form
   * and at the same time can prevent the execution of the final mutation.
   * For this we must return "true" to continue or "false" to stop the flow
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  async beforeSubmitFormMutation(
    action: MutationAction,
    formData: PartialExtended<TModel>,
  ): Promise<boolean> {
    return true;
  }

  /**
   * Extension point after sending the form mutation, allows establishing a logic after sending the main form,
   * This point is always executed whether the sending of the form had an error or was correct.
   * To execute code when the form is correct or not, use the methods "onMutationSuccess" "onMutationError"
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  async afterSubmitFormMutation(result: ApolloMutationResult): Promise<void> {
    return;
  }

  /**
   * Method that is called when the form has been submitted but form invalid property is true
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  onFormInvalid(): void {}

  /**
   * This method is called when the mutation returns correctly from the server
   *
   * @param {ApolloMutationResult} result
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  onMutationSuccess(result: ApolloMutationResult): void {
    void this.sweetAlertService.success();
  }

  /**
   * This method is called when the mutation fails and returns with errors from the server
   *
   * @param error
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  onMutationError(error: { category: string; message?: string }): void {
    void this.sweetAlertService.error({
      title: error.category,
      text: error.message,
    });
  }

  /**
   * This method is called just before sending the mutation to the server
   * is used to modify the variables before being sent
   *
   * @param {string} action
   * @param {any} formData
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  dataFormModelTransformerBeforeSend(
    action: MutationAction,
    formData: PartialExtended<TModel>,
  ): PartialExtended<TModel> {
    return formData;
  }

  /**
   * Method that is called in the buildReactiveForm before create a form
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  beforeBuildReactiveForm() {}

  /**
   * Method that is called in the buildReactiveForm after form is created successfully
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  afterBuildReactiveForm() {}

  /**
   * In this method we can make requests to the server for the necessary data in the form, just before build the
   * reactive form
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  async prefetchDataAfterBuildReactiveForm() {}

  /**
   * Method that obtains a server instance for the class set in the active AllowedDataModel
   */
  async findOneInstanceByID() {
    const query = this.activeAllowedDataModel?.query;
    if (undefined === this.genericEntityId || undefined === query) return;

    const queryResponse =
      await this.crudServiceInstance.fetchElementById<TQueryModel>({
        query,
        id: this.genericEntityId,
        convertTo: 'promise',
      });

    if (!queryResponse.success) {
      this.onFindOneInstanceByIDError(queryResponse.errors);
      return;
    }

    this.entityQueryData = queryResponse.data;

    if (
      this.entityInstanceToString() !==
      DecoratorReflectMetadata.getClassName(this.activeAllowedDataModel?.class)
    ) {
      this.entity = this.createNewEntityInstance(
        this.activeAllowedDataModel?.class,
      );
    }

    this.onFindOneInstanceByIDSuccess(this.entityQueryData);
  }

  /**
   * Method to extend what to do when an instance was obtained successful from the server
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  onFindOneInstanceByIDSuccess(instance: PartialExtended<TQueryModel>) {
    this.entity.updateFromQuery(instance);
  }

  /**
   * Method to extend what to do when fail to get an instance from the server
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  onFindOneInstanceByIDError(reason: GraphQLError[]) {
    console.error('fetchEntityInstance', reason);
    void this.sweetAlertService.error({
      text: 'Error al cargar información. Por favor inténtelo mas tarde.',
    });
  }

  /**
   * Allows you to determine what action the form is performing (create, update)
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  getFormAction(): MutationAction {
    return undefined === this.genericEntityId
      ? MUTATION_ACTION.create
      : MUTATION_ACTION.update;
  }

  /**
   * This method allows us to validate in a generic and global way any desired value in our form.
   * If the method returns true then the form does not continue its flow in case of false it performs the established actions.
   *
   * Is called just after knowing that form is valid according to the rules applied to each field individually,
   * which makes it our first manual checkpoint.
   *
   * @param {FormGroup} form
   * @param {MutationAction} action
   *
   * @return boolean (default -> false)
   */
  checkFormHasGlobalCustomError(
    form: FormGroup,
    action: MutationAction,
  ): boolean {
    return false;
  }

  /**
   * Find and return the allowedDataModel by default, or return undefined if not found
   */
  getDefaultAllowedDataModel(): AllowedDataModel | undefined {
    const allInstances = this.configureForm().allowedDataModels;
    for (const instance of allInstances) {
      if (instance.default) {
        return instance;
      }
    }

    return allInstances[0] ?? undefined;
  }

  /**
   * Method for creating the form
   * @private
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  private buildReactiveForm() {
    try {
      this.beforeBuildReactiveForm();
      this.form = this.buildForm();
      this.afterBuildReactiveForm();

      this._isFormSubmitted.set(false);
    } catch (e) {
      console.error('form-component buildReactiveForm()', e);
    }
  }

  /**
   *Allows you to create a new instance for the class to use in the form
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  private createNewEntityInstance(
    providedClassName?: new () => TModel,
  ): TModel {
    const className =
      providedClassName ??
      (this.activeAllowedDataModel?.class as (new () => TModel) | undefined);

    if (undefined === className) {
      throw new Error('The class property is required within AllowedDataModel');
    }

    return new className();
  }

  //<editor-fold desc="Form helper methods ">
  isControlInvalid(controlName: string) {
    return formHelperIsControlInvalid(this.form.get(controlName));
  }

  isControlValid(controlName: string) {
    return formHelperIsControlValid(this.form.get(controlName));
  }

  controlHasError(validation: string, controlName: string) {
    return formHelperControlHasError(this.form.get(controlName), validation);
  }

  getControlError(validation: string, controlName: string) {
    const errors = this.getControlErrors(controlName);
    return errors && 'validation' in errors ? errors[validation] : null;
  }

  getControlErrors(controlName: string) {
    const control = this.form.get(controlName);

    if (null === control) {
      throw new Error('getControlErrors::control cannot be null.');
    }

    return control.errors;
  }

  controlHasErrorFromApi(controlName: string) {
    const errorKey =
      this._formConfig.formValidations?.propagateErrorAs ??
      FORM_DEFAULT_API_ERROR_KEY;
    return formHelperControlHasError(this.form.get(controlName), errorKey);
  }

  getControlErrorFromApi(controlName: string) {
    const errors = this.getControlErrors(controlName);
    const errorKey =
      this._formConfig.formValidations?.propagateErrorAs ??
      FORM_DEFAULT_API_ERROR_KEY;

    return errors && Object.prototype.hasOwnProperty.call(errors, errorKey)
      ? errors[errorKey]
      : '';
  }

  //</editor-fold>
}
