import { ICrudLocale } from '../../../i18n/interface';

export const GROUP_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  GROUP: {
    FIELD: {
      NAME: 'Nombre',
      ACTIVE: 'Activo',
      ROLES: 'Roles',
    },
    LABEL: {
      SINGULAR: 'Grupo',
      PLURAL: 'Grupos',
    },
    LIST: {
      TITLE: 'Listado de grupos',
    },
  },
};
