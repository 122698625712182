import * as i0 from '@angular/core';
import { signal, Directive } from '@angular/core';
class BrnFormFieldControl {
  constructor() {
    /** Gets the AbstractControlDirective for this control. */
    this.ngControl = null;
    /** Whether the control is in an error state. */
    this.errorState = signal(false);
  }
  static {
    this.ɵfac = function BrnFormFieldControl_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnFormFieldControl)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnFormFieldControl
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnFormFieldControl, [{
    type: Directive
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BrnFormFieldControl };
