import { fromUnixTime, getUnixTime } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';
import {
  APP_DEFAULT_DATE_FORMAT_PIPE,
  APP_DEFAULT_DATE_TIMEZONE_PIPE,
} from '../../../../_config';
import { helperDefaultValue } from './helper-tools';

export const DateHelperConfig = {
  locale: {} as any,
};

/**
 * Establishes the ISO format for dates handling
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const DATE_HELPER_FORMAT_ISO_STRING = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

/**
 * Convert any valid date value in to a date object. In case of nullish values the current date and time are taken
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function dateHelperToDate(
  value?: string | number | null | undefined,
  timezone: 'UTC' | string | undefined = APP_DEFAULT_DATE_TIMEZONE_PIPE,
): Date {
  const date = new Date(value ?? Date.now());
  return undefined !== timezone ? toZonedTime(date, timezone) : date;
}

/**
 * Apply the desired format to an date object
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function dateHelperToFormat(
  date: string | number | Date,
  pattern: string = APP_DEFAULT_DATE_FORMAT_PIPE,
  timezone: 'UTC' | string | undefined = APP_DEFAULT_DATE_TIMEZONE_PIPE,
): string {
  let _date = date instanceof Date ? date : dateHelperToDate(date);

  if (timezone) {
    _date = toZonedTime(_date, timezone);
  }

  return format(_date, pattern, {
    timeZone: timezone,
    locale: helperDefaultValue(DateHelperConfig.locale, undefined),
  });
}

/**
 * Convert any valid date value to ISO format
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function dateHelperToISOString(
  value: Date | string | number | null | undefined,
  timezone?: 'UTC' | string | undefined,
): string | null {
  const date = value instanceof Date ? value : dateHelperToDate(value);
  return dateHelperToFormat(date, DATE_HELPER_FORMAT_ISO_STRING, timezone);
}

/**
 * Converts any date valid value in to unix time
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function dateHelperToEpoch(
  date: string | Date,
  timezone?: 'UTC' | string | undefined,
): number {
  if (typeof date === 'string') {
    date = dateHelperToDate(date, timezone);
  }

  date = timezone ? toZonedTime(date, timezone) : date;

  return getUnixTime(date);
}

/**
 * Convert an unix time value into an date object
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function dateHelperEpochToDate(
  epoch: number,
  timezone: 'UTC' | string | undefined = APP_DEFAULT_DATE_TIMEZONE_PIPE,
): Date {
  const date = fromUnixTime(epoch);
  return undefined !== timezone ? toZonedTime(date, timezone) : date;
}

/**
 * Apply format directly to an unix time
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function dateHelperEpochToFormat(
  epoch: number,
  pattern?: string,
  timezone?: 'UTC' | string | undefined,
): string {
  return dateHelperToFormat(fromUnixTime(epoch), pattern, timezone);
}

/**
 * Converts a unix value time to ISO format
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function dateHelperEpochToISOString(
  epoch: number,
  timezone?: 'UTC' | string | undefined,
): string {
  return dateHelperEpochToFormat(
    epoch,
    DATE_HELPER_FORMAT_ISO_STRING,
    timezone,
  );
}
