import { inject, Injectable, signal } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Injectable({
  providedIn: 'root',
})
export class CustomBreadcrumbService {
  private readonly breadcrumbService = inject(BreadcrumbService);
  private readonly _enabled = signal<boolean>(true);

  get isActive() {
    return this._enabled();
  }

  show() {
    this._enabled.set(true);
  }
  
  hide() {
    this._enabled.set(false);
  }

  get items$() {
    return this.breadcrumbService.breadcrumbs$;
  }
}
