import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { lucideLoaderCircle } from '@ng-icons/lucide';
import {
  HlmIconComponent,
  IconSize,
  provideIcons,
} from '@spartan-ng/ui-icon-helm';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@Component({
  selector: 'ui-spinner',
  standalone: true,
  imports: [CommonModule, NgxUiLoaderModule, HlmIconComponent],
  providers: [provideIcons({ lucideLoaderCircle })],
  template: `
    <ng-template #foregroundSpinner>
      <hlm-icon
        name="lucideLoaderCircle"
        class="animate animate-spin text-primary"
        [size]="size()" />
    </ng-template>

    <ngx-ui-loader
      [loaderId]="loaderId()"
      [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSpinnerComponent {
  loaderId = input.required<string>();
  size = input<IconSize>('xl');
}
