import * as i0 from '@angular/core';
import { signal, computed, Injectable, inject, ElementRef, Directive, Input, DestroyRef, effect, Component, ChangeDetectionStrategy, ViewChild, ContentChild, ContentChildren, input, contentChildren, EventEmitter, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/cdk/listbox';
import { CdkOption, CdkListbox, CdkListboxModule } from '@angular/cdk/listbox';
import { NgTemplateOutlet } from '@angular/common';
import { toObservable, takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Subject, skip, fromEvent, interval, takeUntil, map, switchMap, of, delay, combineLatest } from 'rxjs';
import * as i1$1 from '@spartan-ng/ui-label-brain';
import { BrnLabelDirective } from '@spartan-ng/ui-label-brain';
import { NgControl, NgForm, FormGroupDirective } from '@angular/forms';
import * as i1$2 from '@angular/cdk/overlay';
import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { provideExposedSideProviderExisting, provideExposesStateProviderExisting } from '@spartan-ng/ui-core';
import { BrnFormFieldControl } from '@spartan-ng/ui-formfield-brain';
import { ErrorStateMatcher, ErrorStateTracker } from '@spartan-ng/ui-forms-brain';
const _c0 = ["viewport"];
const _c1 = ["*", [["hlm-select-scroll-up"]], [["brnSelectScrollUp"]], [["brnSelectScrollDown"]], [["hlm-select-scroll-down"]]];
const _c2 = ["*", "hlm-select-scroll-up", "brnSelectScrollUp", "brnSelectScrollDown", "hlm-select-scroll-down"];
function BrnSelectContentComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1);
    i0.ɵɵprojection(1, 2);
  }
}
function BrnSelectContentComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function BrnSelectContentComponent_ng_template_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 3);
    i0.ɵɵprojection(1, 4);
  }
}
function BrnSelectContentComponent_ng_container_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const _c3 = [[["hlm-select-trigger"], ["", "brnSelectTrigger", ""]], [["label", "hlmLabel", ""], ["label", "brnLabel", ""]], "*"];
const _c4 = ["hlm-select-trigger,[brnSelectTrigger]", "label[hlmLabel],label[brnLabel]", "*"];
function BrnSelectComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵattribute("id", ctx_r1.backupLabelId());
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1._placeholder());
  }
}
function BrnSelectComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1);
  }
}
function BrnSelectComponent_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 2);
  }
}
class BrnSelectService {
  get selectTrigger() {
    return this._selectTrigger;
  }
  constructor() {
    this.state = signal({
      id: '',
      labelId: '',
      panelId: '',
      placeholder: '',
      isExpanded: false,
      multiple: false,
      disabled: false,
      dir: 'ltr',
      selectedOptions: [],
      possibleOptions: [],
      value: '',
      triggerWidth: 0
    });
    this.id = computed(() => this.state().id);
    this.labelId = computed(() => this.state().labelId);
    this.panelId = computed(() => this.state().panelId);
    this.placeholder = computed(() => this.state().placeholder);
    this.disabled = computed(() => this.state().disabled);
    this.isExpanded = computed(() => this.state().isExpanded);
    this.multiple = computed(() => this.state().multiple);
    this.dir = computed(() => this.state().dir);
    this.selectedOptions = computed(() => this.state().selectedOptions);
    this.value = computed(() => this.state().value);
    this.triggerWidth = computed(() => this.state().triggerWidth);
    this.possibleOptions = computed(() => this.state().possibleOptions);
    this.multiple$ = toObservable(this.multiple);
    this.listBoxValueChangeEvent$ = new Subject();
    this.listBoxValueChangeEvent$.pipe(takeUntilDestroyed()).subscribe(listBoxChange => {
      const updatedSelections = this.multiple() ? this.getUpdatedOptions(listBoxChange) : [listBoxChange.option];
      const value = this.multiple() ? listBoxChange.value : listBoxChange.value[0];
      this.state.update(state => ({
        ...state,
        selectedOptions: [...updatedSelections],
        value: value
      }));
    });
    // We need to skip the first value because we don't want to deselect all options when the component is initialized with a preselected value e.g. by the form control
    this.multiple$.pipe(skip(1), takeUntilDestroyed()).subscribe(multiple => {
      if (!multiple && this.value().length > 1) {
        this.deselectAllOptions();
      }
    });
  }
  setTriggerWidth(triggerWidth) {
    this.state.update(s => ({
      ...s,
      triggerWidth
    }));
  }
  getUpdatedOptions(latestListboxChange) {
    const isNewSelection = latestListboxChange.value.findIndex(value => value === latestListboxChange.option?.value);
    if (isNewSelection === -1) {
      const removedOptionIndex = this.selectedOptions().findIndex(option => latestListboxChange.option === option);
      const options = this.selectedOptions();
      options.splice(removedOptionIndex, 1);
      return options;
    }
    return [...this.selectedOptions(), latestListboxChange.option];
  }
  deselectAllOptions() {
    this.state.update(state => ({
      ...state,
      selectedOptions: [],
      value: []
    }));
  }
  // Needed due to https://github.com/angular/angular/issues/20810
  _setSelectTrigger(trigger) {
    this._selectTrigger = trigger;
  }
  setInitialSelectedOptions(value) {
    this.selectOptionByValue(value);
    this.state.update(state => ({
      ...state,
      value: value,
      initialSelectedOptions: this.selectedOptions(),
      selectedOptions: this.selectedOptions()
    }));
  }
  selectOptionByValue(value) {
    const options = this.possibleOptions();
    if (value === null || value === undefined) {
      const nullOrUndefinedOption = options.find(o => o && o.value === value);
      if (!nullOrUndefinedOption) {
        this.state.update(state => ({
          ...state,
          selectedOptions: [],
          value: this.multiple() ? [] : ''
        }));
        return;
      }
    }
    if (this.multiple()) {
      const selectedOptions = options.filter(option => {
        if (Array.isArray(value)) {
          return value.includes(option?.value);
        }
        return value === option?.value;
      });
      this.state.update(state => ({
        ...state,
        selectedOptions,
        value: value
      }));
    } else {
      const selectedOption = options.find(option => option?.value === value);
      if (!selectedOption) {
        return;
      }
      this.state.update(state => ({
        ...state,
        selectedOptions: [selectedOption],
        value: selectedOption.value
      }));
    }
  }
  static {
    this.ɵfac = function BrnSelectService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BrnSelectService,
      factory: BrnSelectService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectService, [{
    type: Injectable
  }], () => [], null);
})();
class BrnSelectOptionDirective {
  constructor() {
    this._cdkSelectOption = inject(CdkOption, {
      host: true
    });
    this._selectService = inject(BrnSelectService);
    this._selected = signal(false);
    this._focused = signal(false);
    this.elementRef = inject(ElementRef);
    this.selected = computed(() => this._selected());
    this.focused = computed(() => this._focused());
    this.checkedState = computed(() => this._selected() ? 'checked' : 'unchecked');
    this.dir = computed(() => this._selectService.dir());
    this._disabled = false;
    toObservable(this._selectService.value).subscribe(selectedValues => {
      if (Array.isArray(selectedValues)) {
        const itemFound = selectedValues.find(val => val === this._cdkSelectOption.value);
        this._selected.set(!!itemFound);
      } else {
        this._selected.set(this._cdkSelectOption.value === selectedValues);
      }
    });
  }
  set value(value) {
    this._cdkSelectOption.value = value;
  }
  set disabled(value) {
    this._disabled = value;
  }
  get disabled() {
    return this._disabled;
  }
  hover() {
    this.focus();
  }
  focus() {
    this._cdkSelectOption.focus();
    this._focused.set(true);
  }
  blur() {
    this._focused.set(false);
  }
  static {
    this.ɵfac = function BrnSelectOptionDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectOptionDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSelectOptionDirective,
      selectors: [["", "brnOption", ""]],
      hostVars: 1,
      hostBindings: function BrnSelectOptionDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function BrnSelectOptionDirective_mouseenter_HostBindingHandler() {
            return ctx.hover();
          })("blur", function BrnSelectOptionDirective_blur_HostBindingHandler() {
            return ctx.blur();
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("dir", ctx._selectService.dir());
        }
      },
      inputs: {
        value: "value",
        disabled: "disabled"
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.CdkOption])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectOptionDirective, [{
    type: Directive,
    args: [{
      selector: '[brnOption]',
      standalone: true,
      hostDirectives: [CdkOption],
      host: {
        '(mouseenter)': 'hover()',
        '(blur)': 'blur()',
        '[attr.dir]': '_selectService.dir()'
      }
    }]
  }], () => [], {
    value: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }]
  });
})();
class BrnSelectScrollDownDirective {
  constructor() {
    this._el = inject(ElementRef);
    this._selectContent = inject(BrnSelectContentComponent);
    this.endReached = new Subject();
    this._destroyRef = inject(DestroyRef);
  }
  startEmittingEvents() {
    const mouseLeave$ = fromEvent(this._el.nativeElement, 'mouseleave');
    interval(100).pipe(takeUntil(mouseLeave$), takeUntil(this.endReached), takeUntilDestroyed(this._destroyRef)).subscribe(() => this._selectContent.moveFocusDown());
  }
  stopEmittingEvents() {
    this.endReached.next(true);
  }
  static {
    this.ɵfac = function BrnSelectScrollDownDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectScrollDownDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSelectScrollDownDirective,
      selectors: [["", "brnSelectScrollDown", ""], ["brn-select-scroll-down"], ["hlm-select-scroll-down", 5, "noHlm"]],
      hostAttrs: ["aria-hidden", "true"],
      hostBindings: function BrnSelectScrollDownDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function BrnSelectScrollDownDirective_mouseenter_HostBindingHandler() {
            return ctx.startEmittingEvents();
          });
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectScrollDownDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSelectScrollDown], brn-select-scroll-down, hlm-select-scroll-down:not(noHlm)',
      standalone: true,
      host: {
        'aria-hidden': 'true',
        '(mouseenter)': 'startEmittingEvents()'
      }
    }]
  }], null, null);
})();
class BrnSelectScrollUpDirective {
  constructor() {
    this._el = inject(ElementRef);
    this._selectContent = inject(BrnSelectContentComponent);
    this.endReached = new Subject();
    this._destroyRef = inject(DestroyRef);
  }
  startEmittingEvents() {
    const mouseLeave$ = fromEvent(this._el.nativeElement, 'mouseleave');
    interval(100).pipe(takeUntil(mouseLeave$), takeUntil(this.endReached), takeUntilDestroyed(this._destroyRef)).subscribe(() => this._selectContent.moveFocusUp());
  }
  stopEmittingEvents() {
    this.endReached.next(true);
  }
  static {
    this.ɵfac = function BrnSelectScrollUpDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectScrollUpDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSelectScrollUpDirective,
      selectors: [["", "brnSelectScrollUp", ""], ["brn-select-scroll-up"], ["hlm-select-scroll-up", 5, "noHlm"]],
      hostAttrs: ["aria-hidden", "true"],
      hostBindings: function BrnSelectScrollUpDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseenter", function BrnSelectScrollUpDirective_mouseenter_HostBindingHandler() {
            return ctx.startEmittingEvents();
          });
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectScrollUpDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSelectScrollUp], brn-select-scroll-up, hlm-select-scroll-up:not(noHlm)',
      standalone: true,
      host: {
        'aria-hidden': 'true',
        '(mouseenter)': 'startEmittingEvents()'
      }
    }]
  }], null, null);
})();
class BrnSelectContentComponent {
  constructor() {
    this._el = inject(ElementRef);
    this._cdkListbox = inject(CdkListbox, {
      host: true
    });
    this.destroyRef = inject(DestroyRef);
    this._selectService = inject(BrnSelectService);
    this.labelledBy = this._selectService.labelId;
    this.id = this._selectService.id;
    this.canScrollUp = signal(false);
    this.canScrollDown = signal(false);
    this.initialSelectedOptions$ = toObservable(this._selectService.selectedOptions);
    this._cdkListbox.valueChange.asObservable().pipe(takeUntilDestroyed()).subscribe(val => this._selectService.listBoxValueChangeEvent$.next(val));
    effect(() => {
      this._cdkListbox.multiple = this._selectService.multiple();
      this._selectService.isExpanded() && setTimeout(() => this.updateArrowDisplay());
    });
  }
  ngAfterViewInit() {
    this.setInitiallySelectedOptions();
  }
  setInitiallySelectedOptions() {
    this.initialSelectedOptions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedOptions => {
      // Reapplying cdkLibstbox multiple because seems this is running before effect that
      // updates cdklistbox, reapplying multiple true so we can set the multiple initial options
      if (this._selectService.multiple()) {
        this._cdkListbox.multiple = true;
      }
      for (const cdkOption of this._selectService.possibleOptions()) {
        if (selectedOptions.includes(cdkOption)) {
          cdkOption?.select();
        } else {
          cdkOption?.deselect();
        }
      }
      for (const cdkOption of selectedOptions) {
        cdkOption?.select();
      }
    });
  }
  updateArrowDisplay() {
    this.canScrollUp.set(this.viewport.nativeElement.scrollTop > 0);
    const maxScroll = this.viewport.nativeElement.scrollHeight - this.viewport.nativeElement.clientHeight;
    this.canScrollDown.set(Math.ceil(this.viewport.nativeElement.scrollTop) < maxScroll);
  }
  handleScroll() {
    this.updateArrowDisplay();
  }
  focusList() {
    this._cdkListbox.focus();
  }
  moveFocusUp() {
    this.viewport.nativeElement.scrollBy({
      top: -100,
      behavior: 'smooth'
    });
    if (this.viewport.nativeElement.scrollTop === 0) {
      this.scrollUpBtn.stopEmittingEvents();
    }
  }
  moveFocusDown() {
    this.viewport.nativeElement.scrollBy({
      top: 100,
      behavior: 'smooth'
    });
    const viewportSize = this._el.nativeElement.scrollHeight;
    const viewportScrollPosition = this.viewport.nativeElement.scrollTop;
    if (viewportSize + viewportScrollPosition + 100 > this.viewport.nativeElement.scrollHeight + 50) {
      this.scrollDownBtn.stopEmittingEvents();
    }
  }
  static {
    this.ɵfac = function BrnSelectContentComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectContentComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnSelectContentComponent,
      selectors: [["brn-select-content"], ["hlm-select-content", 5, "noHlm"]],
      contentQueries: function BrnSelectContentComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, BrnSelectScrollUpDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, BrnSelectScrollDownDirective, 5);
          i0.ɵɵcontentQuery(dirIndex, BrnSelectOptionDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.scrollUpBtn = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.scrollDownBtn = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._options = _t);
        }
      },
      viewQuery: function BrnSelectContentComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.viewport = _t.first);
        }
      },
      hostVars: 4,
      hostBindings: function BrnSelectContentComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id() + "--content");
          i0.ɵɵattribute("aria-labelledBy", ctx.labelledBy())("aria-controlledBy", ctx.id() + "--trigger")("dir", ctx._selectService.dir());
        }
      },
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1.CdkListbox]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c2,
      decls: 9,
      vars: 2,
      consts: [["scrollUp", ""], ["viewport", ""], ["scrollDown", ""], [4, "ngTemplateOutlet"], ["data-brn-select-viewport", "", 2, "flex", "1 1 0%", "position", "relative", "width", "100%", "overflow", "auto", "min-height", "36px", "padding-bottom", "2px", "margin-bottom", "-2px", 3, "scroll"]],
      template: function BrnSelectContentComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵtemplate(0, BrnSelectContentComponent_ng_template_0_Template, 2, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, BrnSelectContentComponent_ng_container_2_Template, 1, 0, "ng-container", 3);
          i0.ɵɵelementStart(3, "div", 4, 1);
          i0.ɵɵlistener("scroll", function BrnSelectContentComponent_Template_div_scroll_3_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.handleScroll());
          });
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(6, BrnSelectContentComponent_ng_template_6_Template, 2, 0, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(8, BrnSelectContentComponent_ng_container_8_Template, 1, 0, "ng-container", 3);
        }
        if (rf & 2) {
          const scrollUp_r2 = i0.ɵɵreference(1);
          const scrollDown_r3 = i0.ɵɵreference(7);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngTemplateOutlet", ctx.canScrollUp() && ctx.scrollUpBtn ? scrollUp_r2 : null);
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("ngTemplateOutlet", ctx.canScrollDown() && ctx.scrollDownBtn ? scrollDown_r3 : null);
        }
      },
      dependencies: [NgTemplateOutlet],
      styles: ["[_nghost-%COMP%]{display:flex;box-sizing:border-box;flex-direction:column;outline:none;pointer-events:auto}[data-brn-select-viewport][_ngcontent-%COMP%]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch}[data-brn-select-viewport][_ngcontent-%COMP%]::-webkit-scrollbar{display:none}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectContentComponent, [{
    type: Component,
    args: [{
      selector: 'brn-select-content, hlm-select-content:not(noHlm)',
      standalone: true,
      imports: [BrnSelectScrollUpDirective, BrnSelectScrollDownDirective, NgTemplateOutlet],
      hostDirectives: [CdkListbox],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[attr.aria-labelledBy]': 'labelledBy()',
        '[attr.aria-controlledBy]': "id() +'--trigger'",
        '[id]': "id() + '--content'",
        '[attr.dir]': '_selectService.dir()'
      },
      template: `
		<ng-template #scrollUp>
			<ng-content select="hlm-select-scroll-up" />
			<ng-content select="brnSelectScrollUp" />
		</ng-template>
		<ng-container *ngTemplateOutlet="canScrollUp() && scrollUpBtn ? scrollUp : null" />
		<div
			data-brn-select-viewport
			#viewport
			(scroll)="handleScroll()"
			style="flex: 1 1 0%;
			position: relative;
			width:100%;
			overflow:auto;
			min-height: 36px;
      padding-bottom: 2px;
      margin-bottom: -2px;"
		>
			<ng-content />
		</div>
		<ng-template #scrollDown>
			<ng-content select="brnSelectScrollDown" />
			<ng-content select="hlm-select-scroll-down" />
		</ng-template>
		<ng-container *ngTemplateOutlet="canScrollDown() && scrollDownBtn ? scrollDown : null" />
	`,
      styles: [":host{display:flex;box-sizing:border-box;flex-direction:column;outline:none;pointer-events:auto}[data-brn-select-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch}[data-brn-select-viewport]::-webkit-scrollbar{display:none}\n"]
    }]
  }], () => [], {
    viewport: [{
      type: ViewChild,
      args: ['viewport']
    }],
    scrollUpBtn: [{
      type: ContentChild,
      args: [BrnSelectScrollUpDirective, {
        static: false
      }]
    }],
    scrollDownBtn: [{
      type: ContentChild,
      args: [BrnSelectScrollDownDirective, {
        static: false
      }]
    }],
    _options: [{
      type: ContentChildren,
      args: [BrnSelectOptionDirective, {
        descendants: true
      }]
    }]
  });
})();
class BrnSelectGroupDirective {
  constructor() {
    this.labelledBy = signal('');
  }
  static {
    this.ɵfac = function BrnSelectGroupDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectGroupDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSelectGroupDirective,
      selectors: [["", "brnSelectGroup", ""]],
      hostAttrs: ["role", "group"],
      hostVars: 1,
      hostBindings: function BrnSelectGroupDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("aria-labelledby", ctx.labelledBy());
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectGroupDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSelectGroup]',
      standalone: true,
      host: {
        role: 'group',
        '[attr.aria-labelledby]': 'labelledBy()'
      }
    }]
  }], null, null);
})();
class BrnSelectLabelDirective {
  constructor() {
    this.group = inject(BrnSelectGroupDirective, {
      optional: true
    });
    this.label = inject(BrnLabelDirective, {
      host: true
    });
    this.group?.labelledBy.set(this.label.id);
  }
  static {
    this.ɵfac = function BrnSelectLabelDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectLabelDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSelectLabelDirective,
      selectors: [["", "brnSelectLabel", ""]],
      standalone: true,
      features: [i0.ɵɵHostDirectivesFeature([i1$1.BrnLabelDirective])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectLabelDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSelectLabel]',
      hostDirectives: [BrnLabelDirective],
      standalone: true
    }]
  }], () => [], null);
})();
class BrnSelectTriggerDirective {
  constructor() {
    this.el = inject(ElementRef);
    this._selectService = inject(BrnSelectService);
    this._ngControl = inject(NgControl, {
      optional: true
    });
    this.isExpanded = this._selectService.isExpanded;
    this.selectTriggerId = computed(() => `${this._selectService.id()}--trigger`);
    this.selectContentId = computed(() => `${this._selectService.id()}--content`);
    this.selectDisable = computed(() => this._selectService.disabled());
    this.selectTriggerLabelledBy = computed(() => {
      if (this._selectService.value() && this._selectService.value().length > 0) {
        return `${this._selectService.labelId()} ${this._selectService.id()}--value`;
      }
      return this._selectService.labelId();
    });
    if (!this._selectService) return;
    this._selectService._setSelectTrigger(this);
  }
  ngAfterViewInit() {
    this._selectService.setTriggerWidth(this.el.nativeElement.offsetWidth);
  }
  focus() {
    this.el.nativeElement.focus();
  }
  static {
    this.ɵfac = function BrnSelectTriggerDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectTriggerDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BrnSelectTriggerDirective,
      selectors: [["", "brnSelectTrigger", ""]],
      hostAttrs: ["role", "combobox", "aria-autocomplete", "none", "type", "button"],
      hostVars: 18,
      hostBindings: function BrnSelectTriggerDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("disabled", ctx.selectDisable());
          i0.ɵɵattribute("id", ctx.selectTriggerId())("aria-expanded", ctx.isExpanded())("aria-controls", ctx.selectContentId() + "")("aria-labelledBy", ctx.selectTriggerLabelledBy())("dir", ctx._selectService.dir());
          i0.ɵɵclassProp("ng-invalid", (ctx._ngControl == null ? null : ctx._ngControl.invalid) || null)("ng-dirty", (ctx._ngControl == null ? null : ctx._ngControl.dirty) || null)("ng-valid", (ctx._ngControl == null ? null : ctx._ngControl.valid) || null)("ng-touched", (ctx._ngControl == null ? null : ctx._ngControl.touched) || null)("ng-untouched", (ctx._ngControl == null ? null : ctx._ngControl.untouched) || null)("ng-pristine", (ctx._ngControl == null ? null : ctx._ngControl.pristine) || null);
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectTriggerDirective, [{
    type: Directive,
    args: [{
      selector: '[brnSelectTrigger]',
      standalone: true,
      host: {
        role: 'combobox',
        '[attr.id]': 'selectTriggerId()',
        '[disabled]': 'selectDisable()',
        '[attr.aria-expanded]': 'isExpanded()',
        '[attr.aria-controls]': "selectContentId() + ''",
        '[attr.aria-labelledBy]': 'selectTriggerLabelledBy()',
        'aria-autocomplete': 'none',
        '[attr.dir]': '_selectService.dir()',
        '[class.ng-invalid]': 'this._ngControl?.invalid || null',
        '[class.ng-dirty]': 'this._ngControl?.dirty || null',
        '[class.ng-valid]': 'this._ngControl?.valid || null',
        '[class.ng-touched]': 'this._ngControl?.touched || null',
        '[class.ng-untouched]': 'this._ngControl?.untouched || null',
        '[class.ng-pristine]': 'this._ngControl?.pristine || null',
        type: 'button'
      }
    }]
  }], () => [], null);
})();
class BrnSelectValueComponent {
  constructor(cdr) {
    this._selectService = inject(BrnSelectService);
    this.id = computed(() => `${this._selectService.id()}--value`);
    this.placeholder = computed(() => this._selectService.placeholder());
    this.value = null;
    this.transformFn = values => (values ?? []).join(', ');
    // In certain cases (when using a computed signal for value) where the value of the select and the options are
    // changed dynamically, the template does not update until the next frame. To work around this we can use a simple
    // string variable in the template and manually trigger change detection when we update it.
    toObservable(this._selectService.selectedOptions).pipe(takeUntilDestroyed()).subscribe(value => {
      if (value.length === 0) {
        this.value = null;
        cdr.detectChanges();
        return;
      }
      const selectedLabels = value.map(selectedOption => selectedOption?.getLabel());
      if (this._selectService.dir() === 'rtl') {
        selectedLabels.reverse();
      }
      const result = this.transformFn(selectedLabels);
      this.value = result;
      cdr.detectChanges();
    });
  }
  static {
    this.ɵfac = function BrnSelectValueComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectValueComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnSelectValueComponent,
      selectors: [["brn-select-value"], ["hlm-select-value"]],
      hostVars: 1,
      hostBindings: function BrnSelectValueComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id());
        }
      },
      inputs: {
        transformFn: "transformFn"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 1,
      vars: 1,
      template: function BrnSelectValueComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtext(0);
        }
        if (rf & 2) {
          i0.ɵɵtextInterpolate1(" ", ctx.value || ctx.placeholder(), " ");
        }
      },
      styles: ["[_nghost-%COMP%]{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;white-space:nowrap;pointer-events:none}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectValueComponent, [{
    type: Component,
    args: [{
      selector: 'brn-select-value, hlm-select-value',
      template: `
		{{ value || placeholder() }}
	`,
      host: {
        '[id]': 'id()'
      },
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;white-space:nowrap;pointer-events:none}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformFn: [{
      type: Input
    }]
  });
})();
let nextId = 0;
class BrnSelectComponent {
  set multiple(multiple) {
    this._selectService.state.update(state => ({
      ...state,
      multiple
    }));
  }
  set placeholder(placeholder) {
    this._selectService.state.update(state => ({
      ...state,
      placeholder
    }));
  }
  set disabled(disabled) {
    this._selectService.state.update(state => ({
      ...state,
      disabled
    }));
  }
  constructor() {
    this._selectService = inject(BrnSelectService);
    this.triggerWidth = this._selectService.triggerWidth;
    this._multiple = this._selectService.multiple;
    this._placeholder = this._selectService.placeholder;
    this._disabled = this._selectService.disabled;
    this.dir = input('ltr');
    this.options = contentChildren(CdkOption, {
      descendants: true
    });
    this.options$ = toObservable(this.options);
    this.optionsAndIndex$ = this.options$.pipe(map((options, index) => [options, index]));
    this.openedChange = new EventEmitter();
    this.closeDelay = input(100);
    this.isExpanded = this._selectService.isExpanded;
    this._delayedExpanded = toSignal(toObservable(this.isExpanded).pipe(switchMap(expanded => !expanded ? of(expanded).pipe(delay(this.closeDelay())) : of(expanded)), takeUntilDestroyed()), {
      initialValue: false
    });
    this.state = computed(() => this.isExpanded() ? 'open' : 'closed');
    this._positionChanges$ = new Subject();
    this.side = toSignal(this._positionChanges$.pipe(map(change =>
    // todo: better translation or adjusting hlm to take that into account
    change.connectionPair.originY === 'center' ? change.connectionPair.originX === 'start' ? 'left' : 'right' : change.connectionPair.originY)), {
      initialValue: 'bottom'
    });
    this.backupLabelId = computed(() => this._selectService.labelId());
    this.labelProvided = signal(false);
    this.ngControl = inject(NgControl, {
      optional: true,
      self: true
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onChange = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._onTouched = () => {};
    this._shouldEmitValueChange = signal(false);
    /*
     * This position config ensures that the top "start" corner of the overlay
     * is aligned with with the top "start" of the origin by default (overlapping
     * the trigger completely). If the panel cannot fit below the trigger, it
     * will fall back to a position above the trigger.
     */
    this._positions = [{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    }, {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top'
    }, {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom'
    }, {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom'
    }];
    this.defaultErrorStateMatcher = inject(ErrorStateMatcher);
    this.parentForm = inject(NgForm, {
      optional: true
    });
    this.parentFormGroup = inject(FormGroupDirective, {
      optional: true
    });
    this.errorState = computed(() => this.errorStateTracker.errorState());
    this.writeValue$ = new Subject();
    this.handleOptionChanges();
    this.handleInitialOptionSelect();
    this._selectService.state.update(state => ({
      ...state,
      id: `brn-select-${nextId++}`
    }));
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
    // Watch for Listbox Selection Changes to trigger Collapse and Value Change
    this._selectService.listBoxValueChangeEvent$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (!this._multiple()) {
        this.close();
      }
      // we set shouldEmitValueChange to true because we want to propagate the value change
      // as a result of user interaction
      this._shouldEmitValueChange.set(true);
    });
    /**
     * Listening to value changes in order to trigger forms api on change
     * ShouldEmitValueChange simply ensures we only propagate value change when a user makes a selection
     * we don't propagate changes made from outside the component (ex. patch value or initial value from form control)
     */
    toObservable(this._selectService.value).subscribe(value => {
      if (this._shouldEmitValueChange()) {
        this._onChange(value ?? null);
      }
      this._shouldEmitValueChange.set(true);
    });
    toObservable(this.dir).subscribe(dir => this._selectService.state.update(state => ({
      ...state,
      dir
    })));
    this.errorStateTracker = new ErrorStateTracker(this.defaultErrorStateMatcher, this.ngControl, this.parentFormGroup, this.parentForm);
  }
  ngAfterContentInit() {
    // Check if Label Directive Provided and pass to service
    if (this.selectLabel) {
      this.labelProvided.set(true);
      this._selectService.state.update(state => ({
        ...state,
        labelId: this.selectLabel.id,
        dir: this.dir()
      }));
    } else if (this._placeholder()) {
      this._selectService.state.update(state => ({
        ...state,
        labelId: `${state.id}--label`,
        dir: this.dir()
      }));
    }
  }
  ngDoCheck() {
    this.errorStateTracker.updateErrorState();
  }
  toggle() {
    if (this.isExpanded()) {
      this.close();
    } else {
      this.open();
    }
  }
  open() {
    if (!this._canOpen()) return;
    this._selectService.state.update(state => ({
      ...state,
      isExpanded: true
    }));
    this.openedChange.next(true);
    this._moveFocusToCDKList();
  }
  close() {
    if (!this.isExpanded()) return;
    if (this._selectService.selectTrigger) {
      this._selectService.selectTrigger.focus();
    }
    this.openedChange.next(false);
    this._selectService.state.update(state => ({
      ...state,
      isExpanded: false
    }));
    this._onTouched();
  }
  _canOpen() {
    return !this.isExpanded() && !this._disabled() && this.options()?.length > 0;
  }
  _moveFocusToCDKList() {
    setTimeout(() => this.selectContent.focusList());
  }
  writeValue(value) {
    this.writeValue$.next(value);
  }
  registerOnChange(fn) {
    this._onChange = fn;
  }
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  /**
   * Once writeValue is called and options are available we can handle setting the initial options
   * @private
   */
  handleInitialOptionSelect() {
    // Write value cannot be handled until options are available, so we wait until both are available with a combineLatest
    combineLatest([this.writeValue$, this.options$]).pipe(map((values, index) => [...values, index]), takeUntilDestroyed()).subscribe(([value, initialOptions, index]) => {
      this._shouldEmitValueChange.set(false);
      this._selectService.setInitialSelectedOptions(value);
      // the first time this observable emits a value we are simply setting the initial state
      // this change should not count as changing the state of the select, so we need to mark as pristine
      if (index === 0) {
        this.ngControl?.control?.markAsPristine();
      }
    });
  }
  /**
   * When options change, our current selected options may become invalid
   * Here we will automatically update our current selected options so that they are always inline with the possibleOptions
   * @private
   */
  handleOptionChanges() {
    this.optionsAndIndex$.pipe(takeUntilDestroyed()).subscribe(([options, index]) => {
      if (index > 0) {
        this.handleInvalidOptions(options);
      }
      this.updatePossibleOptions(options);
    });
  }
  /**
   * Check that our "selectedOptions" are still valid when "possibleOptions" is about to be updated
   */
  handleInvalidOptions(options) {
    const selectedOptions = this._selectService.selectedOptions();
    const availableOptionSet = new Set(options);
    if (this._selectService.multiple()) {
      const filteredOptions = selectedOptions.filter(o => availableOptionSet.has(o));
      // only update if there was an actual change
      if (selectedOptions.length !== filteredOptions.length) {
        // update should result in a value change since we are deselecting a value
        this._shouldEmitValueChange.set(true);
        const value = filteredOptions.map(o => o?.value ?? '');
        this._selectService.state.update(state => ({
          ...state,
          selectedOptions: filteredOptions,
          value: value
        }));
        this._onChange(value ?? null);
      }
    } else {
      const selectedOption = selectedOptions[0] ?? null;
      if (selectedOption !== null && !availableOptionSet.has(selectedOption)) {
        this._shouldEmitValueChange.set(true);
        this._selectService.state.update(state => ({
          ...state,
          selectedOptions: [],
          value: ''
        }));
        this._onChange('');
      }
    }
  }
  /**
   * Sync the updated options with "possibleOptions" in the select service
   */
  updatePossibleOptions(options) {
    this._selectService.state.update(state => ({
      ...state,
      possibleOptions: options
    }));
  }
  static {
    this.ɵfac = function BrnSelectComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BrnSelectComponent,
      selectors: [["brn-select"], ["hlm-select"]],
      contentQueries: function BrnSelectComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.options, CdkOption, 5);
          i0.ɵɵcontentQuery(dirIndex, BrnLabelDirective, 4);
          i0.ɵɵcontentQuery(dirIndex, BrnSelectContentComponent, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.selectLabel = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.selectContent = _t.first);
        }
      },
      viewQuery: function BrnSelectComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(CdkConnectedOverlay, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._overlayDir = _t.first);
        }
      },
      inputs: {
        multiple: "multiple",
        placeholder: "placeholder",
        disabled: "disabled",
        dir: [1, "dir"],
        closeDelay: [1, "closeDelay"]
      },
      outputs: {
        openedChange: "openedChange"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([BrnSelectService, CdkListbox, provideExposedSideProviderExisting(() => BrnSelectComponent), provideExposesStateProviderExisting(() => BrnSelectComponent), {
        provide: BrnFormFieldControl,
        useExisting: BrnSelectComponent
      }]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c4,
      decls: 6,
      vars: 5,
      consts: [["trigger", "cdkOverlayOrigin"], [1, "hidden"], ["cdk-overlay-origin", "", 3, "click"], ["cdk-connected-overlay", "", "cdkConnectedOverlayLockPosition", "", "cdkConnectedOverlayHasBackdrop", "", "cdkConnectedOverlayBackdropClass", "cdk-overlay-transparent-backdrop", 3, "backdropClick", "detach", "positionChange", "cdkConnectedOverlayOrigin", "cdkConnectedOverlayOpen", "cdkConnectedOverlayPositions", "cdkConnectedOverlayWidth"]],
      template: function BrnSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c3);
          i0.ɵɵtemplate(0, BrnSelectComponent_Conditional_0_Template, 2, 2, "label", 1)(1, BrnSelectComponent_Conditional_1_Template, 1, 0);
          i0.ɵɵelementStart(2, "div", 2, 0);
          i0.ɵɵlistener("click", function BrnSelectComponent_Template_div_click_2_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.toggle());
          });
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, BrnSelectComponent_ng_template_5_Template, 1, 0, "ng-template", 3);
          i0.ɵɵlistener("backdropClick", function BrnSelectComponent_Template_ng_template_backdropClick_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close());
          })("detach", function BrnSelectComponent_Template_ng_template_detach_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close());
          })("positionChange", function BrnSelectComponent_Template_ng_template_positionChange_5_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx._positionChanges$.next($event));
          });
        }
        if (rf & 2) {
          const trigger_r3 = i0.ɵɵreference(3);
          i0.ɵɵconditional(!ctx.labelProvided() && ctx._placeholder() ? 0 : 1);
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("cdkConnectedOverlayOrigin", trigger_r3)("cdkConnectedOverlayOpen", ctx._delayedExpanded())("cdkConnectedOverlayPositions", ctx._positions)("cdkConnectedOverlayWidth", ctx.triggerWidth() > 0 ? ctx.triggerWidth() : "auto");
        }
      },
      dependencies: [OverlayModule, i1$2.CdkConnectedOverlay, i1$2.CdkOverlayOrigin, CdkListboxModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectComponent, [{
    type: Component,
    args: [{
      selector: 'brn-select, hlm-select',
      standalone: true,
      imports: [OverlayModule, BrnSelectTriggerDirective, CdkListboxModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [BrnSelectService, CdkListbox, provideExposedSideProviderExisting(() => BrnSelectComponent), provideExposesStateProviderExisting(() => BrnSelectComponent), {
        provide: BrnFormFieldControl,
        useExisting: BrnSelectComponent
      }],
      template: `
		@if (!labelProvided() && _placeholder()) {
			<label class="hidden" [attr.id]="backupLabelId()">{{ _placeholder() }}</label>
		} @else {
			<ng-content select="label[hlmLabel],label[brnLabel]" />
		}

		<div cdk-overlay-origin (click)="toggle()" #trigger="cdkOverlayOrigin">
			<ng-content select="hlm-select-trigger,[brnSelectTrigger]" />
		</div>
		<ng-template
			cdk-connected-overlay
			cdkConnectedOverlayLockPosition
			cdkConnectedOverlayHasBackdrop
			cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
			[cdkConnectedOverlayOrigin]="trigger"
			[cdkConnectedOverlayOpen]="_delayedExpanded()"
			[cdkConnectedOverlayPositions]="_positions"
			[cdkConnectedOverlayWidth]="triggerWidth() > 0 ? triggerWidth() : 'auto'"
			(backdropClick)="close()"
			(detach)="close()"
			(positionChange)="_positionChanges$.next($event)"
		>
			<ng-content />
		</ng-template>
	`
    }]
  }], () => [], {
    multiple: [{
      type: Input,
      args: [{
        alias: 'multiple'
      }]
    }],
    placeholder: [{
      type: Input,
      args: [{
        alias: 'placeholder'
      }]
    }],
    disabled: [{
      type: Input,
      args: [{
        alias: 'disabled'
      }]
    }],
    selectLabel: [{
      type: ContentChild,
      args: [BrnLabelDirective, {
        descendants: false
      }]
    }],
    selectContent: [{
      type: ContentChild,
      args: [BrnSelectContentComponent]
    }],
    _overlayDir: [{
      type: ViewChild,
      args: [CdkConnectedOverlay]
    }],
    openedChange: [{
      type: Output
    }]
  });
})();
const BrnSelectImports = [BrnSelectComponent, BrnSelectContentComponent, BrnSelectTriggerDirective, BrnSelectOptionDirective, BrnSelectValueComponent, BrnSelectScrollDownDirective, BrnSelectScrollUpDirective, BrnSelectGroupDirective, BrnSelectLabelDirective];
class BrnSelectModule {
  static {
    this.ɵfac = function BrnSelectModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BrnSelectModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BrnSelectModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [BrnSelectComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BrnSelectModule, [{
    type: NgModule,
    args: [{
      imports: [...BrnSelectImports],
      exports: [...BrnSelectImports]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BrnSelectComponent, BrnSelectContentComponent, BrnSelectGroupDirective, BrnSelectImports, BrnSelectLabelDirective, BrnSelectModule, BrnSelectOptionDirective, BrnSelectScrollDownDirective, BrnSelectScrollUpDirective, BrnSelectService, BrnSelectTriggerDirective, BrnSelectValueComponent };
