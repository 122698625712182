<ui-card
  [label]="label()"
  [description]="description()"
  [blocked]="tableIsLoading()" >
  <ng-template cardToolbar>
    @if (cardToolbarTemplateRef && prependActions()) {
      <ng-template
        [ngTemplateOutlet]="cardToolbarTemplateRef"
        [ngTemplateOutletContext]="{
          tableSearchFormGroup: tableSearchFormGroup,
          tableSearchFormControlName: tableSearchFormControlName,
          tableIsFilterActive: tableIsFilterActive()
        }"></ng-template>
    }


    @if (cardToolbarTemplateRef && !prependActions()) {
      <ng-template
        [ngTemplateOutlet]="cardToolbarTemplateRef"
        [ngTemplateOutletContext]="{
          tableSearchFormGroup: tableSearchFormGroup,
          tableSearchFormControlName: tableSearchFormControlName,
          tableIsFilterActive: tableIsFilterActive()
        }"></ng-template>
    }
  </ng-template>

  @if (cardFooterTemplateRef) {
    <ng-template cardFooter>
      <ng-template [ngTemplateOutlet]="cardFooterTemplateRef"></ng-template>
    </ng-template>
  }

  @if (enableGlobalSearch()) {
    <div class="my-1">
      <ui-input-search
        [formGroup]="tableSearchFormGroup"
        [controlName]="tableSearchFormControlName"
        [placeholder]="globalSearchPlaceholder()"
        [class]="globalSearchNgClass()" />
    </div>
  }
  <div>
    @if (showRefreshBtn()) {
      <ui-button variant="nonBg">
       <img src="assets/media/kidney/icon-refresh.svg">
      </ui-button>
    }
  </div>

  <div style="margin-top: .5rem">
    <ui-table
      [columns]="columns()"
      [data]="tableDataSource()"
      [trackBy]="trackBy()"

      [sortState]="tableState().sorting"

      [caption]="tableCaption()"
      [captionPlacement]="tableCaptionPlacement()"
      [noDataMessage]="internalDataMessage()"

      (sortChange)="applySortPatch($event)"

      [showTableIfEmpty]="showTableIfEmpty()"
      [showSpinner]="false"

      [striped]="tableStriped()"
      [rounded]="tableRounded()"
      [bordered]="tableBordered()"
      [showRowBorder]="tableShowRowBorder()"
      [rowHoverable]="tableRowHoverable()">
      @if (headersTemplateRef) {
        <ng-template genTableHeader let-data="data">
          <ng-template
            [ngTemplateOutlet]="headersTemplateRef"
            [ngTemplateOutletContext]="{
              $implicit: columns(),
              data: data
            }"></ng-template>
        </ng-template>
      }
      @if (rowActionTemplateRef) {
        <ng-template genTableRowAction let-row>
          <ng-template
            [ngTemplateOutlet]="rowActionTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: row }"></ng-template>
        </ng-template>
      }
      @if (rowTemplateRef) {
        <ng-template
          genTableRow
          let-row
          let-index="index"
          let-first="first"
          let-last="last"
          let-odd="odd"
          let-even="even"
          let-count="count">
          <ng-template
            [ngTemplateOutlet]="rowTemplateRef"
            [ngTemplateOutletContext]="{
              $implicit: row,
              index,
              first,
              last,
              odd,
              even,
              count
            }"></ng-template>
        </ng-template>
      }
      @if (emptyTableTemplateRef) {
        <ng-template genTableEmpty>
          <ng-template
            [ngTemplateOutlet]="emptyTableTemplateRef"
            [ngTemplateOutletContext]="{
              $implicit: noDataMessage()
            }"></ng-template>
        </ng-template>
      }
      @if (footerActionTemplateRef) {
        <ng-template genTableFooter let-rows>
          <ng-template
            [ngTemplateOutlet]="footerActionTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: rows }"></ng-template>
        </ng-template>
      }
    </ui-table>
  </div>

  @if (enablePagination()) {
    <ui-paginator
      [state]="tableState().paginator"
      (paginateChange)="applyPaginatorPatch($event)"
      [showPageSize]="paginatorShowPageSize()"
      [showPages]="paginatorShowPages()"
      [showPageInfo]="paginatorShowPageInfo()" />
  }
</ui-card>
