import { ICrudLocale } from '@app/i18n/interface';

export const SPECIALIST_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  SPECIALIST: {
    FIELD: {
      HEADER: 'Datos del profesional',
      NAME: 'Nombre',
      NAME_LASTNAME: 'Nombre y apellidos',
      ACTIVE: 'Activo',
      ROLES: 'Roles',
      CENTER: 'Centro',
      BIRTHDATE: 'Fecha de nacimiento',
      PHONE: 'Teléfono de contacto',
      TREATMENT: 'Tratamiento',
      EMAIL: 'correo electrónico',
      RISK: 'Riesgo',
      STATUS: 'Estado',
      ACTIONS: 'Acciones',
    },
    LABEL: {
      SINGULAR: 'Especialista',
      PLURAL: 'Profesionales',
    },
    LIST: {
      TITLE: 'Listado de Especialistas',
      BTN: ' + Añadir profesional',
    },
    DETAIL: {
      TITLE: 'Listado de Especialistas',
      ID_PATIENT: ' ID paciente',
    },
  },
};
