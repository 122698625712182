import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  booleanAttribute,
  input,
  output
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from './pagination/pagination.component';
import { IPaginatorState, PageSizes } from './types/model';

@Component({
  selector: 'ui-paginator',
  templateUrl: './ui-paginator.component.html',
  styleUrls: ['./ui-paginator.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    PaginationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    '[class]': 'class()'
  }
})
export class UiPaginatorComponent implements OnDestroy {

  class = input<string>('flex items-center justify-center sm:justify-between py-4 flex-wrap');
  state = input.required<IPaginatorState>();
  showPageSize = input(false, {transform: booleanAttribute});
  showPages = input(false, {transform: booleanAttribute});
  showPageInfo = input(true, {transform: booleanAttribute});

  paginateChange = output<IPaginatorState>()

  pageChange(num: number) {
    this.state().page = num;
    this.paginateChange.emit(this.state());
  }

  sizeChange() {
    this.state().itemsPerPage = Number(this.state().itemsPerPage);
    this.state().page = 1;
    this.paginateChange.emit(this.state());
  }

  ngOnDestroy(): void {
    this.state().itemsPerPage = PageSizes[2];
  }
}
