import { inject, Injectable } from '@angular/core';
import { OperationVariables } from '@apollo/client/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  apolloCreateGenericMutation,
  ApolloHelperService,
  ApolloMutation,
  ApolloMutationResult,
  ApolloQuery,
  MUTATION_ACTION,
  MutationAction,
  QueryResult,
} from '../../services/apollo-helper.service';
import { DecoratorReflectMetadata } from '../index';

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export interface CRUDTableService {
  fetchElementById: <T>(options: {
    query: ApolloQuery;
    id: string;
    isLoading$?: BehaviorSubject<boolean>;
    freezeResult?: boolean;
    processResult?: boolean;
  }) => T;

  // eslint-disable-next-line @typescript-eslint/method-signature-style
  mutate<TVariables = OperationVariables, TSubQuery = any>(options: {
    variables: TVariables;
    enabledSecurity?: boolean;
    isLoading$?: BehaviorSubject<boolean>;
    [key: string]: any;
  }): Promise<ApolloMutationResult<TSubQuery>>;
}

@Injectable({
  providedIn: 'root',
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export abstract class GenericCRUDService implements CRUDTableService {
  //Services DI
  protected readonly apolloHelper: ApolloHelperService =
    inject(ApolloHelperService);

  fetchElementById<T>(options: {
    query: ApolloQuery;
    id: string;
    freezeResult?: boolean;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'observable';
  }): Observable<QueryResult<T>>;
  fetchElementById<T>(options: {
    query: ApolloQuery;
    id: string;
    freezeResult?: boolean;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'promise';
  }): Promise<QueryResult<T>>;
  fetchElementById<T>(options: {
    query: ApolloQuery;
    id: string;
    freezeResult?: boolean;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'observable' | 'promise';
  }): Promise<QueryResult<T>> | Observable<QueryResult<T>> {
    if ('promise' === options.convertTo) {
      return this.apolloHelper.fetchById<T>({
        ...options,
        convertTo: 'promise',
      });
    }

    return this.apolloHelper.fetchById<T>({
      ...options,
      convertTo: 'observable',
    });
  }

  /**
   * It allows mutation to be carried out in a generic way, since we only have to pass
   * the instance that we want to mutate and the action, with this the mutation
   * is automatically generated for us.
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  mutate<
    TVariables = OperationVariables,
    TSubQuery = { clientMutationId: string },
  >(options: {
    action: MutationAction;
    instance: any;
    variables: TVariables;
    isLoading$?: BehaviorSubject<boolean>;
    enabledSecurity?: boolean;
    mutationSubQueryFields?: string | string[];
    processResult?: boolean;
  }): Promise<ApolloMutationResult<TSubQuery>>;

  /**
   * It allows mutations to be carried out in the traditional way in which we pass
   * the mutation that we want to apply
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  mutate<
    TVariables = OperationVariables,
    TSubQuery = { clientMutationId: string },
  >(options: {
    mutation: ApolloMutation;
    variables: TVariables;
    isLoading$?: BehaviorSubject<boolean>;
    enabledSecurity?: boolean;
    useInputKeyVar?: boolean;
    processResult?: boolean;
  }): Promise<ApolloMutationResult<TSubQuery>>;

  /**
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   * @param options
   */
  public mutate(options: any): Promise<ApolloMutationResult> {
    const variables = options.variables;
    const enabledSecurity = options.enabledSecurity ?? true;
    const isLoading$ = options.isLoading$;
    const processResult = options.processResult ?? true;

    // if instance options is defined, then is a generic mutation
    if ('instance' in options) {
      let instance = options.instance;
      const mutationSubQueryFields = options.mutationSubQueryFields;
      const action: MutationAction = options.action; //get mutation action

      //check if submitted action is a valid one
      if (!MUTATION_ACTION.isAllowedValue(action)) {
        const allowedActions = MUTATION_ACTION.getAllValues().join(', ');
        throw new Error(`CRUDTableService::mutate - Action (${action}) is not valid.
        Allowed actions are ${allowedActions}.`);
      }

      const INSTANCE_ERROR =
        'CRUDTableService::mutate - The instance parameter is not valid, the allowed types are string or clases.';
      if (undefined === instance) {
        throw new Error(INSTANCE_ERROR);
      }

      //check if instance is class type
      if ('string' !== typeof instance) {
        //check if property name in class has value
        if (undefined === instance.name && undefined === instance.constructor) {
          throw new Error(INSTANCE_ERROR);
        }

        const instanceType = typeof instance;

        //get original class name from metadata
        //is mandatory use @ApiResourceName annotation on any class you want to mutate generically,
        //this annotation guarantee obtain always the real class name
        //after minification process.

        switch (instanceType) {
          case 'function':
            instance = DecoratorReflectMetadata.getClassName(instance);
            break;
          case 'object':
            instance = DecoratorReflectMetadata.getInstanceName(instance);
            break;
          default:
            throw new Error(INSTANCE_ERROR);
        }

        if (undefined === instance) {
          throw new Error(
            `CRUDTableService::mutate - Invalid name value (${instance}), apparently you have not applied the @ClassName decorator to the provided class.`,
          );
        }
      }

      //validate that the value obtained is a string and that at least it is not empty
      if ('string' === typeof instance && instance.trim().length === 0) {
        throw new Error(
          'CRUDTableService::mutate - The instance name is not valid.',
        );
      }

      return this.apolloHelper.mutate({
        mutation: apolloCreateGenericMutation(instance, action, {
          queryFields: Array.isArray(mutationSubQueryFields)
            ? mutationSubQueryFields.join(', ')
            : mutationSubQueryFields,
        }),
        variables: { input: variables },
        enabledSecurity,
        isLoading$,
        processResult,
      });
    }

    //at this point is a normal mutation
    const mutation: ApolloMutation = options.mutation;
    const useInputKeyVar: boolean = options.useInputKeyVar ?? true;

    return this.apolloHelper.mutate({
      mutation,
      variables: useInputKeyVar ? { input: variables } : variables,
      enabledSecurity,
      isLoading$,
      processResult,
    });
  }
}
