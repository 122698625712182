import { Directive, Input } from '@angular/core';

interface TableFooterTemplateContext<TItem extends object> {
  $implicit: TItem[];
}

/**
 * Directive to perform content projection and data casting, to enable autocompletion and type inference in TS.
 *
 * To use it, just assign the directive in ng-template element, if you want to complete the fields, use the attr
 * [castDataTo]="myCustomType or myCustomInterface".
 *
 * The data can be obtained by doing (let-data, let-row, let-item) or any name, the value is implicit therefore there is no need to define
 * where to get it from (let-data="data").
 *
 * @example html
 *  <ng-template genTableFooter [castDataTo]="myCustomType[] or myCustomInterface[]" let-items >
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
@Directive({
  selector: '[genTableFooter]',
  standalone: true
})
export class GenericTableFooterTemplateDirective<TItem extends object = any> {
  @Input('genTableFooter') $implicit!: TItem[] | string;

  static ngTemplateContextGuard<TItem extends object = any>(
    dir: GenericTableFooterTemplateDirective<TItem>,
    ctx: unknown
  ): ctx is TableFooterTemplateContext<TItem> {
    return true;
  }
}
