import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  input,
  numberAttribute,
  TemplateRef,
} from '@angular/core';
import { lucideRefreshCw } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { HlmIconComponent, provideIcons } from '@spartan-ng/ui-icon-helm';
import { Observable } from 'rxjs';
import {
  CardFooterDirective,
  CardToolbarDirective,
  UiButtonComponent,
  UiCardComponent,
  UiFormInputSearchComponent,
  UiPaginatorComponent,
  UiTableComponent,
} from '../index';
import {
  AbstractGenericListHandler,
  GenericTableFilter,
  GenericTableService,
  ListOptions,
} from '../../crud-table';
import {
  GenericTableColumn,
  GenericTableEmptyTemplateDirective,
  GenericTableFooterTemplateDirective,
  GenericTableHeaderTemplateDirective,
  GenericTableRowActionTemplateDirective,
  GenericTableRowTemplateDirective,
} from '../ui-table';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ui-generic-table-page',
  providers: [provideIcons({ lucideRefreshCw })],
  templateUrl: './ui-generic-table-page.component.html',
  styles: [``],
  imports: [
    CommonModule,
    TranslateModule,
    UiCardComponent,
    UiPaginatorComponent,
    UiTableComponent,
    UiFormInputSearchComponent,
    GenericTableHeaderTemplateDirective,
    GenericTableRowActionTemplateDirective,
    GenericTableRowTemplateDirective,
    GenericTableFooterTemplateDirective,
    CardToolbarDirective,
    CardFooterDirective,
    GenericTableEmptyTemplateDirective,
    CardToolbarDirective,
    HlmIconComponent,
    UiButtonComponent,
  ],
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class UiGenericTablePageComponent extends AbstractGenericListHandler {
  //general page
  tableFilter$ = input<Observable<GenericTableFilter>>();

  label = input<string>('Listado');
  description = input<string>();

  showRefreshBtn = input(false, { transform: booleanAttribute });
  refreshBtnTitle = input<string>('ACTION.RELOAD.GENERIC');
  showRefreshBtnIcon = input(true, { transform: booleanAttribute });

  prependActions = input(false, { transform: booleanAttribute });

  trackBy = input<string | undefined>(undefined);

  tableCaption = input<string>();
  tableCaptionPlacement = input<'top' | 'bottom'>('top');

  //generic table options
  columns = input<GenericTableColumn[]>([]);
  tableService = input.required<GenericTableService>();

  @ContentChild(GenericTableHeaderTemplateDirective, { read: TemplateRef })
  headersTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableRowTemplateDirective, { read: TemplateRef })
  rowTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableRowActionTemplateDirective, { read: TemplateRef })
  rowActionTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableFooterTemplateDirective, { read: TemplateRef })
  footerActionTemplateRef?: TemplateRef<any>;
  @ContentChild(GenericTableEmptyTemplateDirective, { read: TemplateRef })
  emptyTableTemplateRef?: TemplateRef<any>;

  @ContentChild(CardFooterDirective, { read: TemplateRef })
  cardFooterTemplateRef?: TemplateRef<any>;
  @ContentChild(CardToolbarDirective, { read: TemplateRef })
  cardToolbarTemplateRef?: TemplateRef<any>;

  tableStriped = input(false, { transform: booleanAttribute });
  tableRounded = input(false, { transform: booleanAttribute });
  tableBordered = input(false, { transform: booleanAttribute });
  tableShowRowBorder = input(false, { transform: booleanAttribute });
  tableRowHoverable = input(true, { transform: booleanAttribute });

  //no data options
  noDataMessage = input<string>('Lo sentimos no hay información disponible.');
  noDataMessageOnFilter = input<string>('No se encontraron elementos.');
  showTableIfEmpty = input(true, { transform: booleanAttribute });

  //pagination options
  enablePagination = input(true, { transform: booleanAttribute });
  paginatorShowPageSize = input(true, { transform: booleanAttribute });
  paginatorShowPages = input(true, { transform: booleanAttribute });
  paginatorShowPageInfo = input(true, { transform: booleanAttribute });

  //global search
  enableGlobalSearch = input(true, { transform: booleanAttribute });
  globalSearchPlaceholder = input<string>('ACTION.SEARCH.GENERIC');
  globalSearchNgClass = input<string>();
  globalSearchKeyUpDelay = input(650, { transform: numberAttribute });

  internalDataMessage = computed(() => {
    return this.tableIsFilterActive()
      ? this.noDataMessageOnFilter()
      : this.noDataMessage();
  });

  configureList(): ListOptions {
    return {
      tableService: this.tableService(),
      reactiveFilters: this.tableFilter$(),
      searchFilter: {
        enabled: this.enableGlobalSearch(),
        inputDelay: this.globalSearchKeyUpDelay(),
      },
    };
  }
}
