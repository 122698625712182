import { inject, Injectable } from '@angular/core';
import {
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client/core/types';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { Apollo, gql } from 'apollo-angular';
import { DocumentNode, GraphQLError } from 'graphql';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  lastValueFrom,
  map,
  Observable,
  of,
} from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';
import {
  type ObjectExcludeFunctions,
  type ObjectValues,
  type StringUnion,
} from '../types/common';
import { helperGenerateHash } from '../utils/tools';

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const INVALID_MUTATION_ID = 'invalid_clientMutationId';
export const LOCAL_ERROR = 'local_error';

export type ApolloMutationError = {
  message: string;
  debugMessage: string;
  extensions: {
    category: string;
    debugMessage?: string;
    status?: number;
    violations?: [{ path: string; message: string }];
  };
};

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type ApolloFormError = {
  errors: ApolloMutationError[];
};

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type ApolloQuery<TResult = any, TVariables = OperationVariables> =
  | DocumentNode
  | TypedDocumentNode<TResult, TVariables>;

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type ApolloMutation =
  | DocumentNode
  | TypedDocumentNode<any, OperationVariables>;

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type ApolloMutationResult<T = any> =
  | { success: true; data: T }
  | { success: false; errors: ApolloMutationError[] };

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type QueryResult<T = any> =
  | { success: true; data: T }
  | { success: false; errors: GraphQLError[] };

/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const MUTATION_ACTION = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  getAllValues() {
    return Object.values(MUTATION_ACTION);
  },
  isAllowedValue(action: unknown): boolean {
    const exist = MUTATION_ACTION[action as keyof typeof MUTATION_ACTION];
    return exist ? true : false;
  },
} as const;

// /**
//  * @author Carlos Duardo <charlieandroid55@gmail.com>
//  */
// export namespace MUTATION_ACTION {
//   /**
//    * @author Carlos Duardo <charlieandroid55@gmail.com>
//    */
//   export function getAllValues() {
//     return Object.values(MUTATION_ACTION);
//   }

//   /**
//    * @author Carlos Duardo <charlieandroid55@gmail.com>
//    */
//   export function isAllowedValue(action: string): boolean {
//     // @ts-expect-error - TS2339: Property 'includes' does not exist on type 'string'.
//     return getAllValues().includes(action);
//   }
// }

type MutationActionValues = ObjectValues<
  ObjectExcludeFunctions<typeof MUTATION_ACTION>
>;
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export type MutationAction = StringUnion<MutationActionValues>;

export const ENTITY_FORM_VIOLATION_CODE = 422;

@Injectable({
  providedIn: 'root',
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class ApolloHelperService {
  protected apollo: Apollo = inject(Apollo);

  /**
   * Fetch one resource by id
   * @param options
   * @return Observable
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public fetchById<T = any>(
    options:
      | {
          query: ApolloQuery;
          id: string;
          isLoading$?: BehaviorSubject<boolean>;
          freezeResult?: boolean;
          convertTo?: undefined;
        }
      | {
          query: ApolloQuery;
          id: string;
          isLoading$?: BehaviorSubject<boolean>;
          freezeResult?: boolean;
          convertTo?: 'observable';
        },
  ): Observable<QueryResult<T>>;
  /**
   * Fetch one resource by id
   * @param options
   * @return Promise
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public fetchById<T = any>(options: {
    query: ApolloQuery;
    id: string;
    isLoading$?: BehaviorSubject<boolean>;
    freezeResult?: boolean;
    convertTo?: 'promise';
  }): Promise<QueryResult<T>>;
  /**
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public fetchById<T = any>(options: {
    /**
     * Graphql query
     */
    query: ApolloQuery;
    /**
     * ID for searching an exact object
     */
    id: string;
    /**
     * Alternative you can pass a boolean observable to the query, and automatically receive the information if query is loading or not
     * it's quite handy when you want to show loading state for a query
     */
    isLoading$?: BehaviorSubject<boolean>;
    freezeResult?: boolean;
    convertTo?: 'observable' | 'promise';
  }): Observable<QueryResult<T>> | Promise<QueryResult<T>> {
    const { id, convertTo } = options;

    if ('promise' === convertTo) {
      return this.fetch({
        ...(options as any),
        convertTo: 'promise',
        isCollection: false,
        variables: { id },
      });
    }

    return this.fetch({
      ...(options as any),
      convertTo: 'observable',
      isCollection: false,
      variables: { id },
    });
  }

  public fetch<T = any, TVariables = OperationVariables>(options: {
    query: ApolloQuery;
    isCollection?: boolean;
    freezeResult?: boolean;
    variables?: TVariables;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'observable';
  }): Observable<QueryResult<T>>;

  public fetch<T = any, TVariables = OperationVariables>(options: {
    query: ApolloQuery;
    isCollection?: boolean;
    freezeResult?: boolean;
    variables?: TVariables;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'promise';
  }): Promise<QueryResult<T>>;

  /**
   * Make a query to the server and return a promise or observable with the value or errors if exists
   * @param options
   * @return Promise
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public fetch<T = any, TVariables = OperationVariables>(options: {
    query: ApolloQuery;
    isCollection?: boolean;
    freezeResult?: boolean;
    variables?: TVariables;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'observable' | 'promise';
  }): Observable<QueryResult<T>> | Promise<QueryResult<T>> {
    const {
      query,
      variables,
      isLoading$,
      isCollection,
      freezeResult = true,
      convertTo,
    } = options;

    isLoading$?.next(true);

    const source$ = this.apollo
      .query<T>({
        errorPolicy: 'all',
        query,
        variables: variables as OperationVariables,
      })
      .pipe(
        take(1),
        map((result) => {
          const response = apolloGetQueryData<T>({ result, isCollection });

          if (undefined === response) {
            return undefined;
          }

          if (!response.success) {
            return {
              success: false,
              errors: response.errors,
            };
          }

          return {
            success: true,
            data: freezeResult
              ? (response.data as Readonly<T>)
              : structuredClone<T>(response.data),
          };
        }),
        catchError((errors) => of({ success: false, errors })),
        finalize(() => isLoading$?.next(false)),
      ) as Observable<QueryResult<T>>;

    return 'promise' === convertTo ? lastValueFrom(source$) : source$;
  }

  /**
   * Perform mutations and return a promise when it returns successfully from the server
   * and rejects it when there are errors, as well as checking the integrity of clientMutationId
   * if specified
   *
   * @param options.mutation
   * @param options.variables
   * @param options.enabledSecurity
   * @param options.isLoadingObserver
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  public async mutate(options: {
    mutation: ApolloMutation;
    variables: OperationVariables;
    isLoading$?: BehaviorSubject<boolean>;
    enabledSecurity?: boolean;
    processResult?: boolean;
  }): Promise<ApolloMutationResult> {
    const {
      mutation,
      variables,
      isLoading$,
      enabledSecurity = true,
      processResult = true,
    } = options || {};

    isLoading$?.next(true);

    if (enabledSecurity) {
      apolloGuaranteeMutationIdInVars(variables);
    }

    const source$ = this.apollo
      .mutate({
        errorPolicy: 'all',
        mutation,
        variables,
      })
      .pipe(
        filter((result) => !result.loading),
        take(1),
        map((result) => {
          const { data, errors } = result;
          if (undefined !== errors && errors.length > 0) {
            return {
              success: false,
              errors,
            };
          }

          //if security is activated we check that the initial clientMutationId is the same as the server responded
          if (
            enabledSecurity &&
            apolloGetMutationIdFromVariables(variables) !==
              apolloGetMutationIdFromResponse(data)
          ) {
            return {
              success: false,
              errors: [{ extensions: { category: INVALID_MUTATION_ID } }],
            };
          }

          const apolloMutationData = apolloGetMutationData(data);
          return {
            success: true,
            data:
              processResult && apolloMutationData?.success
                ? apolloMutationData.data
                : data,
          };
        }),
        catchError((err) =>
          of({
            success: false,
            errors: [
              {
                extensions: { category: LOCAL_ERROR },
                debugMessage: err.message,
              },
            ],
          }),
        ),
        finalize(() => isLoading$?.next(false)),
      );
    return lastValueFrom(source$ as Observable<ApolloMutationResult>);
  }
}

//begin helper methods with tree-shake support

/**
 * Get the clientMutationId of the variables sent to the mutation
 * @param variables
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function apolloGetMutationIdFromVariables(
  variables: any,
): string | undefined {
  if (Object.prototype.hasOwnProperty.call(variables, 'input')) {
    return variables.input.clientMutationId;
  }

  return variables.clientMutationId;
}

/***
 * Get the clientMutationId from the server response
 * @param data
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function apolloGetMutationIdFromResponse(data: any): string | undefined {
  const keyMutation = apolloGetConnectionName(data);
  if (keyMutation) {
    return data[keyMutation]?.clientMutationId;
  }
  return undefined;
}

/**
 * Processes an ApolloQueryResult and returns an object with key success = true and key data with the value of the query
 * as long as there are no errors, in case of error it returns success = false and the errors key with the query errors
 * @param options
 * @return { success: true, data: T } | { success: false, errors: any } | undefined
 */
export function apolloGetQueryData<T = any>(options: {
  result: ApolloQueryResult<any>;
  isCollection?: boolean;
}): { success: true; data: T } | { success: false; errors: any } | undefined {
  const { data, errors } = options.result;

  if (undefined !== errors) {
    return {
      success: false,
      errors,
    };
  }

  if (undefined !== data) {
    if (Object.keys(data).length > 1) {
      const processResult: Record<string, any> = {};
      for (const key in data) {
        const singleResultData = apolloProcessSingleResultData(
          data,
          undefined,
          key,
        );
        if (singleResultData?.success) {
          processResult[key] = singleResultData.data;
        }
      }
      return Object.keys(processResult).length > 0
        ? { success: true, data: processResult as T }
        : undefined;
    }

    return apolloProcessSingleResultData(data, options.isCollection);
  }

  return undefined;
}

function apolloProcessSingleResultData(
  data: Record<string, any>,
  isCollection?: boolean,
  key?: string,
): { success: true; data: any } | undefined {
  const apolloConnectionName = key ?? apolloGetConnectionName(data);

  if (apolloConnectionName) {
    if (undefined === isCollection) {
      isCollection = Object.prototype.hasOwnProperty.call(
        data[apolloConnectionName],
        'collection',
      );
    }

    return {
      success: true,
      data: isCollection
        ? data[apolloConnectionName].collection
        : data[apolloConnectionName],
    };
  }

  return undefined;
}

/**
 * Processes the response returned by the graphql mutation and infers the value of the structure to be able to return the specific data,
 * If nothing is found, it returns undefined
 * @example
 * let mutationResultData1 = {
 *    "updateGroup": {
 *      "clientMutationId": "ç#Zzkh!YmV2c",
 *      "group": {
 *        "id": "/api/groups/1",
 *        "name": "New Group"
 *      },
 *      "__typename": "updateGroupPayload"
 *    }
 * }
 *
 * let mutationResultData2 = {
 *    "updateGroup": {
 *      "clientMutationId": "ç#Zzkh!YmV2c",
 *      "__typename": "updateGroupPayload"
 *    }
 * }
 *
 * let data1 = apolloGetMutationData(mutationResultData1);
 * let data2 = apolloGetMutationData(mutationResultData2);
 *
 * @param mutationResultData
 * @return {success: boolean, data: any} | undefined
 */
export function apolloGetMutationData(
  mutationResultData: any,
): { success: boolean; data: any } | undefined {
  if (undefined === mutationResultData) {
    return undefined;
  }

  const apolloConnectionName = apolloGetConnectionName(mutationResultData);

  if (undefined !== apolloConnectionName) {
    let definitionName = apolloConnectionName;

    const index = definitionName.search(/[A-Z]/); // find the index of the first uppercase letter
    const split = definitionName.slice(index); // get the second part of the string
    const firstLetterLowercase = split.charAt(0).toLowerCase(); // get the first letter of the second part and convert it to lowercase
    definitionName = firstLetterLowercase + split.slice(1);

    const result =
      mutationResultData[apolloConnectionName][definitionName] || undefined;

    return {
      success: undefined !== result,
      data: result,
    };
  }
}

/**
 * It allows obtaining the initial key of the structure of a graphql response, in the case of queries it would be the name of the requested resource,
 * and for mutations it would be the value of the name of the operation carried out
 * @param data
 */
export function apolloGetConnectionName(data: any): string | undefined {
  return Object.keys(data)[0];
}

/**
 * Returns the error message from a ApolloFormError response, if it is of type "internal" o "graphql"
 *
 * @param {ApolloFormError} reason
 * @return string | undefined
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function apolloGetProcessedErrorFromResponse(reason: ApolloFormError):
  | {
      category: string;
      message?: string;
    }
  | undefined {
  const firstReasonElement = reason.errors[0];

  if (!firstReasonElement) {
    throw new Error('Variable reason cannot be empty');
  }

  const isInvalidMutation = firstReasonElement.message
    .toLowerCase()
    .replaceAll('"', '')
    .includes('variable $input got invalid');

  if (isInvalidMutation) {
    firstReasonElement.extensions.category = 'graphql';
  }

  const errorCategory = firstReasonElement?.extensions?.category?.toLowerCase();
  const errorStatus = firstReasonElement?.extensions?.status;

  if (ENTITY_FORM_VIOLATION_CODE === errorStatus) return undefined;

  switch (errorCategory) {
    case INVALID_MUTATION_ID.toLowerCase():
      return {
        category: 'INVALID CSRF TOKEN',
        message: 'invalid_client_mutation_id',
      };
    case LOCAL_ERROR:
    case 'internal':
      return {
        category: firstReasonElement.message,
        message: firstReasonElement.debugMessage,
      };
    case 'graphql':
      return {
        category: firstReasonElement.extensions.category + ' server error',
        message: firstReasonElement.message,
      };
    case undefined:
      return {
        category: 'unknown server error',
        message:
          firstReasonElement.extensions?.debugMessage ??
          firstReasonElement.message,
      };
    default:
      return {
        category: firstReasonElement?.extensions?.category + ' server error',
        message: firstReasonElement.message,
      };
  }
}

/**
 * Generates mutations dynamically based on API-PLATFORM standards,
 *
 * @param resource
 * @param action
 * @param options
 * @returns TypedDocumentNode
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function apolloCreateGenericMutation(
  resource: string,
  action: MutationAction | string,
  options?: {
    queryFields?: string;
    suffix?: string;
  },
): TypedDocumentNode {
  const { queryFields = undefined, suffix = 'Instance' } = options ?? {};

  const operationName = action.toString() + resource;

  let queryTemplate = '';
  if ('' !== queryFields && undefined !== queryFields) {
    queryTemplate = `${resource.charAt(0).toLowerCase() + resource.slice(1)} {
        ${queryFields}
    }
    `;
  }

  const template = `mutation ${operationName + suffix} ($input: ${operationName}Input!) {
          ${operationName}(input: $input){
          clientMutationId
          ${queryTemplate}
          }
        }`;

  return gql`
    ${template}
  `;
}

/***
 * Guarantees that in the mutation variables there is clientMutationId with value
 * otherwise create and assign a default value
 * @param variables
 * @param hashIdLength
 * @private
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
function apolloGuaranteeMutationIdInVars(
  variables: any,
  hashIdLength = 12,
): void {
  if (undefined === apolloGetMutationIdFromVariables(variables)) {
    const hash = helperGenerateHash({
      length: hashIdLength,
      hasSpecialCharacters: true,
    });
    if (Object.prototype.hasOwnProperty.call(variables, 'input')) {
      variables.input.clientMutationId = hash;
    } else {
      variables.clientMutationId = hash;
    }
  }
}

/**
 * It automates the work of processing a QueryResult of type collection (findAll) or simple (findOneBy), this helper function
 * processes the observable and returns the pure value of the query, as well checks the existence of errors and if they exist,
 * returns null, this way our flow is not broken by uncontrolled errors.
 *
 * It also allows us to throw a console error if we wish.
 *
 * It is ideal for flows where it is not required to send alerts to the user and the flow of the app should not be stopped either,
 * in case of errors the app would work as if there were no data retrieved.
 *
 * Note:
 * It is important to understand that the observable should not be infinite or, as it is better known, a Hot Observable liken event clicks or watch queries,
 * like those of the HttpClient or those of the ApolloHelperService, pass an infinite observables can cause memory leaks,
 * and you should be aware of destroying it.
 *
 * @example
 * const queryResult = apolloGetDataFromObservableQueryResult(this.apolloHelper.fetch(...queryOptions))
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function apolloGetDataFromObservableQueryResult<TValue = any>(
  observable: Observable<QueryResult<TValue>>,
  options?: {
    defaultValue?: TValue;
    onErrorCallback?: (error: any) => void;
  },
): Observable<TValue> {
  return observable.pipe(
    map((queryResponse) => {
      if (!queryResponse.success) {
        const error = queryResponse.errors[0];
        throw new Error(error?.message);
      }

      return queryResponse.data as TValue;
    }),
    catchError((error) => {
      if (undefined !== options?.onErrorCallback) {
        options.onErrorCallback(error);
      }

      return options?.defaultValue ? of(options.defaultValue) : EMPTY;
    }),
  );
}
