import { ICrudLocale } from '@app/i18n/interface';

export const CENTER_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  CENTER: {
    FIELD: {
      NAME: 'Nombre del centro',
      ADMIN_NAME: 'Nombre del administrador',
      CIF: 'CIF',
      ADDRESS: 'Dirección',
      PROVINCE: 'Província',
      ACTIVE: 'Activo',
      PROFESSIONAL: 'Profesionales',
      PATIENTS: 'Pacientes',
      STATUS: 'Estado',
      ID: 'ID paciente',
      TREATMENT: 'Tratamiento',
      RISK: 'Riesgo',
      ACTIONS: 'Acciones',
      ROLES: 'Roles',
      HEADER: 'Datos del profesional',
      BIRTHDATE: 'Fecha de nacimiento',
      PHONE: 'Teléfono de contacto',
      EMAIL: 'correo electrónico'
    },
    LABEL: {
      SINGULAR: 'Centro',
      PLURAL: 'Centros',
    },
    LIST: {
      TITLE: 'Listado de Centros',
      BTN: '+ Añadir centro'
    },
    DETAILS: {
      TABS: {
        TAB_1: 'Profesionales asociados',
        TAB_2: 'Pacientes asociados',
        TAB_3: 'Datos del centro',
        BTN: '+ Añadir centro'
      },
      BTN: 'Editar'
    },

  },
};
