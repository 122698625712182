import { ICrudLocale } from '@app/i18n/interface';

export const STATISTICS_MANAGEMENT_LOCALE_ES: ICrudLocale = {
  STATISTICS: {
    FIELD: {
      NAME: 'Nombre',
      ACTIVE: 'Activo',
      ROLES: 'Roles',
    },
    LABEL: {
      SINGULAR: 'Estadísticas',
      PLURAL: 'Estadísticas',
    },
    LIST: {
      TITLE: 'Listado de Estadísticas',
    },
  },
};
