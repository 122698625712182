import { inject, Injectable } from '@angular/core';
import { OperationVariables } from '@apollo/client/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ApolloMutation,
  ApolloMutationResult,
  ApolloQuery,
  MutationAction,
  QueryResult,
} from '../../services/apollo-helper.service';
import {
  CRUDTableService,
  GenericCRUDService,
  GenericTableService,
} from '../index';

@Injectable({
  providedIn: 'root',
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export abstract class GenericCRUDTableService<T>
  extends GenericTableService<T>
  implements CRUDTableService
{
  //Services DI
  private readonly genericCRUDService: GenericCRUDService =
    inject(GenericCRUDService);

  fetchElementById<T>(options: {
    query: ApolloQuery;
    id: string;
    freezeResult?: boolean;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'observable';
  }): Observable<QueryResult<T>>;
  fetchElementById<T>(options: {
    query: ApolloQuery;
    id: string;
    freezeResult?: boolean;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'promise';
  }): Promise<QueryResult<T>>;
  fetchElementById<T>(options: {
    query: ApolloQuery;
    id: string;
    freezeResult?: boolean;
    isLoading$?: BehaviorSubject<boolean>;
    convertTo?: 'observable' | 'promise';
  }) {
    if ('promise' === options.convertTo) {
      return this.genericCRUDService.fetchElementById<T>({
        ...options,
        convertTo: 'promise',
      });
    }

    return this.genericCRUDService.fetchElementById<T>({
      ...options,
      convertTo: 'observable',
    });
  }

  /**
   * It allows mutation to be carried out in a generic way, since we only have to pass
   * the instance that we want to mutate and the action, with this the mutation
   * is automatically generated for us.
   *
   * @param action
   * @param instance
   * @param variables
   * @param isLoading$
   * @param enabledSecurity
   * @param mutationSubQuery
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  mutate<
    TVariables = OperationVariables,
    TSubQuery = { clientMutationId: string },
  >(options: {
    action: MutationAction;
    instance: any;
    variables: TVariables;
    isLoading$?: BehaviorSubject<boolean>;
    enabledSecurity?: boolean;
    mutationSubQueryFields?: string | string[];
    processResult?: boolean;
  }): Promise<ApolloMutationResult<TSubQuery>>;

  /**
   * It allows mutations to be carried out in the traditional way in which we pass
   * the mutation that we want to apply
   *
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   */
  mutate<
    TVariables = OperationVariables,
    TSubQuery = { clientMutationId: string },
  >(options: {
    mutation: ApolloMutation;
    variables: TVariables;
    isLoading$?: BehaviorSubject<boolean>;
    enabledSecurity?: boolean;
    useInputKeyVar?: boolean;
    processResult?: boolean;
  }): Promise<ApolloMutationResult<TSubQuery>>;

  /**
   * @author Carlos Duardo <charlieandroid55@gmail.com>
   * @param options
   */
  public mutate(options: any): Promise<ApolloMutationResult> {
    return this.genericCRUDService.mutate(options);
  }
}
