<nav hlmPagination>
  <ul hlmPaginationContent>
    @if (directionLinks) {
    <li
      hlmPaginationItem
      aria-label="Previous"
      i18n-aria-label="@@ng.pagination.previous-aria"
      (click)="selectPage(page - 1); $event.preventDefault()"
      [disabled]="previousDisabled()">
      <hlm-pagination-previous [label]="undefined" />
    </li>
    } @for (pageNumber of pages; track page) {
    <li>
      @if (isEllipsis(pageNumber)) {
      <hlm-pagination-ellipsis />
      } @else {
      <a
        hlmPaginationItem
        [disabled]="disabled"
        [active]="pageNumber === page"
        (click)="selectPage(pageNumber); $event.preventDefault()">
        {{ pageNumber }}
      </a>
      }
    </li>
    } @if (directionLinks) {
    <li
      hlmPaginationItem
      i18n-aria-label="@@ng.pagination.next-aria"
      (click)="selectPage(page + 1); $event.preventDefault()"
      [disabled]="nextDisabled()">
      <hlm-pagination-next [label]="undefined" />
    </li>
    }
  </ul>
</nav>
