import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { helperDefaultValue } from '../utils/tools/helper-tools';

@Pipe({
  name: 'default',
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
/**
 * Pipe to transform any empty variable into a default value, without the need for controls in the view or in the TS.
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class DefaultPipe implements PipeTransform {
  transform(currentValue: unknown, value: any, numberGreaterThanZero: boolean = false): any {
    return helperDefaultValue(currentValue, value, numberGreaterThanZero)
  }
}
