/**
 * Formats a phone number based on a provided phone mask by replacing '#' in the mask with corresponding digits from the input value.
 * @param value The input phone number string to format
 * @param phoneMask The phone mask string with '#' as placeholders for digits
 * @returns The formatted phone number string
 */
export function helperFormatPhone(value: string, phoneMask: string): string {
  if (0 === value.trim().length) {
    return '';
  }

  const result: string[] = [];
  const splitPhoneMask = phoneMask.split('');
  const splitValue = value.split('');

  for (const mask of splitPhoneMask) {
    if (splitValue.length === 0) {
      break;
    }

    if (mask === '#') {
      result.push(splitValue.shift() ?? '');
    } else {
      result.push(mask);
    }
  }

  return result.join('');
}

/**
 * Helper function to check if a value is empty or not, considering various data types.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperIsEmpty(
  value: unknown,
  numberGreaterThanZero: boolean = false,
): boolean {
  const isStringWithData = (val: unknown) =>
    'string' === typeof val && val.trim().length > 0;
  const isArrayWithData = (val: unknown) =>
    Array.isArray(val) && val.length > 0;
  const isObjectWithData = (val: unknown) =>
    'object' === typeof val && Object.keys(val ?? {}).length > 0;
  const isNumberValid = (val: unknown) => {
    if ('number' !== typeof val || !Number.isFinite(val)) {
      return false;
    }

    if (numberGreaterThanZero) {
      return val > 0;
    }

    return true;
  };

  const isBoolean = (val: unknown) => 'boolean' === typeof val;

  return !(
    isStringWithData(value) ||
    isArrayWithData(value) ||
    isObjectWithData(value) ||
    isNumberValid(value) ||
    isBoolean(value)
  );
}

/**
 * Helper function that returns a default value if the current value is empty
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperDefaultValue(
  currentValue: unknown,
  value: unknown,
  numberGreaterThanZero: boolean = false,
): any {
  return helperIsEmpty(currentValue, numberGreaterThanZero)
    ? value
    : currentValue;
}

/**
 * Helper function that generates a random hash string with the specified length and character set options.
 *
 * @returns {string} - The randomly generated hash string.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperGenerateHash(
  /**
   * @param {number} length - The length of the hash string to be generated, by default 10.
   * @param {boolean} hasSymbols - Whether to include symbols in the character set, by default true.
   * @param {boolean} hasNumber - Whether to include numbers in the character set, by default true.
   * @param {boolean} hasLetters - Whether to include letters in the character set, by default true.
   * @param {boolean} lowercase - Whether to include lowercase letters in the character, set by default false.
   * @param {boolean} hasSpecialCharacters - Whether to include special character set, by default false.
   */
  options: {
    length?: number;
    hasSymbols?: boolean;
    hasNumber?: boolean;
    lowercase?: boolean;
    hasLetters?: boolean;
    hasSpecialCharacters?: boolean;
  } = {},
): string {
  const {
    length = 10,
    hasSymbols = true,
    hasNumber = true,
    lowercase = false,
    hasLetters = true,
    hasSpecialCharacters = false,
  } = options;

  let charset = '';
  let usedOptions = 0;
  let randomValue = '';

  const lowercaseCharset = 'abcdefghijklmnopqrstuvwxyz';
  const specialCharset = 'ñáéíóúç';
  const symbolCharset = '-!@#$%&*.';
  const numberCharset = '0123456789';

  if (hasLetters) {
    charset = lowercaseCharset;
    randomValue += lowercaseCharset.charAt(
      Math.floor(Math.random() * lowercaseCharset.length),
    );
    usedOptions++;

    if (!lowercase) {
      const uppercaseCharset = lowercaseCharset.toUpperCase();
      charset += uppercaseCharset;
      randomValue += uppercaseCharset.charAt(
        Math.floor(Math.random() * uppercaseCharset.length),
      );
      usedOptions++;
    }
  }

  if (hasSymbols) {
    charset += symbolCharset;
    randomValue += symbolCharset.charAt(
      Math.floor(Math.random() * symbolCharset.length),
    );
    usedOptions++;
  }

  if (hasSpecialCharacters) {
    charset += specialCharset;
    randomValue += specialCharset.charAt(
      Math.floor(Math.random() * specialCharset.length),
    );
    usedOptions++;
  }

  if (hasNumber) {
    charset += numberCharset;
    randomValue += numberCharset.charAt(
      Math.floor(Math.random() * numberCharset.length),
    );
    usedOptions++;
  }

  for (let i = 0, n = charset.length; i < length - usedOptions; ++i) {
    randomValue += charset.charAt(Math.floor(Math.random() * n));
  }

  const shuffleStr = (str: string) => {
    const array = str.split('');

    for (let index = array.length; index > 0; index--) {
      const randomIndex = Math.floor(Math.random() * (index + 1));

      const element1 = array.at(index);
      const element2 = array.at(randomIndex);

      if (element1 !== undefined && element2 !== undefined) {
        [array[index], array[randomIndex]] = [element2, element1];
      }
    }
    return array.join('');
  };

  return shuffleStr(randomValue);
}

/**
 * Helper function that generates a slug from a given word by replacing spaces with underscores and converting
 * camelCase or PascalCase to snake_case.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperGenerateSlug({
  word,
  upperCase,
}: {
  word: string;
  upperCase?: boolean;
}) {
  const slug = word
    .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
    .replace(/(a-z0-9)([A-Z])/, '$1_$2')
    .replace(/ /g, '_');

  return upperCase ? slug.toUpperCase() : slug.toLowerCase();
}

/**
 * Helper function that takes an endpoint, an id, and an optional prefix and returns a string
 * representing the IRI (Internationalized Resource Identifier) for the given id in the specified endpoint.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperIdToIri(
  endpoint: string,
  id: number | string,
  prefix: string = 'api',
): string {
  return `/${prefix}/${endpoint}/${id}`;
}

/**
 * Helper function to extract and return the numeric ID from a given IRI string
 *
 * return 0 if cannot convert iri to id
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperIriToId(iri: string): number {
  const parts = iri.split('/');
  const value = Number(parts.at(-1));
  return !Number.isNaN(value) ? value : 0;
}

/**
 * Helper function that extracts the endpoint from a given IRI string.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperGetIriEndpoint(iri: string): string | null {
  const parts = iri.split('/');
  return parts.at(2) ?? null;
}

/**
 * Helper function to generate a random integer
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperRandomInteger(min: number, max: number): number {
  const range = max - min;
  return Math.floor(Math.random() * (range + 1) + min);
}

/**
 * Helper function to get random elements from a collection.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperRandomElements<T>(
  collection: T[],
  count: number = 1,
): T | T[] | null {
  const length = collection.length;

  if (length === 0) {
    return null;
  }

  if (count >= length) {
    return collection;
  }

  if (1 === count) {
    const randomIndex = helperRandomInteger(0, length - 1);
    return collection[randomIndex] ?? null;
  }

  const shuffled = collection.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}

/**
 * Helper function that extracts a specific column from a collection of objects,
 * starting from an optional index and returning a maximum number of results.
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function helperExtractColumnFromCollection({
  collection,
  column,
  startIndex = null,
  maxResult = null,
}: {
  collection: any[];
  column: string;
  startIndex?: number | null;
  maxResult?: number | null;
}): any[] {
  let arrayColumValues = collection.map((item) => item[column]);

  if (startIndex) {
    arrayColumValues = arrayColumValues.splice(startIndex);
  }

  if (maxResult) {
    arrayColumValues = arrayColumValues.splice(0, maxResult);
  }

  return arrayColumValues;
}
