<div class="hidden sm:flex items-center py-3 justify-start">
  @if (showPageSize()) {
    <select
      class="w-[60px] ps-3.5 py-1.5 text-sm rounded-lg dark:text-foreground dark:bg-popover cursor-pointer"
      [(ngModel)]="state().itemsPerPage"
      (change)="sizeChange()">
      @for (ps of state().pageSizes; track ps) {
        <option>
          {{ ps }}
        </option>
      }
    </select>
  }

  @if (showPageInfo()) {
    <span class="text-sm text-wrap ms-1">
      &nbsp;{{ 'PAGINATOR.SHOWING_ROWS' | translate }}
      {{ state().startRowIndex }} {{ 'PAGINATOR.TO' | translate }}&nbsp;{{
        state().endRowIndex
      }}
      {{ 'PAGINATOR.OF' | translate }}&nbsp;{{ state().totalCount }}
    </span>
  }
</div>
<div class="flex flex-wrap py-2 mr-3">
  @if (showPages()) {
    <ng-pagination
      [collectionSize]="state().totalCount"
      [(page)]="state().page"
      [maxSize]="4"
      [rotate]="true"
      [boundaryLinks]="true"
      [pageSize]="state().itemsPerPage"
      (pageChange)="pageChange($event)" />
  }
</div>
