import { computed, effect, Injectable, signal } from '@angular/core';
import { fromEvent, Subject, takeUntil, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

export const ALL_THEMES_COLORS = [
  'default',
  'blue',
  'orange',
  'red',
  'violet',
  'slate',
  'green',
  'yellow',
] as const;
export const ALL_THEMES_MODES = ['light', 'dark', 'system'] as const;

export type ThemeColor = typeof ALL_THEMES_COLORS[number];
export type ThemeMode = typeof ALL_THEMES_MODES[number];

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly colorSchemeDestroyer$ = new Subject<void>();
  private readonly colorScheme$ = fromEvent<MediaQueryListEvent>(window.matchMedia('(prefers-color-scheme: dark)'), 'change')
    .pipe(
      filter(() => 'system' === this.mode),
      takeUntil(this.colorSchemeDestroyer$),
      tap(value => this.darkMode = value.matches)
    );

  public static readonly LS_THEME_COLOR = 'theme-color';
  public static readonly LS_THEME_DARK_MODE = 'theme-mode';

  private readonly _color = signal<ThemeColor>('default');
  private readonly _mode = signal<ThemeMode>('system');
  private readonly _darkMode = signal<boolean>(false);

  constructor() {
    effect(() => {
      const themeMode = this.mode;

      if ('system' === themeMode) {
        this.colorScheme$.subscribe();
        this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        return;
      }

      this.colorSchemeDestroyer$.next();
      this.darkMode = 'dark' === themeMode;

    }, {allowSignalWrites: true});
  }

  init() {
    // const storedThemeColor = localStorage.getItem(ThemeService.LS_THEME_COLOR);
    // const storedThemeMode = localStorage.getItem(ThemeService.LS_THEME_DARK_MODE);

    // this.color = null !== storedThemeColor ? storedThemeColor as ThemeColor : 'default';
    // this._mode.set(null !== storedThemeMode ? storedThemeMode as ThemeMode : 'light')
    this.color = 'default';
    this._mode.set('light')
  }

  set color(value: ThemeColor) {
    localStorage.setItem(ThemeService.LS_THEME_COLOR, value);
    this._color.set(value);
  }

  get color() {
    return this._color();
  }

  get darkMode(): boolean {
    return this._darkMode();
  }

  set darkMode(value: boolean) {
    this._darkMode.set(value);
  }

  get mode(): ThemeMode {
    return this._mode();
  }

  set mode(value: ThemeMode) {
    localStorage.setItem(ThemeService.LS_THEME_DARK_MODE, value);
    this._mode.set(value);
  }

  theme = computed(() => {
    return `theme-${this._color()} ${this._darkMode() ? 'dark' : ''}`;
  });

  icon = computed(() => {
    switch(this.mode){
      case 'dark':
      return 'lucideMoon';
      case 'light':
        return 'lucideSun'
      default:
      return 'lucideSunMoon'
    }
  });

}
