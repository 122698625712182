import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output
} from '@angular/core';
import { provideIcons } from '@ng-icons/core';
import { lucideLoaderCircle } from '@ng-icons/lucide';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonVariants, HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';

@Component({
  selector: 'ui-button',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HlmButtonDirective, HlmIconComponent, TranslateModule],
  providers: [provideIcons({ lucideLoaderCircle })],
  template: `
    <button
      hlmBtn
      [class]="btnClass()"
      [disabled]="isDisabled()"
      [size]="size()"
      [variant]="variant()"
      [rounded]="rounded()"
      [type]="type()"
      [style]="btnStyle()"
      (click)="onClickTrigger($event)">
      @if (loading()) {
        <span class="inline-flex items-center justify-center text-nowrap">
          {{ loadingMessage() | translate }}...
          <hlm-icon
            name="lucideLoaderCircle"
            size="sm"
            class="ms-2 animate-spin" />
        </span>
      } @else {
        <ng-content />
      }
    </button>
  `,
  styles: [
    `
      :host * {
        pointer-events: auto;
      }
    `,
  ],
})
/**
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export class UiButtonComponent {
  onClick = output<MouseEvent>({ alias: 'click' });

  rounded = input<ButtonVariants['rounded']>('default');
  variant = input<ButtonVariants['variant']>('default');
  size = input<ButtonVariants['size']>('default');
  type = input<'button' | 'reset' | 'submit'>('button');
  loadingMessage = input<string>('TEXT.PLEASE_WAIT');
  disabled = input(false, { transform: booleanAttribute });
  loading = input(false, { transform: booleanAttribute });

  btnClass = input<string>('', { alias: 'class' });
  btnStyle = input<Record<string, any> | undefined>(undefined, {
    alias: 'style',
  });

  isDisabled = computed(() => this.loading() || this.disabled());

  onClickTrigger(event: MouseEvent) {
    event.stopPropagation();
    this.onClick.emit(event);
  }
}
