import { IRole } from '@modules/auth/models/interfaces';

export const ROLE_SPECIALIST: IRole = {
  name: 'CORE.ROLE.SPECIALIST',
  value: 'ROLE_SPECIALIST',
};
export const ROLE_ADMIN: IRole = {
  name: 'CORE.ROLE.ADMIN',
  value: 'ROLE_ADMIN',
};

export const ROLE_SPECIALIST_ADMIN_CENTER: IRole = {
  name: 'CORE.ROLE.SPECIALIST_ADMIN_CENTER',
  value: 'ROLE_SPECIALIST_ADMIN_CENTER',
};

export const AVAILABLE_ROLES: IRole[] = [
  ROLE_SPECIALIST,
  ROLE_SPECIALIST_ADMIN_CENTER,
  ROLE_ADMIN,
];

/**
 * Returns an array of all roles by the specified key.
 * @param {string} key - The key to use for retrieving roles. Can be either 'value' or 'name'.
 * @returns {Array} An array of all roles by the specified key.
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export function GET_ALL_ROLES_BY_KEY(key: 'value' | 'name' = 'value') {
  return AVAILABLE_ROLES.map((role) => role[key]);
}
