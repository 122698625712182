import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  numberAttribute,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { helperExtractColumnFromCollection } from '../../utils/tools';
import { StringUnion } from '../../types';

@Component({
  selector: 'app-label-show-only',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, NgFor],
  template: `
    <ng-template #showAsString>
      {{ values }}
    </ng-template>

    <ng-container *ngIf="taggify(); else showAsString">
      <span
        class="inline-flex scale-90 items-center rounded-md px-2 py-1 text-xs font-medium"
        [ngClass]="tagClass()"
        *ngFor="let label of values">
        {{ label }}
      </span>
    </ng-container>

    <ng-container *ngIf="hasInfo && showBadge">
      <span
        class="inline-flex scale-90 items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
        [ngClass]="badgeClass()">
        +{{ totalCount - maxResult() }}
      </span>
    </ng-container>
  `,
  styles: [``],
})
export class UiTaggifyLabelComponent implements OnChanges {
  collection = input.required<any[]>();
  taggify = input(false, { transform: booleanAttribute });
  column = input<string>();
  maxResult = input(1, { transform: numberAttribute });

  badgeClass = input<string | undefined>(
    'bg-gray-50 text-gray-600 ring-gray-500/10',
  );
  tagClass = input<string | undefined>(
    'bg-primary text-primary-foreground ring-primary/10 me-0.5',
  );

  locale = input<StringUnion<'es' | 'en'>>();
  listType = input<Intl.ListFormatType>();
  listStyle = input<Intl.ListFormatStyle>();

  showBadge: boolean = false;
  totalCount: number;
  values: any;
  hasInfo: boolean = false;

  private formatter?: Intl.ListFormat;

  private getProcessCollection() {
    if (undefined === this.maxResult) {
      throw new Error(
        'UiTaggifyLabelComponent:: Sorry, the key maxResult must be specified.',
      );
    }

    if (this.maxResult() <= 0) {
      return [];
    }

    let processCollection = this.collection().slice();
    this.totalCount = processCollection.length;

    this.showBadge = this.totalCount > this.maxResult();

    if (undefined !== this.column()) {
      processCollection = helperExtractColumnFromCollection({
        collection: processCollection,
        column: this.column() ?? 'name',
        maxResult: this.maxResult(),
      });
    } else {
      processCollection = processCollection.splice(0, this.maxResult());
    }

    return processCollection;
  }

  joinKeyFromCollection() {
    return this.formatter?.format(this.getProcessCollection());
  }

  init() {
    this.formatter = new Intl.ListFormat(this.locale(), {
      type: this.listType(),
      style: this.listStyle(),
    });

    this.values = this.taggify()
      ? this.getProcessCollection()
      : this.joinKeyFromCollection();
    this.hasInfo = this.values.length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }
}
