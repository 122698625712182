import {booleanAttribute, computed, Directive, input} from '@angular/core';
import {RouterLink} from '@angular/router';
import {buttonVariants, ButtonVariants} from '@spartan-ng/ui-button-helm';
import {hlm} from '@spartan-ng/ui-core';
import {cva, VariantProps} from 'class-variance-authority';

export const paginationLinkVariants = cva('', {
  variants: {},
  defaultVariants: {},
});
export type PaginationLinkVariants = VariantProps<typeof paginationLinkVariants>;

@Directive({
  selector: '[hlmPaginationLink]',
  standalone: true,
  hostDirectives: [
    {
      directive: RouterLink,
      inputs: [
        'target',
        'queryParams',
        'fragment',
        'queryParamsHandling',
        'state',
        'info',
        'relativeTo',
        'preserveFragment',
        'skipLocationChange',
        'replaceUrl',
        'routerLink: link',
      ],
    },
  ],
  host: {
    '[class]': '_computedClass()',
    '[attr.aria-current]': 'isActive() ? "page" : null',
  },
})
export class HlmPaginationLinkDirective {
  public class = input('');
  public isActive = input(false, {transform: booleanAttribute});
  public size = input<ButtonVariants['size']>('icon');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public link = input<string | any[] | null | undefined>('');

  protected _computedClass = computed(() =>
    hlm(
      paginationLinkVariants(),
      buttonVariants({
        variant: this.isActive() ? 'outline' : 'ghost',
        size: this.size(),
      }),
      this.class(),
    ),
  );
}
