import { IGender } from '../app/modules/auth/models/interfaces';

export const AVAILABLE_GENDERS: IGender[] = [
  {
    value: 'F',
    name: 'CORE.GENDER.FEMALE',
  },
  {
    value: 'M',
    name: 'CORE.GENDER.MALE',
  },
  {
    value: 'I',
    name: 'CORE.GENDER.INTERSEXUAL',
  },
  {
    value: 'O',
    name: 'CORE.GENDER.OTHER',
  },
];
