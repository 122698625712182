import { PAGINATOR_LOCALE_ES } from '@app/shared/ui/ui-paginator';
import { I18N_PARTIALS_ES } from '../partials/es';
import { ILangVocab } from '../interface';
import { GROUP_MANAGEMENT_LOCALE_ES } from '@app/modules/group/i18n/locale';
import { USER_MANAGEMENT_LOCALE_ES } from '@app/modules/user/i18n/locale';
import { AUTH_LOCALE_ES } from '@app/modules/auth/i18n/locale';

export const locale: ILangVocab = {
  lang: 'es',
  data: {
    CORE: {
      ROLE: {
        ADMIN: 'Administrador',
        SPECIALIST: 'Especialista',
        SPECIALIST_ADMIN_CENTER: 'Gestor de centros',
      },
      GENDER: {
        MALE: 'Masculino',
        FEMALE: 'Femenino',
        INTERSEXUAL: 'Intersexual',
        OTHER: 'Otro',
      },
      THEME_COLOR: {
        DEFAULT: 'Inicial',
        BLUE: 'Azul',
        ORANGE: 'Naranja',
        RED: 'Rojo',
        VIOLET: 'Violeta',
        SLATE: 'Azul oscuro',
        GREEN: 'Verde',
        YELLOW: 'Amarillo',
      },
      THEME_MODE: {
        SYSTEM: 'Automático',
        LIGHT: 'Claro',
        DARK: 'Oscuro',
      },
      USER_MENU: {
        PROFILE: 'Mi perfil',
        LANG: 'Idioma',
        LOGOUT: 'Salir',
        THEME: {
          LABEL: 'Tema',
          COLOR: 'Color',
          MODE: 'Modo',
        },
      }
    },
    TEXT: {
      MANAGEMENT: 'Gestión',
      ADMINISTRATION: 'Administración',
      SELECT: 'Seleccionar',
      PLEASE_WAIT: 'Por favor espere',
      ONLINE_MESSAGE: 'Tu conexión a internet fue restaurada.',
      OFFLINE_MESSAGE: 'No estas conectado a internet.',
      NO_ITEMS_FOUND: 'No se encontraron elementos',
    },
    ...AUTH_LOCALE_ES,
    ...I18N_PARTIALS_ES,
    ...PAGINATOR_LOCALE_ES,
    ...GROUP_MANAGEMENT_LOCALE_ES,
    ...USER_MANAGEMENT_LOCALE_ES,
  }
};
