<div class="relative">
  <ui-spinner [loaderId]="componentLoaderID" />

  @if (tableHasItems() || (!tableHasItems() && showTableIfEmpty())) {
    <div
      class="w-full overflow-x-auto text-sm"
      [class.rounded-md]="rounded()"
      [class.shadow-md]="shadow()"
      [class.table-bordered]="bordered()">
      <table
        class="table-padding w-full table-auto text-left"
        [class.table-hover]="rowHoverable()"
        [class.table-striped]="tableHasItems() ? striped() : false"
        [class.table-dashed]="dashed()"
        [class.table-row-bordered]="showRowBorder()"
        [class.table-row-dashed]="showRowBorderDashed()"
        [class.caption-top]="'top' === captionPlacement()"
        [class.caption-bottom]="'bottom' === captionPlacement()">
        <caption class="my-2">{{ caption() }}</caption>

        <!--<editor-fold desc="Table Header">-->
        @if (
          undefined === headersTemplateRef && columns() && columns().length > 0
        ) {
          <thead [ngClass]="headerClass()" [ngStyle]="headerStyle()">
            <tr>
              <ng-container
                *rxFor="let header of columns(); trackBy: trackHeaderBy">
                <!-- @for (header of columns(); track trackHeaderBy(header)) { -->
                @if (header.enabled || undefined === header.enabled) {
                  <th
                    scope="col"
                    class="H-14-400-DM_Sans camelcase text-gray-500"
                    [ngClass]="header.htmlClass || ''"
                    [ngStyle]="header.htmlStyle || {}"
                    [class.cursor-pointer]="
                      header.config &&
                      undefined !== header.config.sortColumn &&
                      null !== header.config.sortColumn
                    ">
                    {{ header.label | translate }}
                    @if (
                      header.config &&
                      undefined !== header.config.sortColumn &&
                      null !== header.config.sortColumn
                    ) {
                      <app-sort-icon
                        class="relative top-[3px] ms-1"
                        [column]="header.config.sortColumn || 'column'"
                        [state]="getSortStateForColum(header.config)"
                        (sortChange)="sortingEmitter($event)" />
                    }
                  </th>
                }
                <!-- } -->
              </ng-container>
            </tr>
          </thead>
        } @else {
          <ng-container
            *ngTemplateOutlet="
              headersTemplateRef || defaultHeaderTemplate;
              context: { $implicit: columns(), data: data() }
            " />
        }
        <!--</editor-fold>-->
        <tbody>
             @if (tableHasItems()) {
            <ng-container
              *rxFor="
                let row of data;
                trackBy: trackByFn;
                strategy: 'idle';
                let count = count;
                let index = index;
                let first = first;
                let last = last;
                let even = even;
                let odd = odd
              ">
              <ng-container
                *ngTemplateOutlet="
                  rowTemplateRef || defaultRowTemplate;
                  context: {
                    $implicit: row,
                    index,
                    first,
                    last,
                    odd,
                    even,
                    count,
                  }
                " />
            </ng-container>
          } @else {
            <ng-template [ngTemplateOutlet]="emptyTableTemplate"></ng-template>
          }
          <!-- @for (
            row of data();
            let index = $index;
            let first = $first;
            let last = $last;
            let odd = $odd;
            let even = $even;
            let count = $count;
            track trackByFn(0,row)
          ) {
            <ng-container
              *ngTemplateOutlet="
                rowTemplateRef || defaultRowTemplate;
                context: {
                  $implicit: row,
                  index,
                  first,
                  last,
                  odd,
                  even,
                  count,
                }
              " />
          } @empty {
            <ng-template [ngTemplateOutlet]="emptyTableTemplate" />
          } -->
        </tbody>

        <!--<editor-fold desc="Table Footer">-->
        @if (tableHasItems() && undefined !== footerTemplateRef) {
          <tfoot>
            <tr>
              <ng-container
                *ngTemplateOutlet="
                  footerTemplateRef;
                  context: {
                    $implicit: data(),
                  }
                " />
            </tr>
          </tfoot>
        }
        <!--</editor-fold>-->
      </table>
    </div>
  } @else {
    <ng-template [ngTemplateOutlet]="emptyTableTemplate" />
  }
</div>

<!--<editor-fold desc="Default Header Template">-->
<ng-template #defaultHeaderTemplate let-data="data">
  <thead [ngClass]="headerClass()" [ngStyle]="headerStyle()">
    <tr *rxFor="let header of data[0] | keyvalue; trackBy: 'key'">
      <!-- @for (header of data[0] | keyvalue; track header.key) { -->
      <th>{{ $any(header.key) | titlecase }}</th>
      <!-- } -->
    </tr>
  </thead>
</ng-template>
<!--</editor-fold>-->

<!--<editor-fold desc="Default Row Template">-->
<ng-template #defaultRowTemplate let-row let-index="index">
  <tr>
    <ng-template #actionTemplateRef>
      @if (undefined !== rowActionTemplateRef) {
        <ng-container
          *ngTemplateOutlet="
            rowActionTemplateRef;
            context: { $implicit: row }
          " />
      }
    </ng-template>

    @if (columns().length > 0) {
      <ng-container *rxFor="let header of columns(); trackBy: trackHeaderBy">
        @if (
          (true === header.enabled || undefined === header.enabled) &&
          undefined !== header.config
        ) {
          @if (header.config.column && 'action_cell' !== header.config.column) {
            <td>
              @if (
                'default' === header.config.cellType ||
                undefined === header.config.cellType
              ) {
                {{
                  undefined === header.config.cellRender
                    ? row[header.config.column]
                    : (row[header.config.column]
                      | cellTransformer: row : index : header.config.cellRender)
                }}
              } @else {
                <span
                  [innerHTML]="
                    undefined === header.config.cellRender
                      ? row[header.config.column]
                      : (row[header.config.column]
                        | cellTransformer
                          : row
                          : index
                          : header.config.cellRender)
                  "></span>
              }
            </td>
          } @else {
            <ng-container *ngTemplateOutlet="actionTemplateRef" />
          }
        }
      </ng-container>

      <!-- @for (header of columns(); track trackHeaderBy(header)) {
        @if (
          (true === header.enabled || undefined === header.enabled) &&
          undefined !== header.config
        ) {
          @if (header.config.column && 'action_cell' !== header.config.column) {
            <td>
              @if (
                'default' === header.config.cellType ||
                undefined === header.config.cellType
              ) {
                {{
                  undefined === header.config.cellRender
                    ? row[header.config.column]
                    : (row[header.config.column]
                      | cellTransformer: row : index : header.config.cellRender)
                }}
              } @else {
                <span
                  [innerHTML]="
                    undefined === header.config.cellRender
                      ? row[header.config.column]
                      : (row[header.config.column]
                        | cellTransformer
                          : row
                          : index
                          : header.config.cellRender)
                  "></span>
              }
            </td>
          } @else {
            <ng-container *ngTemplateOutlet="actionTemplateRef" />
          }
        }
      } -->
    } @else {
      <!-- @for (entries of row | keyvalue; track entries.key) { -->
        <td *rxFor="let entries of row | keyvalue; trackBy: 'key'">{{ entries.value }}</td>
      <!-- } -->

      <ng-container *ngTemplateOutlet="actionTemplateRef" />
    }
  </tr>
</ng-template>
<!--</editor-fold>-->

<!--<editor-fold desc="Empty Table Template">-->
<ng-template #emptyTableTemplate>
  <!--showTableIfEmpty allows us to control whether we should show the table if we do not have data-->
  @if (showTableIfEmpty()) {
    <tr>
      <td colspan="100">
        <ng-template [ngTemplateOutlet]="noDataDefaultTemplate" />
      </td>
    </tr>
  } @else {
    <ng-template [ngTemplateOutlet]="noDataDefaultTemplate" />
  }
</ng-template>

<ng-template #noDataDefaultTemplate>
  <!--  If the user does not provide a template we use the default one -->
  @if (null === emptyTableTemplateRef || undefined === emptyTableTemplateRef) {
    <div
      class="mt-5 flex w-full flex-col items-center justify-center rounded p-5 align-middle">
      @if (noDataShowImg()) {
        <div class="mb-5 shrink-0 text-center">
          <img
            width="100"
            src="assets/media/svg/no_data.svg"
            class=""
            alt="no_data"
            height="97" />
        </div>
      }

      <div class="fs-6 mb-0">
        <div
          class="mb-2 text-center font-semibold text-gray-400"
          [innerText]="noDataMessage() | translate"></div>
      </div>
    </div>
  } @else {
    <!--  custom template provided by user-->
    <ng-template
      [ngTemplateOutlet]="emptyTableTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: noDataMessage() }" />
  }
</ng-template>

<!--</editor-fold>-->
