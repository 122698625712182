import {
  Component,
  computed,
  ContentChild,
  ElementRef,
  input,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import { provideIcons } from '@ng-icons/core';
import { lucideChevronDown } from '@ng-icons/lucide';
import { hlm } from '@spartan-ng/ui-core';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';
import { InputVariants, inputVariants } from '@spartan-ng/ui-input-helm';
import { BrnSelectTriggerDirective } from '@spartan-ng/ui-select-brain';
import { ClassValue } from 'clsx';

@Component({
  selector: 'hlm-select-trigger',
  standalone: true,
  imports: [BrnSelectTriggerDirective, HlmIconComponent],
  providers: [provideIcons({ lucideChevronDown })],
  template: `
    <button [class]="_computedClass()" #button brnSelectTrigger type="button">
      <ng-content />
      @if (icon) {
        <ng-content select="hlm-icon" />
      } @else {
        <hlm-icon class="ml-2 h-4 w-4 flex-none" name="lucideChevronDown" />
      }
    </button>
  `,
})
export class HlmSelectTriggerComponent {
  @ViewChild('button', { static: true })
  public buttonEl!: ElementRef;

  @ContentChild(HlmIconComponent, { static: false })
  protected icon!: HlmIconComponent;

  private readonly classNames = signal<ClassValue>('');
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input({ alias: 'class' })
  set _class(classNames: ClassValue) {
    this.classNames.set(classNames);
  }

  public variant = input<InputVariants['variant']>('default');

  protected readonly _computedClass = computed(() =>
    hlm(
      'h-10 items-center justify-between focus-visible:outline-none focus:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0',

      inputVariants({ variant: this.variant() }),

      '[&.ng-invalid.ng-dirty]:text-destructive [&.ng-invalid.ng-dirty]:border-destructive [&.ng-invalid.ng-touched]:border-destructive [&.ng-invalid.ng-dirty]:focus:border-none [&.ng-invalid.ng-touched]:focus:border-none [&.ng-invalid.ng-touched]:focus:ring-destructive [&.ng-invalid.ng-dirty]:focus:ring-destructive',
      this.classNames(),
    ),
  );
}
