interface IPaginatorKey {
  LOADING: string,
  SHOWING_ROWS: string,
  TO: string,
  OF: string,

  [key: string]: any;
}

export interface IPaginatorLocale {
  PAGINATOR: IPaginatorKey,

  [key: string]: any;
}

export const PAGINATOR_LOCALE_ES: IPaginatorLocale = {
  PAGINATOR: {
    LOADING: 'Cargando',
    SHOWING_ROWS: 'Mostrando',
    TO: '-',
    OF: 'de',
  },

};
export const PAGINATOR_LOCALE_EN: IPaginatorLocale = {
  PAGINATOR: {
    LOADING: 'Loading',
    SHOWING_ROWS: 'Showing',
    TO: '-',
    OF: 'of',
  }
};
